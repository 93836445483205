// ChatByIdPage.tsx
import { useChatMessages } from '@/entities/chat/models/useChatMessages'
import { useFavoriteMessages } from '@/entities/chat/models/useFavoriteMessages'
import { IAiAssistPayload } from '@/entities/chat/types'
import { ChatContainer } from '@/entities/chat/ui/ChatContainer'
import { ChatContainerSkeleton } from '@/entities/chat/ui/ChatContainerSkeleton'
import { ChatPanelButton } from '@/entities/chat/ui/ChatPanelButton'
import { MobileChatContainer } from '@/entities/chat/ui/MobileChatContainer'
import { IUser } from '@/entities/user/models'
import { chatApi } from '@/shared/api/chat'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { useAppDispatch, useTypedSelector } from '@/shared/store'
import { useSocket } from '@/socketHook'
import { DocumentPreviewContainer } from '@/widgets/DocumentPreview'
import { useSidebar } from '@/widgets/Sidebar'
import { useLoaderData, useParams } from 'react-router-dom'

export const ChatByIdPage = () => {
  const { chat_id } = useParams<{ chat_id: string }>()
  const { user } = useLoaderData() as {
    user: IUser
  }

  const isMobile = useMediaQuery('(max-width: 959px)')
  const { data, isLoading, error } = useChatMessages(chat_id!)

  const { documents, isChatOpen } = useTypedSelector(
    (state) => state.documentPreview
  )
  const { isSidebarOpen } = useSidebar()
  const dispatch = useAppDispatch()

  useSocket({
    type: 'CHAT',
    userId: Number(user.id),
    callBack: (payload: IAiAssistPayload) => {
      let documents = payload.documents
      if (typeof documents === 'string') {
        try {
          documents = JSON.parse(documents)
        } catch (error) {
          console.error('Failed to parse documents:', error)
          documents = []
        }
      }

      dispatch(
        chatApi.util.updateQueryData(
          'getChatById',
          chat_id!,
          (draft) => {
            if (draft && draft.messages) {
              draft.messages.push({
                id: payload.message_id,
                from: 'ASSISTANT',
                is_favorite: false,
                message: payload.message,
                documents: documents,
                created_at: new Date().toISOString(), //TODO: В идеале должно возращатся беком
              })
            }
          }
        )
      )
    },
  })

  if (isLoading) {
    return <ChatContainerSkeleton isSidebarOpen={isSidebarOpen} />
  }

  if (!chat_id) return <></>

  if (!data?.messages) {
    return <div>Chat not found</div>
  }

  return (
    <>
      {isMobile ? (
        <MobileChatContainer messages={data.messages ?? []} />
      ) : (
        <ChatContainer messages={data.messages ?? []} />
      )}
      <DocumentPreviewContainer />
      {documents.length !== 0 && !isChatOpen && <ChatPanelButton />}
    </>
  )
}
