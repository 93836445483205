import {
  IAiAssistPayload,
  IChatAiFile,
  IChatDetails,
  IChatMessage,
  IMessage,
} from '@/entities/chat/types'
import { deepClone } from '@/shared/utils/common'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ChatState {
  chats: Record<string, IChatDetails>
  isChatOpen: boolean
}

const initialState: ChatState = {
  chats: {},
  isChatOpen: false,
}

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addMessage: (
      state,
      action: PayloadAction<{ chatId: string; message: IChatMessage }>
    ) => {
      const { chatId, message } = action.payload
      const chat = state.chats[chatId]
      if (chat) {
        chat.messages.push(message)
        chat.last_used_at = message.created_at
      }
    },
    setChat: (
      state,
      action: PayloadAction<{
        chatId: string
        chatDetails: IChatDetails
      }>
    ) => {
      const { chatId, chatDetails } = action.payload

      state.chats[chatId] = structuredClone(chatDetails)
    },
    addAiAssistMessage: (
      state,
      action: PayloadAction<{
        chatId: string
        payload: IAiAssistPayload
      }>
    ) => {
      const { chatId, payload } = action.payload
      const chat = state.chats[chatId]

      if (chat) {
        // Parse documents if they are in string format
        let documents = payload.documents
        if (typeof payload.documents === 'string') {
          try {
            documents = JSON.parse(payload.documents)
          } catch (error) {
            console.error('Failed to parse documents:', error)
          }
        }

        const newMessage: IChatMessage = {
          id: Date.now(), // Use a temporary id (replace with real id if necessary)
          from: 'ASSISTANT',
          is_favorite: false,
          message: payload.message,
          documents: deepClone(documents), // Deep clone the parsed documents
          created_at: new Date().toISOString(), // Add timestamp
        }

        chat.messages.push(newMessage)
        chat.last_used_at = newMessage.created_at
      }
    },

    addUserMessage: (
      state,
      action: PayloadAction<{
        chatId: string
        payload: IChatMessage
      }>
    ) => {
      const { chatId, payload } = action.payload
      const chat = state.chats[chatId]
      if (chat) {
        chat.messages.push(payload)
        chat.last_used_at = payload.created_at
      }
    },
    removeMessageById: (
      state,
      action: PayloadAction<{ chatId: string; messageId: number }>
    ) => {
      const { chatId, messageId } = action.payload
      const chat = state.chats[chatId]
      if (chat) {
        chat.messages = chat.messages.filter(
          (msg) => msg.id !== messageId
        )
      }
    },
    openChat: (state) => {
      state.isChatOpen = true
    },
    closeChat: (state) => {
      state.isChatOpen = false
    },
  },
})

export const {
  addMessage,
  setChat,
  addUserMessage,
  addAiAssistMessage,
  removeMessageById,
  openChat,
  closeChat,
} = chatSlice.actions

export default chatSlice.reducer
