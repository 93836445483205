import IconEdit from '@/shared/assets/icons/icon_edit.svg?react'
import IconGrid from '@/shared/assets/icons/icon_grid.svg?react'
import IconMessages from '@/shared/assets/icons/icon_messages.svg?react'
import { useAppDispatch } from '@/shared/store'
import { logout } from '@/shared/store/slices/auth/userSlice'
import { Button } from '@/shared/ui/Button'
import { IconButton } from '@/shared/ui/IconButton'
import { Typography } from '@/shared/ui/Typography'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'

import { MobileSidebar } from '../../Sidebar'
import { useSidebar } from '../../Sidebar/model/useSidebar'

export const MobilePlatformHeader = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { toggleSidebar } = useSidebar()

  const handleLogout = () => {
    Cookies.remove('access_token')
    dispatch(logout())
    navigate('/signin')
  }
  return (
    <div className="inline-flex items-start justify-between px-5 pb-1 pt-6">
      <MobileSidebar>
        {/* <IconButton> */}
        <IconGrid className="h-6 w-6 sm:h-8 sm:w-8" />
        {/* </IconButton> */}
      </MobileSidebar>
      <IconButton onClick={() => navigate('/')}>
        <IconEdit className="h-6 w-6 sm:h-8 sm:w-8" />
      </IconButton>
    </div>
  )
}
