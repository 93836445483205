import IconClose from '@/shared/assets/icons/icon_close.svg?react'
import IconSortOutlinedDark from '@/shared/assets/icons/icon_sort_outlined_dark.svg?react'
import { Button } from '@/shared/ui/Button'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTrigger,
} from '@/shared/ui/Drawer'
import { IconButton } from '@/shared/ui/IconButton'
import { Typography } from '@/shared/ui/Typography'

import { useSortModeFilter } from '../../hooks/useSortModeFilter'
import { ISortMode } from '../../types'
import { SortModeRadioGroup } from '../SortModeFilterRadioGroup'

const defaultSortMode: ISortMode = 'FORCE'

export const SortModeFilterDrawer = () => {
  const {
    selectedSortMode,
    handleSortModeChange,
    handleApply,
    handleReset,
  } = useSortModeFilter(defaultSortMode)

  return (
    <Drawer direction="bottom">
      <DrawerTrigger>
        <IconButton className="flex h-10 w-10 items-center justify-center border">
          <IconSortOutlinedDark className="h-6 w-6" />
        </IconButton>
      </DrawerTrigger>
      <DrawerContent className="z-[70] h-[62svh] overflow-y-hidden sm:h-[90svh] md:h-[88svh]">
        <DrawerClose className="absolute right-6 top-10">
          <IconClose className="h-6 w-6" />
        </DrawerClose>
        <DrawerHeader className="flex items-center justify-start gap-3 p-0">
          <Typography className="text-[28px] font-bold leading-[34px] text-black">
            Сортировка
          </Typography>
        </DrawerHeader>
        <DrawerDescription
          className="-mx-6 mt-8 flex flex-col items-stretch justify-start gap-5
            overflow-y-scroll px-6"
        >
          <SortModeRadioGroup
            selectedSortMode={selectedSortMode}
            handleSortModeChange={handleSortModeChange}
          />
        </DrawerDescription>
        <DrawerFooter className="flex flex-row flex-nowrap justify-between gap-5 p-0 pb-5">
          <DrawerClose className="shrink-0 grow">
            <Button
              variant={'tonal'}
              className="w-full"
              onClick={handleApply}
            >
              Применить
            </Button>
          </DrawerClose>
          <DrawerClose className="shrink-0 grow">
            <Button
              variant={'outlined'}
              className="w-full"
              onClick={handleReset}
            >
              Сбросить
            </Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
