import IconArrowRightWhite from '@/shared/assets/icons/icon_arrow_right_white.svg?react'
import { Button } from '@/shared/ui/Button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/shared/ui/DropdownMenu'
import { Typography } from '@/shared/ui/Typography'

import { mockSortModeData } from '../../consts'
import { useSortModeFilter } from '../../hooks/useSortModeFilter'
import { ISortMode } from '../../types'
import { SortModeFilterCustomInput } from '../SortModeFilterCustomInput'
import { SortModeFilterItemDesktop } from '../SortModeFilterItemDesktop'

export const SortModeFilterDesktopMenu = () => {
  const {
    selectedSortMode,
    handleSortModeChange,
    handleApply,
    handleReset,
  } = useSortModeFilter(mockSortModeData.FORCE as ISortMode)
  const MOCK_TOTAL_DOCUMENTS = 999999
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <SortModeFilterCustomInput />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="start"
        className="relative flex w-[465px] flex-col overflow-hidden rounded-2xl bg-white
          p-2 pb-14"
      >
        <div className="overflow-y-auto pr-2">
          <div className="flex flex-nowrap gap-6 pl-3">
            <Typography variant={'subtitle'}>
              Выбрано фильтров: 1
            </Typography>
            <button
              className="rounded-full px-3 py-0 text-black"
              onClick={handleReset}
            >
              <Typography
                variant={'subtitle'}
                className="text-black underline"
              >
                Очистить
              </Typography>
            </button>
          </div>
          <SortModeFilterItemDesktop
            handleSortModeChange={handleSortModeChange}
            items={mockSortModeData}
            selectedSortMode={selectedSortMode}
          />
        </div>
        <div
          className="absolute bottom-0 -ml-2 box-border flex w-full items-center
            justify-between gap-8 rounded-b-2xl border-t border-t-[#F0F0F0]
            bg-white px-5 py-2"
        >
          <Typography
            variant={'label'}
            className="font-semibold"
          >{`Найдено документов: ${MOCK_TOTAL_DOCUMENTS}`}</Typography>
          <DropdownMenuItem className="p-0">
            <Button
              onClick={handleApply}
              variant={'secondary'}
              leftIcon={<IconArrowRightWhite />}
              className="grow py-1"
            >
              Показать
            </Button>
          </DropdownMenuItem>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
