import { ISignupIndividualDTO } from '@/entities/signup/types'
import {
  useRegisterIndividualMutation,
  useSendOtpMutation,
} from '@/shared/api/signupIndividual'
import { useAppDispatch, useTypedSelector } from '@/shared/store'
import {
  setCanResend,
  setCodeSent,
  updateSignupData,
} from '@/shared/store/slices/auth/signupIndividualSlice'
import { useToast } from '@/shared/ui/Toast/useToast'
import Cookies from 'js-cookie'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useTimer } from 'react-timer-hook'

export const useSignupIndividual = () => {
  const methods = useForm<ISignupIndividualDTO>()
  const errors = methods.formState.errors
  const { resetField } = methods
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { data, codeSent, canResend } = useTypedSelector(
    (state) => state.signupIndividual
  )

  const [sendOtp, { isLoading: isOtpLoading }] = useSendOtpMutation()
  const [registerIndividual, { isLoading: isRegisterLoading }] =
    useRegisterIndividualMutation()

  const { seconds, isRunning, start, pause, resume, restart } =
    useTimer({
      expiryTimestamp: new Date(),
      onExpire: () => {
        dispatch(setCanResend(true))
      },
    })

  const handleCodeSent = useCallback(async () => {
    const phone = methods.watch('phone')
    if (phone) {
      try {
        //TODO: restore once the backend is ready
        await sendOtp(phone).unwrap()
        // await new Promise<void>((resolve) => {
        //   setTimeout(resolve, 2000)
        // })

        dispatch(setCodeSent(true))
        dispatch(setCanResend(false))
      } catch (error: any) {
        console.error('Failed to send OTP')
      } finally {
        const newExpiryTimestamp = new Date()
        newExpiryTimestamp.setSeconds(
          newExpiryTimestamp.getSeconds() + 30
        )
        restart(newExpiryTimestamp, true)
      }
    }
  }, [dispatch, restart, methods])

  const onSubmit = methods.handleSubmit(async (formData) => {
    dispatch(updateSignupData(formData))

    try {
      const response = await registerIndividual(formData).unwrap()
      Cookies.set('access_token', response.access_token, {
        expires: 30,
        secure: true,
        sameSite: 'Strict',
      })
      methods.reset()

      navigate('/')
    } catch (error: any) {
      const errorData = error?.data || {}
      const keys = Object.keys(
        formData
      ) as (keyof ISignupIndividualDTO)[]

      keys.forEach((key) => {
        if (errorData.messages && errorData.messages[key]) {
          methods.setError(key, {
            type: 'manual',
            message: errorData.messages[key][0],
          })
        }
      })
    }
  })

  return {
    methods,
    onSubmit,
    data,
    resetField,
    timeLeft: seconds,
    codeSent,
    canResend,
    handleCodeSent,
    loading: isOtpLoading || isRegisterLoading,
    errors,
  }
}
