// src/components/OrganizationFilterAccordion.tsx
import IconChevronRight from '@/shared/assets/icons/icon_chevron_right.svg?react'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/shared/ui/Accordion'
import { Checkbox } from '@/shared/ui/Checkbox'
import { Typography } from '@/shared/ui/Typography'
import React from 'react'

import { Organization } from '../../types'

interface OrganizationFilterAccordionProps {
  level?: number
  parentId: number
  isFiltered: boolean
  isSearching: boolean
  selectedIndices: number[]
  organizations: Organization[]
  handleCheckboxChange: (id: number) => void
}

export const OrganizationFilterAccordion: React.FC<
  OrganizationFilterAccordionProps
> = ({
  parentId,
  level = 0,
  isFiltered,
  isSearching,
  organizations,
  selectedIndices,
  handleCheckboxChange,
}) => {
  let filteredOrganizations: Organization[]

  if (isFiltered) {
    // Display matching organizations as a flat list
    filteredOrganizations = organizations
  } else {
    // Display organizations hierarchically
    filteredOrganizations = organizations.filter(
      (org) => org.parentId === parentId
    )
  }

  return (
    <>
      {filteredOrganizations.map((organization) => {
        const hasChildren =
          !isSearching &&
          organizations.some(
            (org) => org.parentId === organization.id
          )

        return (
          <Accordion type="single" collapsible key={organization.id}>
            <AccordionItem value={organization.id.toString()}>
              <div
                className="flex cursor-pointer items-center justify-start gap-2 self-stretch px-3
                  py-[10px]"
                style={{ marginLeft: `${level * 16}px` }}
              >
                <Checkbox
                  variant={'secondary'}
                  id={`checkbox-${organization.id}`}
                  className="shrink"
                  size={'small'}
                  checked={selectedIndices.includes(organization.id)}
                  onCheckedChange={() =>
                    handleCheckboxChange(organization.id)
                  }
                />
                <AccordionTrigger
                  className="p-0"
                  iconSvg={
                    hasChildren ? (
                      <IconChevronRight className="mr-1 min-h-5 min-w-5" />
                    ) : null
                  }
                >
                  <span className="flex h-full items-center justify-start text-start">
                    <Typography
                      variant={'body'}
                      className="inline text-sm"
                    >
                      {organization.name}{' '}
                      <span className="text-sm font-normal leading-5 text-[#747474]">
                        ({organization.document_amount})
                      </span>
                    </Typography>
                  </span>
                </AccordionTrigger>
              </div>

              {hasChildren && (
                <AccordionContent className="border-l border-l-[#E3E5E8]">
                  <OrganizationFilterAccordion
                    level={level + 1}
                    isFiltered={false}
                    isSearching={isSearching}
                    parentId={organization.id}
                    organizations={organizations}
                    selectedIndices={selectedIndices}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                </AccordionContent>
              )}
            </AccordionItem>
          </Accordion>
        )
      })}
    </>
  )
}
