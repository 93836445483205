import { ISignupJudicialDTO } from '@/entities/signup/types'
import { IAuthResponse } from '@/shared/api/auth'
import { incrementStep } from '@/shared/store/slices/auth/signupJudicialSlice'
import { unformatPhone } from '@/shared/utils/unformatPhone'
import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'

import { config } from '..'

interface ICompanyTypesResponse {
  success: boolean
  data: { code: string; name: string }[]
}

export const signupJudicialApi = createApi({
  reducerPath: 'signupJudicialApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE,
    prepareHeaders: (headers) => {
      return headers
    },
  }),
  endpoints: (builder) => ({
    sendMailOtp: builder.mutation<void, string>({
      query: (phone) => ({
        url: '/otps/phone',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone: `${unformatPhone(phone)}` }),
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(incrementStep())
        } catch (error) {
          // TODO: add error handling
          console.log(error)
        }
      },
    }),
    registerJudicial: builder.mutation<
      IAuthResponse,
      ISignupJudicialDTO
    >({
      query: (data) => ({
        url: '/registration/company',
        method: 'POST',
        body: { ...data, phone: `${unformatPhone(data.phone)}` },
      }),
    }),
    fetchCompanyTypes: builder.query<ICompanyTypesResponse, void>({
      query: () => ({
        url: '/handbooks/company-types',
      }),
    }),
  }),
})

export const {
  useSendMailOtpMutation,
  useRegisterJudicialMutation,
  useFetchCompanyTypesQuery,
} = signupJudicialApi
