import { CustomFilterInput, ECategoryType } from '@/entities/filter'
import { Checkbox, Typography } from '@/shared/ui'

import { useTypeFilter } from '../../hooks/useTypeFilter'
import { TypeFilterAccordion } from '../TypeFilterAccordion'

export const TypeFilterDesktopMenu = () => {
  const {
    types,
    isFiltered,
    isSearching,
    allSelected,
    setTotalTypesCount,
    setTypes,
    selectedIndices,
    handleSelectAll,
    setIsFiltered,
    handleCheckboxChange,
  } = useTypeFilter()

  return (
    <div className="relative flex flex-col overflow-hidden">
      <div className="p-0" onClick={(e) => e.preventDefault()}>
        <CustomFilterInput
          setIsFiltered={setIsFiltered}
          setFilterItems={setTypes}
          categoryType={ECategoryType.TYPES}
          setTotalCount={setTotalTypesCount}
        />
        <div className="flex items-center justify-start gap-2 px-3 py-[10px]">
          <Checkbox
            variant={'secondary'}
            size={'small'}
            checked={allSelected}
            onCheckedChange={handleSelectAll}
            id="select-all-checkbox"
          />
          <Typography variant={'subtitle'} className="ml-2 text-sm">
            Все
          </Typography>
        </div>
      </div>
      <TypeFilterAccordion
        level={0}
        parentId={-1}
        types={types}
        isFiltered={isFiltered}
        isSearching={isSearching}
        selectedIndices={selectedIndices}
        handleCheckboxChange={handleCheckboxChange}
      />
    </div>
  )
}
