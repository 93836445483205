import IconBackArrowGray from '@/shared/assets/icons/icon_back_arrow_gray.svg?react'
import Logo from '@/shared/assets/icons/logo.svg?react'
import { Button } from '@/shared/ui/Button'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/shared/ui/Card'
import { IconButton } from '@/shared/ui/IconButton'
import { Input } from '@/shared/ui/Input'
import { Typography } from '@/shared/ui/Typography'
import { FormProvider } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { usePasswordRecovery } from '../../models/usePasswordRecovery'

export const PasswordRecoveryForm = () => {
  const { token } = useParams<{ token: string }>()
  const navigate = useNavigate()
  const { register, errors, handleSubmit, onSubmit, methods } =
    usePasswordRecovery(token ?? '')

  return (
    <div className="flex flex-col items-center justify-start gap-4 xl:gap-8">
      <div
        className="flex h-[50px] w-[360px] flex-col sm:w-[440px] lg:w-[450px]
          xl:w-[544px]"
      >
        <Logo className="self-start xl:self-center" />
      </div>
      <Card
        className="relative flex w-[360px] flex-col gap-8 rounded-[40px] px-4 py-8
          shadow-shadow2 sm:w-[440px] sm:p-10 lg:w-[450px] lg:p-8 xl:w-[544px]"
      >
        <CardHeader className="mt-6 flex flex-col items-center justify-start gap-4 sm:mt-0">
          <CardTitle className="flex w-full items-center justify-between">
            <IconButton
              onClick={() => navigate('/signin')}
              className="absolute left-4 top-4"
            >
              <IconBackArrowGray />
            </IconButton>

            <Typography
              variant={'heading3'}
              className="grow text-center"
            >
              Восстановление пароля
            </Typography>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <CardContent className="flex flex-col gap-4">
                <Input
                  label="Новый пароль"
                  type="password"
                  {...register('new_password', {
                    required: 'Required field',
                  })}
                  placeholder="••••••••"
                />
                <Input
                  label="Подтверждение пароля"
                  type="password"
                  {...register('confirm_password', {
                    required: 'Required field',
                  })}
                  placeholder="••••••••"
                />
              </CardContent>
              <CardFooter className="mt-8 flex flex-col items-center gap-5 lg:gap-8">
                <div className="flex flex-col items-stretch justify-center gap-4 self-stretch">
                  <Button
                    variant={'primary'}
                    className="w-full"
                    disabled={
                      !methods.watch('confirm_password') ||
                      !methods.watch('new_password')
                    }
                    type="submit"
                  >
                    Изменить пароль
                  </Button>
                  <Typography className="text-center text-xs text-gray-500">
                    У вас нет аккаунта?{' '}
                    <Link
                      to={'/signup'}
                      className="font-medium text-base-900 underline"
                    >
                      Создать новый аккаунт
                    </Link>
                  </Typography>
                </div>

                <Typography
                  variant={'subtitle'}
                  className="self-stretch text-center font-normal text-[#9EA2AA]"
                >
                  Используя CODEX, Вы соглашаетесь с{' '}
                  <span className={'underline'}>
                    Политикой конфиденциальности
                  </span>
                </Typography>
              </CardFooter>
            </form>
          </FormProvider>
        </CardContent>
      </Card>
    </div>
  )
}
