import IconFileOutlinedDark from '@/shared/assets/icons/icon_file_outlined_dark.svg?react'
import { Typography } from '@/shared/ui/Typography'

import { IChatAiFile } from '../../types'
import { ChatAiFileDrawer } from '../ChatAiFileDrawer'

type ChatAiFileProps = {
  file: IChatAiFile
}

export const MobileChatAiFile = ({ file }: ChatAiFileProps) => {
  return (
    <div className="flex grow flex-col items-stretch justify-start self-stretch">
      <ChatAiFileDrawer
        document={{
          id: file.id,
          name: file.name,
          body: file.name,
        }}
      >
        <div
          className="flex w-full items-center justify-start gap-3 rounded-lg border
            border-[#F0F0F0] px-4 py-3 hover:cursor-pointer hover:bg-slate-100"
        >
          <IconFileOutlinedDark
            height={24}
            width={24}
            className="shrink-0"
          />
          <div className="flex flex-col items-stretch justify-start gap-4 overflow-hidden">
            <Typography className="whitespace-normal break-words text-start">
              {file.name}
            </Typography>
          </div>
        </div>
      </ChatAiFileDrawer>
    </div>
  )
}
