import { useEffect, useState } from 'react'

export const useBoundary = (className: string) => {
  const [boundaryElements, setBoundaryElements] = useState<Element[]>(
    []
  )

  useEffect(() => {
    const elements = document.getElementsByClassName(className)
    setBoundaryElements(Array.from(elements))
  }, [className])

  return boundaryElements
}
