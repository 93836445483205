import { ButtonToggleFavoriteMessage } from '@/features/chat/ui/ButtonToggleFavoriteMessage'
import IconCodexGray from '@/shared/assets/icons/icon_codex_gray.svg?react'
import IconCopyGray from '@/shared/assets/icons/icon_copy_gray.svg?react'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { IconButton } from '@/shared/ui/IconButton'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { HTMLAttributes } from 'react'
import ReactMarkdown from 'react-markdown'
import { useParams } from 'react-router-dom'

import { useAiResponse } from '../../../../features/chat/models/useAiResponse'
import { IChatMessage } from '../../types'
import { ChatAiFile } from '../ChatAiFile'
import { MobileChatAiFile } from '../MobileChatAiFile'

interface ChatAiResponseProps extends HTMLAttributes<HTMLDivElement> {
  response: IChatMessage
  isLastResponse: boolean
}

export const ChatAiResponse = ({
  response,
  isLastResponse,
  ...props
}: ChatAiResponseProps) => {
  const isMobile = useMediaQuery('(max-width: 959px)')
  const { chat_id } = useParams()

  return (
    <div
      className="group flex items-stretch justify-start gap-4 self-stretch rounded-lg"
      {...props}
    >
      <div className="max-w-8">
        <IconButton
          className="flex items-center justify-center rounded-full border-[1px]
            border-base-200 bg-white p-[6px]"
        >
          <IconCodexGray />
        </IconButton>
      </div>
      <div className="flex flex-col items-stretch justify-start gap-4">
        <Typography className="whitespace-normal break-words">
          <ReactMarkdown className={'markdown -mt-[18px]'}>
            {response.message ?? ''}
          </ReactMarkdown>{' '}
        </Typography>
        {response.documents && (
          <div className="flex flex-col items-stretch justify-start gap-2">
            {response.documents.length > 0 &&
              response.documents.map((file) => {
                const fileKey = `${file.id + response.id}`
                return isMobile ? (
                  <MobileChatAiFile file={file} key={fileKey} />
                ) : (
                  <ChatAiFile file={file} key={fileKey} />
                )
              })}
          </div>
        )}
        <div
          className={cn(
            'flex items-center justify-start gap-3 self-stretch opacity-0',
            'transition-opacity group-hover:opacity-100',
            isLastResponse && 'opacity-100'
          )}
        >
          <IconButton className="p-1">
            <IconCopyGray
              height={20}
              width={20}
              className="[&>g>path]:stroke-[#67707E]"
            />
          </IconButton>
          <ButtonToggleFavoriteMessage
            chatId={chat_id ? chat_id : response.chat?.id}
            isFavorite={response.is_favorite}
            messageId={String(response.id)}
          />
        </div>
      </div>
    </div>
  )
}
