import { configureStore } from '@reduxjs/toolkit'
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux'

import { broadcastAuthApi } from '../api/broadcast'
import { chatApi } from '../api/chat'
import { documentsApi } from '../api/documents'
import { signinIndividualApi } from '../api/signinIndividual'
import { signupIndividualApi } from '../api/signupIndividual'
import { signupJudicialApi } from '../api/signupJudicial'
import { userApi } from '../api/user'
import { rtkQueryErrorLogger } from './middleware'
import signinIndividualReducer from './slices/auth/signinIndividualSlice'
import signinJudicialReducer from './slices/auth/signinJudicialSlice'
import signupIndividualReducer from './slices/auth/signupIndividualSlice'
import signupJudicialReducer from './slices/auth/signupJudicialSlice'
import userReducer from './slices/auth/userSlice'
import chatReducer from './slices/chat/chatSlice'
import documentPreviewReducer from './slices/chat/documentPreviewSlice'

// Import your API slice

const store = configureStore({
  reducer: {
    signupJudicial: signupJudicialReducer,
    signupIndividual: signupIndividualReducer,
    signinIndividual: signinIndividualReducer,
    signinJudicial: signinJudicialReducer,
    chat: chatReducer,
    user: userReducer,
    [documentsApi.reducerPath]: documentsApi.reducer,
    [signinIndividualApi.reducerPath]: signinIndividualApi.reducer,
    [signupIndividualApi.reducerPath]: signupIndividualApi.reducer,
    [signupJudicialApi.reducerPath]: signupJudicialApi.reducer,
    [broadcastAuthApi.reducerPath]: broadcastAuthApi.reducer,
    [chatApi.reducerPath]: chatApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    documentPreview: documentPreviewReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      signinIndividualApi.middleware,
      signupIndividualApi.middleware,
      signupJudicialApi.middleware,
      documentsApi.middleware,
      broadcastAuthApi.middleware,
      chatApi.middleware,
      userApi.middleware,
      rtkQueryErrorLogger
    ), // Add RTK Query middleware
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useTypedSelector: TypedUseSelectorHook<RootState> =
  useSelector

export default store
