import { Typography } from '@/shared/ui/Typography'
import React from 'react'

import { IChatMessage } from '../../types'

interface ChatUserRequestProps
  extends React.HTMLAttributes<HTMLDivElement> {
  chatMessage: IChatMessage
}

export const ChatUserRequest = ({
  chatMessage,
  ...props
}: ChatUserRequestProps) => {
  if (!chatMessage) {
    return null
  }
  return (
    <div
      className="flex items-center justify-end self-stretch"
      {...props}
    >
      <Typography
        className={'rounded-[26px] bg-[#F0F0F0] px-5 py-[10px]'}
      >
        {chatMessage.message ?? ''}
      </Typography>
    </div>
  )
}
