import { IChatAiFile } from '@/entities/chat/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface DocumentPreviewState {
  documents: IChatAiFile[]
  isChatOpen: boolean
}

const initialState: DocumentPreviewState = {
  documents: [],
  isChatOpen: false,
}

export const documentPreviewSlice = createSlice({
  name: 'documentPreview',
  initialState,
  reducers: {
    addDocument: (state, action: PayloadAction<IChatAiFile>) => {
      if (state.documents.length >= 2) {
        state.documents.shift()
      }

      state.documents.push(action.payload)
    },
    removeDocument: (state, action: PayloadAction<number>) => {
      state.documents = state.documents.filter(
        (doc) => doc.id !== action.payload
      )
    },
    openChatPanel: (state) => {
      state.isChatOpen = true
    },
    closeChatPanel: (state) => {
      state.isChatOpen = false
    },
  },
})

export const {
  addDocument,
  removeDocument,
  openChatPanel,
  closeChatPanel,
} = documentPreviewSlice.actions
export default documentPreviewSlice.reducer
