import { ISignupJudicialDTO } from '@/entities/signup/types'
import {
  useFetchCompanyTypesQuery,
  useRegisterJudicialMutation,
  useSendMailOtpMutation,
} from '@/shared/api/signupJudicial'
import { useAppDispatch, useTypedSelector } from '@/shared/store'
import {
  incrementStep,
  setCanResend,
  setCodeSent,
  setCompanyTypes,
  updateSignupData,
} from '@/shared/store/slices/auth/signupJudicialSlice'
import { useToast } from '@/shared/ui/Toast/useToast'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTimer } from 'react-timer-hook'

export const useSignupJudicial = () => {
  const dispatch = useAppDispatch()
  const { data, codeSent, canResend } = useTypedSelector(
    (state) => state.signupJudicial
  )

  const methods = useForm<ISignupJudicialDTO>({
    defaultValues: data ?? {},
  })

  const [sendMailOtp, { isLoading: isOtpLoading }] =
    useSendMailOtpMutation()
  const [registerJudicial, { isLoading: isRegisterLoading }] =
    useRegisterJudicialMutation()

  const {
    data: companyTypesResponse,
    isLoading: isCompanyTypesLoading,
  } = useFetchCompanyTypesQuery()

  // useEffect(() => {
  //   if (companyTypesData) {
  //     dispatch(setCompanyTypes(companyTypesData.data))
  //   }
  // }, [companyTypesData, dispatch])

  const { seconds, restart } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: () => {
      dispatch(setCanResend(true))
    },
  })

  const handleCodeSent = useCallback(async () => {
    const phone = methods.watch('phone')
    if (phone) {
      try {
        //TODO: Change once OTP works
        await sendMailOtp(phone).unwrap()
        // await new Promise<void>((resolve) => {
        //   setTimeout(resolve, 2000)
        // })
        // dispatch(incrementStep())

        dispatch(setCodeSent(true))
        dispatch(setCanResend(false))
      } catch (error: any) {
        console.error('OTP error')
      } finally {
        const newExpiryTimestamp = new Date()
        newExpiryTimestamp.setSeconds(
          newExpiryTimestamp.getSeconds() + 30
        )
        restart(newExpiryTimestamp, true)
      }
    }
  }, [dispatch, restart, methods])

  const onSubmitOTP = methods.handleSubmit(async (formData) => {
    dispatch(
      updateSignupData({
        ...formData,
      })
    )
    try {
      await handleCodeSent()
    } catch (error) {
      console.error(error)
    }
  })

  return {
    methods,
    onSubmitOTP,
    resetField: methods.resetField,
    companyTypes: companyTypesResponse?.data,
    isCompanyTypesLoading,
    errors: methods.formState.errors,
    timeLeft: seconds,
    codeSent,
    canResend,
    handleCodeSent,
    isLoading: isOtpLoading || isRegisterLoading,
  }
}
