import IconBookGray from '@/shared/assets/icons/icon_book_gray.svg?react'
import IconBookOrange from '@/shared/assets/icons/icon_book_orange.svg?react'
import IconCodexBlue from '@/shared/assets/icons/icon_codex_blue.svg?react'
import IconCodexGray from '@/shared/assets/icons/icon_codex_gray.svg?react'
import IconSmileGray from '@/shared/assets/icons/icon_smile_gray.svg?react'
import IconSmileGreen from '@/shared/assets/icons/icon_smile_green.svg?react'
import { Typography } from '@/shared/ui'
import { motion } from 'framer-motion'

const containerVariantsPeach = {
  rest: { backgroundColor: 'transparent' },
  hover: {
    backgroundColor: '#FFE5D5',
    transition: { duration: 0.3, ease: 'easeInOut' },
  },
}

const containerVariantsBlue = {
  rest: { backgroundColor: 'transparent' },
  hover: {
    backgroundColor: '#E6F0FF',
    transition: { duration: 0.3, ease: 'easeInOut' },
  },
}

const containerVariantsGreen = {
  rest: { backgroundColor: 'transparent' },
  hover: {
    backgroundColor: '#E0F8E0',
    transition: { duration: 0.3, ease: 'easeInOut' },
  },
}

const iconGrayVariants = {
  rest: { opacity: 1 },
  hover: {
    opacity: 0,
    transition: { duration: 0.2, ease: 'easeInOut' },
  },
}

const iconColorVariants = {
  rest: { opacity: 0 },
  hover: {
    opacity: 1,
    transition: { duration: 0.2, ease: 'easeInOut' },
  },
}
export const SidebarFooter = () => {
  return (
    <div
      className="absolute bottom-0 z-[50] w-full flex-none flex-col items-stretch
        justify-start rounded-b-3xl border-t border-t-base-50 px-4 pb-4 pt-2
        backdrop-blur-[8px]"
      style={{ background: 'rgba(252, 252, 253, 0.9)' }}
    >
      {/* First Item */}
      <motion.div
        className="group flex items-center justify-start gap-2 rounded-lg px-2 py-3"
        variants={containerVariantsPeach}
        initial="rest"
        whileHover="hover"
      >
        <div className="relative h-5 w-5">
          <motion.div
            variants={iconGrayVariants}
            className="absolute inset-0"
          >
            <IconBookGray />
          </motion.div>
          <motion.div
            variants={iconColorVariants}
            className="absolute inset-0"
          >
            <IconBookOrange />
          </motion.div>
        </div>
        <Typography variant={'label'}>Гид по возможностям</Typography>
      </motion.div>

      <motion.div
        className="group flex items-center justify-start gap-2 rounded-lg px-2 py-3"
        variants={containerVariantsBlue}
        initial="rest"
        whileHover="hover"
      >
        <div className="relative h-5 w-5">
          <motion.div
            variants={iconGrayVariants}
            className="absolute inset-0"
          >
            <IconCodexGray />
          </motion.div>
          <motion.div
            variants={iconColorVariants}
            className="absolute inset-0"
          >
            <IconCodexBlue />
          </motion.div>
        </div>
        <Typography variant={'label'}>Подробнее о CODEX</Typography>
      </motion.div>

      <motion.div
        className="group flex items-center justify-start gap-2 rounded-lg px-2 py-3"
        variants={containerVariantsGreen}
        initial="rest"
        whileHover="hover"
      >
        <div className="relative h-5 w-5">
          <motion.div
            variants={iconGrayVariants}
            className="absolute inset-0"
          >
            <IconSmileGray />
          </motion.div>
          <motion.div
            variants={iconColorVariants}
            className="absolute inset-0"
          >
            <IconSmileGreen />
          </motion.div>
        </div>
        <Typography variant={'label'}>Помочь в улучшении</Typography>
      </motion.div>
    </div>
  )
}
