import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ISortMode } from '../types'

export const useSortModeFilter = (defaultSortMode: ISortMode) => {
  const [selectedSortMode, setSelectedSortMode] =
    useState<ISortMode>(defaultSortMode)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const sortMode = searchParams.get('sort_mode') as ISortMode
    if (sortMode) {
      setSelectedSortMode(sortMode)
    }
  }, [location.search])

  const handleSortModeChange = (sortMode: ISortMode) => {
    setSelectedSortMode(sortMode)
  }

  const handleApply = () => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('sort_mode', selectedSortMode)
    navigate({ search: searchParams.toString() })
  }

  const handleReset = () => {
    setSelectedSortMode(defaultSortMode)
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete('sort_mode')
    navigate({ search: searchParams.toString() })
  }

  return {
    selectedSortMode,
    handleSortModeChange,
    handleApply,
    handleReset,
  }
}
