import { cn } from '@/shared/utils/common'
import { useEffect } from 'react'

import { useSidebar } from '../../../../widgets/Sidebar'
import { IChatMessage } from '../../types'
import { ChatInput } from '../ChatInput'
import { ChatMessages } from '../ChatMessages'

interface ChatContainerProps {
  messages: IChatMessage[]
}

export const ChatContainer = ({ messages }: ChatContainerProps) => {
  const { isSidebarOpen } = useSidebar()
  console.log(messages)

  useEffect(() => {
    const lastMessageElement = document.getElementById('last-message')
    if (lastMessageElement) {
      lastMessageElement.scrollIntoView({ behavior: 'smooth' })
    }
  }, [messages])

  if (!messages) return null

  return (
    <div className="relative flex min-h-full w-full flex-col">
      <div className="flex-grow overflow-y-auto">
        <div
          className={cn(
            isSidebarOpen ? 'lg:min-w-[672px]' : 'lg:min-w-[768px]',
            `relative mx-auto flex w-full min-w-[672px] flex-col items-stretch
            justify-start gap-8 self-center bg-[#FCFCFC] pb-12 pt-24
            lg:max-w-[850px]`
          )}
        >
          <ChatMessages messages={messages} />
        </div>
      </div>

      <div className="w-full">
        <ChatInput />
      </div>
    </div>
  )
}
