import IconCalendarOutlinedDark from '@/shared/assets/icons/icon_calendar_outlined_dark.svg?react'
import IconClose from '@/shared/assets/icons/icon_close.svg?react'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { Button } from '@/shared/ui/Button'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTrigger,
} from '@/shared/ui/Drawer'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useState,
} from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useLocation, useNavigate } from 'react-router-dom'

interface CustomInputProps {
  value?: string
  onClick?: () => void
  className?: string
}

const CustomInput: ForwardRefRenderFunction<
  HTMLButtonElement,
  CustomInputProps
> = ({ value, onClick, className }, ref) => (
  <div className="flex flex-col items-start gap-[3px]">
    <button
      className={`${className} flex h-[40px] items-center justify-between gap-6
        self-stretch rounded-lg border border-[#E3E5E8] bg-white px-4 py-2`}
      onClick={onClick}
      ref={ref}
    >
      <Typography className="font-Inter whitespace-nowrap text-sm font-medium leading-[20px]">
        {value ? value : 'Дата'}
      </Typography>
      <IconCalendarOutlinedDark />
    </button>
  </div>
)

const CustomDateInput = forwardRef(CustomInput)

interface SelectedDate {
  year: number
  month: number
}

export const MobileRatificationDateDrawer = () => {
  const [startMonth, setStartMonth] = useState<
    SelectedDate | undefined
  >(undefined)
  const [endMonth, setEndMonth] = useState<SelectedDate | undefined>(
    undefined
  )
  const isDesktop = useMediaQuery('(min-width: 960px)')

  const navigate = useNavigate()
  const location = useLocation()
  const startYear = 1992
  const currentYear = new Date().getFullYear()
  const months = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ]

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const created_at = searchParams.get('created_at')
    if (created_at) {
      const [start, end] = created_at.split('|')
      const [startYear, startMonth] = start.split('-').map(Number)
      const [endYear, endMonth] = end.split('-').map(Number)
      setStartMonth({ month: startMonth, year: startYear })
      setEndMonth({ month: endMonth, year: endYear })
    }
  }, [location.search])

  const handleMonthClick = (year: number, month: number) => {
    const selectedDate = { year, month }

    if (
      !startMonth ||
      (startMonth &&
        !endMonth &&
        (year < startMonth.year ||
          (year === startMonth.year && month < startMonth.month)))
    ) {
      setStartMonth(selectedDate)
      setEndMonth(undefined)
    } else if (startMonth && !endMonth) {
      if (
        year > startMonth.year ||
        (year === startMonth.year && month > startMonth.month)
      ) {
        setEndMonth(selectedDate)
      } else {
        setStartMonth(selectedDate)
        setEndMonth(undefined)
      }
    } else {
      setStartMonth(selectedDate)
      setEndMonth(undefined)
    }
  }

  const handleApply = () => {
    if (startMonth && endMonth) {
      const createdAt = `${startMonth.year}-${startMonth.month}|${endMonth.year}-${endMonth.month}`
      const searchParams = new URLSearchParams(location.search)
      searchParams.set('created_at', createdAt)
      navigate({ search: searchParams.toString() })
    }
  }

  const handleReset = () => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete('created_at')
    navigate({ search: searchParams.toString() })
    setStartMonth(undefined)
    setEndMonth(undefined)
  }

  const renderMonthsGrid = (year: number) => (
    <div
      key={year}
      className="flex flex-col items-stretch justify-start gap-8"
    >
      <Typography
        className="text-center font-semibold"
        variant={'body'}
      >
        {year}
      </Typography>
      <div className="grid grid-cols-3 grid-rows-4 gap-2">
        {months.map((month, index) => (
          <div
            key={index}
            onClick={() => handleMonthClick(year, index)}
            className={cn(
              'flex h-[52px] flex-col items-center justify-center gap-2 rounded-lg',
              'hover:cursor-pointer hover:bg-[#F0F0F0] hover:text-black',
              {
                'bg-[#F0F0F0]':
                  startMonth &&
                  endMonth &&
                  (year > startMonth.year ||
                    (year === startMonth.year &&
                      index > startMonth.month)) &&
                  (year < endMonth.year ||
                    (year === endMonth.year &&
                      index < endMonth.month)),
                [`bg-secondary-primary-midnight font-semibold text-white
                hover:bg-secondary-primary-midnight hover:text-white`]:
                  (startMonth &&
                    startMonth.year === year &&
                    startMonth.month === index) ||
                  (endMonth &&
                    endMonth.year === year &&
                    endMonth.month === index),
              }
            )}
          >
            <Typography
              variant={'label'}
              className={cn({
                'font-semibold text-white':
                  (startMonth &&
                    startMonth.year === year &&
                    startMonth.month === index) ||
                  (endMonth &&
                    endMonth.year === year &&
                    endMonth.month === index),
              })}
            >
              {month}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  )

  const formatMonth = (date: SelectedDate | undefined) => {
    if (!date) return ''
    return `${months[date.month]} ${date.year}`
  }

  const formatMonthShort = (date: SelectedDate | undefined) => {
    if (!date) return ''
    return `${months[date.month].slice(0, 3)}. ${date.year}`
  }

  return (
    <Drawer direction="bottom">
      <DrawerTrigger asChild>
        <CustomDateInput
          value={
            startMonth && endMonth
              ? `${formatMonthShort(startMonth)} - ${formatMonthShort(endMonth)}`
              : ''
          }
        />
      </DrawerTrigger>
      <DrawerContent className="z-[70] h-[100svh] overflow-y-hidden sm:h-[90vh] md:h-[88vh]">
        <DrawerClose className="absolute left-1 top-1">
          <IconClose className="h-6 w-6" />
        </DrawerClose>
        <DrawerHeader className="flex flex-col items-stretch justify-start gap-3 p-0">
          <Typography className="text-center text-[28px] font-bold leading-[34px] text-black">
            Дата ратификации
          </Typography>

          {startMonth || endMonth ? (
            <Typography className="text-center leading-6">
              {`${formatMonth(startMonth)} - ${formatMonth(endMonth)}`}
            </Typography>
          ) : (
            <Typography
              variant={'subtitle'}
              className="text-center leading-6"
            >
              Выберите период
            </Typography>
          )}
        </DrawerHeader>
        <DrawerDescription className="-mx-6 mt-8 overflow-y-scroll px-6">
          <div className="flex flex-col items-stretch justify-start gap-8">
            {Array.from(
              { length: currentYear - startYear + 1 },
              (_, i) => currentYear - i
            ).map((year) => renderMonthsGrid(year))}
          </div>
        </DrawerDescription>
        <DrawerFooter className="flex flex-row flex-nowrap justify-between gap-5 p-0 pb-5">
          <DrawerClose className="shrink-0 grow">
            <Button
              variant={'tonal'}
              className="w-full"
              onClick={handleApply}
            >
              Применить
            </Button>
          </DrawerClose>
          <DrawerClose className="shrink-0 grow">
            <Button
              variant={'outlined'}
              className="w-full"
              onClick={handleReset}
            >
              Сбросить
            </Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
