import { ISigninIndividualDTO } from '@/entities/signin/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface SigninIndividualState {
  data: ISigninIndividualDTO
  codeSent: boolean
  canResend: boolean
  expiryTimestamp: string | null // Store as ISO string
}

const initialState: SigninIndividualState = {
  data: {
    phone: '',
    verification_code: '',
  },
  codeSent: false,
  canResend: false,
  expiryTimestamp: null,
}

const signinIndividualSlice = createSlice({
  name: 'signinIndividual',
  initialState,
  reducers: {
    resetIndividualSigninState: (state) => {
      state.data = initialState.data
      state.codeSent = false
      state.expiryTimestamp = null
      state.canResend = false
    },
    updateSigninData: (
      state,
      action: PayloadAction<Partial<ISigninIndividualDTO>>
    ) => {
      state.data = { ...state.data, ...action.payload }
    },
    setCodeSent: (state, action: PayloadAction<boolean>) => {
      state.codeSent = action.payload
      if (action.payload) {
        const newExpiryTimestamp = new Date().toISOString() // Store as ISO string
        state.expiryTimestamp = newExpiryTimestamp
      } else {
        state.expiryTimestamp = null
      }
    },
    setCanResend: (state, action: PayloadAction<boolean>) => {
      state.canResend = action.payload
    },
  },
})

export const {
  resetIndividualSigninState,
  updateSigninData,
  setCodeSent,
  setCanResend,
} = signinIndividualSlice.actions

export default signinIndividualSlice.reducer
