import IconArrowThinkUp from '@/shared/assets/icons/icon_arrow_thick_up.svg?react'
import IconDownloadDark from '@/shared/assets/icons/icon_download_dark.svg?react'
import IconFolderDark from '@/shared/assets/icons/icon_folder_dark.svg?react'
import IconInfoDark from '@/shared/assets/icons/icon_info_dark.svg?react'
import IconMarkDark from '@/shared/assets/icons/icon_mark_dark.svg?react'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from '@/shared/ui/Menubar'
import { Typography } from '@/shared/ui/Typography'
import { useBoundary } from '@/shared/utils/useBoundary'
import { ReactNode } from 'react'

export const DocumentsBaseDocumentMenu = ({
  children,
  onInfoClick,
}: {
  children: ReactNode
  onInfoClick: () => void
}) => {
  const boundaryElements = useBoundary('menu_boundary')

  return (
    <>
      <Menubar className="flex items-center justify-stretch self-stretch border-none">
        <MenubarMenu>
          <MenubarTrigger
            onClick={(e) => e.stopPropagation()}
            className="absolute right-3 top-3 z-30 flex h-6 w-6 items-center justify-start
              rounded-lg bg-[#FCFDFD] hover:cursor-pointer hover:bg-[#F0F0F0]"
          >
            {children}
          </MenubarTrigger>

          <MenubarContent
            className="relative flex flex-col items-stretch gap-3 rounded-[20px] bg-white p-2"
            collisionBoundary={boundaryElements}
            collisionPadding={{ top: 120 }}
            align="start"
            style={{
              boxShadow:
                '0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
            }}
            alignOffset={-150}
            sideOffset={10} // Adjust this if necessary to position the content correctly
          >
            {/* Arrow Icon positioned at right-[20px] */}
            <IconArrowThinkUp className="absolute -top-[14px] right-[20px] h-5 w-5" />

            {/* Menu Items */}
            <MenubarItem
              inset={true}
              className="p-0"
              onClick={onInfoClick}
            >
              <div
                className="flex w-full items-center gap-3 rounded-lg p-2 hover:cursor-pointer
                  hover:bg-slate-100"
              >
                <IconInfoDark className="h-5 w-5" />
                <Typography className="text-nowrap font-normal">
                  О документе
                </Typography>
              </div>
            </MenubarItem>

            <MenubarItem inset={true} asChild className="p-0">
              <div
                className="group flex items-center gap-3 self-stretch rounded-lg p-2
                  hover:cursor-pointer hover:bg-[#E7EFFE]"
              >
                <IconMarkDark
                  className="h-5 w-5 transition-colors duration-300 ease-in-out
                    group-hover:fill-[#4D8EFF]"
                />
                <Typography className="text-nowrap font-normal">
                  В избранное
                </Typography>
              </div>
            </MenubarItem>

            <MenubarItem inset={true} asChild className="p-0">
              <div
                className="flex items-center gap-3 self-stretch rounded-lg p-2
                  hover:cursor-pointer hover:bg-slate-100"
              >
                <IconFolderDark className="h-5 w-5" />
                <Typography className="text-nowrap font-normal">
                  В папку
                </Typography>
              </div>
            </MenubarItem>

            <MenubarItem inset={true} asChild className="p-0">
              <div
                className="flex items-center gap-3 self-stretch rounded-lg p-2
                  hover:cursor-pointer hover:bg-slate-100"
              >
                <IconDownloadDark className="h-5 w-5" />
                <Typography className="text-nowrap font-normal">
                  Скачать
                </Typography>
              </div>
            </MenubarItem>
          </MenubarContent>
        </MenubarMenu>
      </Menubar>
    </>
  )
}
