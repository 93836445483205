import { Paragraph } from '@/entities/document'
import { DocumentContentsProvider } from '@/features/document/contexts/DocumentContentContext'
import { useGetDocumentByIdQuery } from '@/shared/api/documents'
import { Skeleton } from '@/shared/ui'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { Sidebar, useSidebar } from '@/widgets/Sidebar'
import { WidgetDocumentContents } from '@/widgets/WidgetDocumentContents'
import { WidgetDocumentMenuActions } from '@/widgets/WidgetDocumentMenuActions'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import './document.css'

export const DocumentByIdPage = () => {
  const { id: documentId } = useParams()
  const { data, error, isLoading } = useGetDocumentByIdQuery({
    id: Number(documentId),
  })

  const documentContent = useMemo(() => {
    if (data && data.document && data.document.paragraphs) {
      return data.document.paragraphs
        .map((paragraph) => paragraph.content)
        .join('')
    }
    return ''
  }, [data])

  const { isSidebarOpen } = useSidebar()
  if (isLoading) {
    return <Typography>Loading...</Typography>
  }
  if (error) {
    return <Typography>Error...</Typography>
  }
  return (
    <div
      className="relative -mt-[96px] flex h-[100vh] w-screen flex-col items-stretch
        justify-start pb-8"
    >
      <div
        className={cn(
          'relative flex items-stretch',
          isSidebarOpen ? 'gap-16' : ''
        )}
        style={{ height: 'calc(100vh - 32px)' }}
      >
        <Sidebar />
        <Skeleton
          className="mx-auto mt-[96px] h-[calc(100dvh)] w-[1160px] rounded-[42px]
            border-[1px] border-base-100 bg-base-300"
          style={{
            boxShadow: '0px 8px 32px 0px rgba(24, 35, 54, 0.12)',
          }}
        ></Skeleton>
      </div>
    </div>

    // <DocumentContentsProvider>
    //   <div
    //     className="relative flex h-[calc(100dvh-96px)] w-full items-stretch justify-start
    //       gap-8 px-2"
    //   >
    //     {/* <WidgetDocumentContents /> */}
    //     <div className="flex w-[1340px] flex-col items-stretch justify-start gap-8">
    //       <div className="py-2 text-center">
    //         <Typography
    //           variant={'label'}
    //           className="font-medium text-base-500"
    //         >
    //           {data?.document?.name}
    //         </Typography>
    //       </div>
    //       {/* Document Content */}
    //       <div className="flex w-full grow flex-col items-center justify-start pb-[98px]">
    //         <div
    //           className="flex w-full flex-col items-center justify-start gap-8 rounded-[16px]
    //             border-[1px] border-base-100 bg-white px-10 py-8"
    //           style={{
    //             boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.04)',
    //           }}
    //         >
    //           {data?.document?.paragraphs && (
    //             <ParagraphRenderer
    //               paragraphs={data.document.paragraphs}
    //             />
    //           )}
    //         </div>
    //       </div>
    //     </div>
    //     <WidgetDocumentMenuActions />
    //   </div>
    // </DocumentContentsProvider>
  )
}

// Example with Tailwind classes
const getParagraphClass = (type: 'COMMENT' | 'TEXT' | 'HEADING') => {
  switch (type) {
    case 'HEADING':
      return 'text-base-grey-950 font-serif text-[18px] font-bold leading-[26px]'
    case 'COMMENT':
      return 'text-comment-red font-serif text-[18px] font-normal leading-[26px]'
    case 'TEXT':
      return 'text-base-grey-950 font-serif text-[18px] font-normal leading-[26px]'
    default:
      return ''
  }
}

// Main rendering logic
const ParagraphRenderer = ({
  paragraphs,
}: {
  paragraphs: Paragraph[]
}) => {
  return (
    <div className="flex flex-col gap-1">
      {paragraphs.map((paragraph) => (
        <p
          key={paragraph.id}
          className={getParagraphClass(paragraph.type)}
          dangerouslySetInnerHTML={{ __html: paragraph.content }}
        />
      ))}
    </div>
  )
}
