import { IChatAiFile } from '@/entities/chat/types'
import { ChatAiFileDrawerMenu } from '@/entities/chat/ui/ChatAiFileDrawerMenu'
import { ChatPanel } from '@/entities/chat/ui/ChatPanel'
import IconClose from '@/shared/assets/icons/icon_close_gray.svg?react'
import IconExportGray from '@/shared/assets/icons/icon_export_gray.svg?react'
import IconMoreDots from '@/shared/assets/icons/icon_more_dots_dark.svg?react'
import {
  RootState,
  useAppDispatch,
  useTypedSelector,
} from '@/shared/store'
import { removeDocument } from '@/shared/store/slices/chat/documentPreviewSlice'
// import { removeDocumentById } from '@/shared/store/slices/chat/chatSlice'
import { IconButton } from '@/shared/ui/IconButton'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { useState } from 'react'
import {
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from 'react-resizable-panels'

import { DocumentInfoDialog } from '../DocumentInfoDialog'

export const DocumentPreviewContainer = () => {
  const dispatch = useAppDispatch()
  const { documents, isChatOpen } = useTypedSelector(
    (state) => state.documentPreview
  )

  const [isInfoDialogOpen, setIsInfoDialogOpen] =
    useState<boolean>(false)

  const handleInfoClick = () => {
    setIsInfoDialogOpen((prev) => !prev)
  }
  const handleInfoClose = () => {
    setIsInfoDialogOpen(false)
  }

  if (!documents || documents.length === 0) return null
  const handleRemoveDocument = (id: number) => {
    dispatch(removeDocument(id))
  }

  const renderPanel = (document: IChatAiFile) => (
    <>
      <Panel
        key={document.id}
        className="relative flex shrink-0 flex-col items-center rounded-[40px] bg-white
          shadow-shadow2 lg:max-w-[868px] xl:max-w-[1176px]"
        defaultSize={50}
        minSize={40}
      >
        <div
          className="flex min-h-[60px] items-start justify-between gap-8 self-stretch px-5
            pt-5"
        >
          <div className="flex items-center justify-between gap-8">
            <DocumentInfoDialog
              open={isInfoDialogOpen}
              onClose={handleInfoClose}
            />
            <ChatAiFileDrawerMenu onInfoClick={handleInfoClick}>
              <IconButton>
                <IconMoreDots />
              </IconButton>
            </ChatAiFileDrawerMenu>
            <IconButton>
              <IconExportGray />
            </IconButton>
          </div>
          <Typography variant={'heading3'} className="truncate">
            {document.name}
          </Typography>
          <div className="flex items-center justify-between gap-8">
            <div className="h-6 w-6" />
            <IconButton
              onClick={() => handleRemoveDocument(document.id)}
            >
              <IconClose />
            </IconButton>
          </div>
        </div>
        <div
          className="custom-scrollbar flex flex-col items-center gap-4 self-stretch
            overflow-auto px-5 pb-6 pt-8"
        >
          <Typography className="text-base font-normal leading-normal text-black">
            {`In the first version, the async function awaits the rejected promise before returning its result, which causes the rejection to be turned into an exception and the catch clause to be reached; the function will thus return a promise resolving to the string "Saved!".

The second version of the function, however, does return the rejected promise directly without awaiting it within the async function, which means that the catch case is not called and the caller gets the rejection instead.`}
          </Typography>
        </div>
      </Panel>
    </>
  )

  return (
    <>
      <PanelGroup
        className={cn(
          'fixed bottom-0 left-0 z-40 bg-white shadow-lg',
          `flex max-h-[calc(100%-72px)] w-screen transform items-stretch
          justify-center gap-2 px-9 pb-8 pt-16`
        )}
        autoSaveId="example"
        direction="horizontal"
      >
        {documents.length === 1 && !isChatOpen ? (
          renderPanel(documents[0])
        ) : documents.length === 1 && isChatOpen ? (
          <>
            {renderPanel(documents[0])}
            <PanelResizeHandle className="h-[16px] w-[3px] self-center rounded-sm bg-[#182336] opacity-[0.38]" />
            <ChatPanel />
          </>
        ) : documents.length === 2 ? (
          <>
            {renderPanel(documents[0])}
            <PanelResizeHandle className="h-[16px] w-[3px] self-center rounded-sm bg-[#182336] opacity-[0.38]" />
            {renderPanel(documents[1])}
          </>
        ) : null}
      </PanelGroup>
      {isChatOpen && documents.length === 2 && (
        <PanelGroup
          direction="horizontal"
          className={cn(
            'fixed bottom-0 left-0 z-50 bg-transparent shadow-lg',
            `flex max-h-[calc(100%-72px)] w-screen transform items-stretch
            justify-center gap-2 px-9 pb-8 pt-16`
          )}
          autoSaveId="example"
        >
          <Panel />
          <PanelResizeHandle className="h-[16px] w-[3px] self-center rounded-sm bg-[#182336] opacity-[0.38]" />
          <ChatPanel />
        </PanelGroup>
      )}
    </>
  )
}
