import { toast } from '@/shared/ui/Toast/useToast'
import {
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
  SerializedError,
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

interface ErrorResponseData {
  success: boolean
  code: number
  message: string
}

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      const error = action.payload as
        | FetchBaseQueryError
        | SerializedError

      // Check if the error is a FetchBaseQueryError and has a data property
      if ('status' in error) {
        const errorData = error.data as ErrorResponseData
        const errorMessage =
          errorData?.message || 'An unknown error occurred'

        // Display a toast message based on the HTTP status code
        if (error.status === 404) {
          // toast({
          //   title: 'Not Found',
          //   description: errorMessage,
          //   variant: 'error',
          // })
        } else if (error.status === 401) {
          // toast({
          //   title: 'Unauthorized Access',
          //   description: errorMessage,
          //   variant: 'error',
          // })
        } else {
          toast({
            title: 'Что-то пошло не так',
            description: errorMessage,
            variant: 'error',
          })
        }
      }
      // Handle SerializedError (usually unexpected errors)
      else if ('message' in error) {
        toast({
          title: 'Error',
          description: error.message || 'An unknown error occurred',
          variant: 'error',
        })
      } else {
        toast({
          title: 'Unknown Error',
          description: 'An unknown error occurred',
          variant: 'error',
        })
      }
    }

    return next(action)
  }
