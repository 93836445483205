import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { IActForm } from '../types'

export const useActFormFilter = (mockData: IActForm[]) => {
  const [selectedIndices, setSelectedIndices] = useState<number[]>([])
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const actForm = searchParams.get('act_form')?.split(',') || []
    if (actForm.length) {
      setSelectedIndices(actForm.map(Number))
    }
  }, [location.search])

  const handleCheckboxChange = (id: number) => {
    setSelectedIndices((prev) =>
      prev.includes(id)
        ? prev.filter((index) => index !== id)
        : [...prev, id]
    )
  }

  const handleApply = () => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete('act_form')
    if (selectedIndices.length > 0) {
      searchParams.set('act_form', selectedIndices.join(','))
    }
    navigate({ search: searchParams.toString() })
  }

  const handleReset = () => {
    setSelectedIndices([])
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete('act_form')
    navigate({ search: searchParams.toString() })
  }

  return {
    selectedIndices,
    handleCheckboxChange,
    handleApply,
    handleReset,
  }
}
