import { Skeleton } from '@/shared/ui/Skeleton'
import { Typography } from '@/shared/ui/Typography'
import { forwardRef } from 'react'

const DocumentItemSkeleton = forwardRef<HTMLDivElement>((_, ref) => (
  <Skeleton
    className="flex shrink grow-0 basis-[86px] items-stretch justify-start gap-1
      rounded-none py-3 pl-4 pr-14 shadow-shadow3"
  >
    <div className="flex shrink grow basis-[44px] items-start justify-start">
      <Skeleton className="h-6 w-6 bg-base-50" />
    </div>
    <div className="flex grow basis-full flex-col items-stretch justify-start gap-2">
      <Skeleton className="h-6 w-full bg-base-50" />
      <Skeleton className="h-6 w-full bg-base-50" />
    </div>
  </Skeleton>
))

DocumentItemSkeleton.displayName = 'DocumentItemSkeleton'

export { DocumentItemSkeleton }

{
  /* <div
className={cn(
  'relative border-b-[#F0F0F0] bg-white py-3 pl-4 pr-14',
  isLastDocumentItem
    ? 'rounded-b-3xl border-b-0 pb-4'
    : 'border-b-[1px]'
)}
>
<div ref={ref} className="relative pl-8">
  <Typography
    variant={'body'}
    className="absolute left-0 top-0 font-medium"
  >
    {documentItem.id + 1}.
  </Typography>

  <div className="flex flex-col items-stretch justify-start gap-2">
    <Typography variant={'body'}>
      {documentItem.name}
    </Typography>
    <div className="flex flex-col items-stretch justify-start">
      {documentItem.additional_info.length > 0 &&
        documentItem.additional_info.map((item, index) => {
          if (
            index ===
            documentItem.additional_info.length - 1
          )
            return (
              <div className="flex items-center justify-start gap-3">
                <Typography
                  variant={'label'}
                  className="font-medium text-[#5E626A]"
                >
                  {item}
                </Typography>
                <DocumentItemStatus
                  status={documentItem.status}
                />
              </div>
            )
          return (
            <Typography
              variant={'label'}
              className="font-medium text-[#5E626A]"
            >
              {item}
            </Typography>
          )
        })}
    </div>
  </div> */
}
