import { DocumentItemStatus } from '@/entities/document/ui/DocumentItemStatus'
import IconExportGray from '@/shared/assets/icons/icon_export_gray.svg?react'
import IconFileOutlinedDark from '@/shared/assets/icons/icon_file_outlined_dark.svg?react'
import IconMaximize from '@/shared/assets/icons/icon_maximize.svg?react'
import IconRowVertical from '@/shared/assets/icons/icon_row_vertical.svg?react'
import { useAppDispatch, useTypedSelector } from '@/shared/store'
import { addDocument } from '@/shared/store/slices/chat/documentPreviewSlice'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from '@/shared/ui/Menubar'
import { Typography } from '@/shared/ui/Typography'
import { useBoundary } from '@/shared/utils/useBoundary'
import * as MenubarPrimitive from '@radix-ui/react-menubar'
import {
  LegacyRef,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react'

import { IChatAiFile } from '../../types'

type ChatAiFileProps = {
  file: IChatAiFile
}

export const ChatAiFile = ({ file }: ChatAiFileProps) => {
  const dispatch = useAppDispatch()
  const { chats } = useTypedSelector((state) => state.chat)

  const boundaryElements = useBoundary('menu_boundary')

  // const handleOpenDocument = () => {
  //   dispatch(
  //     addDocument({
  //       id: file.id,
  //       name: file.name,
  //       chunks: file.chunks,
  //     })
  //   )
  // }

  return (
    <Menubar
      key={file.id}
      className="flex items-center justify-stretch self-stretch"
    >
      <MenubarMenu>
        <MenubarTrigger
          className="flex w-full flex-col items-stretch justify-start gap-2 rounded-[16px]
            border border-[#F0F0F0] bg-white p-4 hover:cursor-pointer
            hover:bg-slate-100"
        >
          <Typography
            variant={'body'}
            className="break-words text-start font-medium leading-[24px]"
          >
            {file.name}
          </Typography>
          {file.additional_info && file.additional_info.length > 0 ? (
            <ul
              className="list-disc flex-col items-stretch justify-start gap-1 pl-4
                marker:text-base-500"
            >
              {file.additional_info.map((item, index) => {
                return (
                  <li
                    key={`additional-info-${file.id},${index}`}
                    className=""
                  >
                    <div className="inline-flex w-full items-start justify-start gap-2">
                      <Typography
                        variant={'label'}
                        className="text-start"
                      >
                        {item}
                      </Typography>
                      {index === file.additional_info.length - 1 && (
                        <DocumentItemStatus status={file.status} />
                      )}
                    </div>
                  </li>
                )
              })}
            </ul>
          ) : null}
        </MenubarTrigger>
        <MenubarContent
          collisionBoundary={boundaryElements}
          collisionPadding={{ right: -20, top: 10, bottom: 15 }}
          className="z-50 flex w-[174px] flex-col items-stretch gap-3 rounded-[20px]
            bg-white p-2 shadow-shadow2"
          align="end"
          alignOffset={-150}
          sideOffset={-24}
        >
          <MenubarItem
            className="flex items-center justify-start gap-3 rounded-lg p-2
              hover:cursor-pointer hover:bg-slate-100"
            // onClick={handleOpenDocument}
            inset={true}
          >
            <IconMaximize className="h-6 w-6" />
            <Typography className="whitespace-nowrap font-normal">
              Открыть здесь
            </Typography>
          </MenubarItem>

          <MenubarItem
            asChild
            onClick={() => {
              const newTab = window.open('', '_blank')
              if (newTab) {
                newTab.document.write(`
            <!DOCTYPE html>
            <html lang="en">
              <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>${file.name}</title>
                <style>
                  body {
                    font-family: 'Inter', sans-serif;
                    margin: 0;
                    padding: 20px;
                    color: #000;
                  }
                  h1 {
                    text-align: center;
                    font-size: 2em;
                    font-weight: bold;
                    margin-bottom: 20px;
                  }
                  p {
                    font-size: 1em;
                    line-height: 1.5;
                  }
                </style>
              </head>
              <body>
                <h1>${file.name}</h1>
              </body>
            </html>
          `)
                newTab.document.close()
              }
            }}
            className="gap-3 rounded-lg p-2 hover:cursor-pointer hover:bg-slate-100"
          >
            <div
              className="flex items-center justify-start gap-3 rounded-lg p-2
                hover:cursor-pointer hover:bg-slate-100"
            >
              <IconExportGray className="h-6 w-6" />

              <Typography className="font-normal">
                В новом окне
              </Typography>
            </div>
          </MenubarItem>
          {/* {chats.documents && chats.documents.length >= 1 ? (
            <MenubarItem
              className="flex items-center justify-start gap-3 rounded-lg p-2
                hover:cursor-pointer hover:bg-slate-100"
              onClick={handleOpenDocument}
              inset={true}
            >
              <IconRowVertical className="h-6 w-6" />
              <Typography
                
                className="whitespace-nowrap font-normal"
              >
                Сравнить
              </Typography>
            </MenubarItem>
          ) : null} */}
        </MenubarContent>
      </MenubarMenu>
    </Menubar>
  )
}
