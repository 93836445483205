import { ReactNode } from 'react'

export enum EPlatformPage {
  Assistant = 'assistant',
  Documents = 'documents',
  CourtBase = 'court-base',
}

export const platformPages = [
  EPlatformPage.Assistant,
  EPlatformPage.Documents,
  EPlatformPage.CourtBase,
]
export interface SegmentedItem {
  value: EPlatformPage
  icon?: ReactNode
  iconActive?: ReactNode
  label: ReactNode
  disabled?: boolean
}
