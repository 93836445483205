import { IResponseError } from '@/shared/api'
import { IAuthResponse } from '@/shared/api/auth'
import {
  useRegisterJudicialMutation,
  useSendMailOtpMutation,
} from '@/shared/api/signupJudicial'
import { useAppDispatch, useTypedSelector } from '@/shared/store'
import { updateSignupData } from '@/shared/store/slices/auth/signupJudicialSlice'
import Cookies from 'js-cookie'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useTimer } from 'react-timer-hook'

import { ISignupJudicialDTO } from '../types'

export const useSignupJudicialOTP = () => {
  const dispatch = useAppDispatch()
  const methods = useForm<ISignupJudicialDTO>()
  const navigate = useNavigate()

  const { data: signupJudicialData } = useTypedSelector(
    (state) => state.signupJudicial
  )

  const [registerJudicial, { isLoading: isRegisterLoading }] =
    useRegisterJudicialMutation()
  const [sendMailOtp] = useSendMailOtpMutation()
  const { seconds, restart } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: () => setCanResend(true),
  })

  const [canResend, setCanResend] = useState(false)

  const onSubmit = methods.handleSubmit(
    useCallback(
      async (data) => {
        dispatch(
          updateSignupData({
            email: data.email,
            password: data.password,
          })
        )
        try {
          const response = await registerJudicial(
            signupJudicialData
          ).unwrap()
          const payload = response as IAuthResponse

          Cookies.set('access_token', payload.access_token, {
            expires: 30,
            secure: true,
            sameSite: 'Strict',
          })
          methods.reset()

          navigate('/')
        } catch (error: any) {
          const errorData = error as IResponseError
        }
      },
      [dispatch, methods, navigate, registerJudicial]
    )
  )

  const handleResendCode = async (email: string) => {
    setCanResend(false)
    try {
      await sendMailOtp(email).unwrap()
      methods.reset()
    } catch (error: any) {
      console.error('OTP resend error')
    } finally {
      dispatch(updateSignupData({ email, password: '' }))
    }
  }

  return {
    methods,
    onSubmit,
    handleResendCode,
    timeLeft: seconds,
    email: signupJudicialData.email,
    errors: methods.formState.errors,
    canResend,
  }
}
