import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/shared/ui/Dialog'
import { Typography } from '@/shared/ui/Typography'
import { ReactNode } from 'react'

interface IDocumentDetails {
  title: string
  publicationDate: string
  adoptionDate: string
  ratificationDate: string
  enforcementDate: string
  revisionDate: string
  body: string
}

type Props = {
  documentInfo?: IDocumentDetails
  open: boolean
  onClose: () => void
}

const mockDocumentDetails: IDocumentDetails = {
  title:
    'Постоянное информационно-пропагандистское обеспечение нашей деятельности позволяет оценить значение существенных финансовых и административных условий.',
  publicationDate: '14.06.2024',
  adoptionDate: '14.06.2024',
  ratificationDate: '14.06.2024',
  enforcementDate: '14.06.2024',
  revisionDate: '14.06.2024',
  body: 'Nulla enim tempor anim incididunt. Qui reprehenderit do duis excepteur ex aliqua minim. Cillum adipisicing est voluptate veniam Cupidatat laboris esse reprehenderit voluptate cupidatat aute consectetur officia irure. Aliqua ullamco elit aliquip duis pariatur eu labore sit sint. Labore sunt ex excepteur irure velit consectetur ullamco eiusmod velit nulla. Reprehenderit occaecat nulla veniam excepteur voluptate sint nisi incididunt laborum est pariatur. Velit id est irure anim. Tempor deserunt incididunt officia consectetur deserunt consequat esse veniam ut exercitation do ex sit fugiat. Mollit est ad do elit officia.\
  Fugiat adipisicing deserunt dolore elit duis dolor incididunt sit officia et in. Dolor incididunt reprehenderit ea sit adipisicing velit. Elit minim commodo sunt tempor velit. Cupidatat Lorem amet ex veniam Lorem.\
  Excepteur esse ex dolore proident cillum magna occaecat mollit nulla enim sint consectetur excepteur. Minim dolore sint elit adipisicing elit esse consequat consectetur mollit laboris ad Lorem mollit. Aliquip exercitation est officia cupidatat laborum laborum aute veniam voluptate qui. Enim in ut cillum aliqua voluptate minim ex.\
  Esse qui culpa in ea elit voluptate est ut ullamco. Eu et pariatur magna reprehenderit velit tempor pariatur enim proident cupidatat est sunt pariatur. Magna laborum occaecat ea exercitation culpa ex do qui nisi enim esse ipsum voluptate. Fugiat dolore excepteur esse nostrud est voluptate veniam duis pariatur ex aliqua laboris. Deserunt officia esse non quis laborum magna cupidatat anim nulla velit aliquip amet.\
  Ullamco quis deserunt eu officia ut consequat est. Fugiat velit id Lorem non dolore pariatur. Ex ut adipisicing incididunt aliquip cillum aute ad eu commodo quis consectetur. Elit ea aute eiusmod dolore voluptate irure nisi ullamco sint sit ullamco nisi Lorem nisi. Officia est in sit do qui elit ex.\
  Exercitation laborum esse enim pariatur nisi adipisicing minim esse non qui minim id exercitation reprehenderit. Quis quis exercitation tempor quis do cupidatat cillum aliquip excepteur. Sunt qui in tempor officia veniam sint cupidatat in enim ea reprehenderit. Exercitation est ea fugiat irure in ea sint eu proident commodo nostrud ea elit ullamco. Sit est elit occaecat deserunt ea. Sunt minim proident officia reprehenderit velit occaecat ut incididunt officia eu dolor Lorem.\
  Commodo id excepteur in nostrud. Qui nostrud elit in ex velit do minim cupidatat ipsum veniam in labore qui eu. Minim ea pariatur aute ipsum anim do nostrud sit. Sunt cillum eiusmod incididunt aliqua voluptate incididunt officia.\
  Excepteur dolore mollit dolore exercitation adipisicing mollit consequat esse cupidatat quis occaecat. Fugiat sunt dolore adipisicing duis ad cupidatat in ullamco qui Lorem anim eu pariatur ut. Nisi sunt ipsum ea ipsum excepteur consectetur eu cillum aliquip excepteur consectetur. Anim exercitation dolor nostrud consequat aute ad nulla adipisicing esse nulla dolore minim velit. Irure consequat velit anim sunt. Aliqua ad reprehenderit excepteur enim irure velit amet.\
  Eu ut ex nostrud Lorem laboris nisi veniam cillum est excepteur veniam. Duis occaecat ea amet duis in anim amet nulla officia id. Consectetur sunt Lorem sit nostrud cillum laboris in fugiat officia. Lorem quis officia eiusmod elit.\
  Occaecat ut cillum cupidatat sit deserunt amet nisi fugiat. Pariatur pariatur nostrud officia nisi elit magna magna quis laborum. Ullamco id tempor nisi anim qui elit anim cupidatat. Nulla dolor amet excepteur exercitation ipsum sint tempor dolore ipsum minim voluptate sunt quis tempor.',
}

export const DocumentInfoDialog = ({
  documentInfo = mockDocumentDetails,
  open = false,
  onClose,
}: Props) => {
  return (
    <Dialog open={open}>
      <DialogContent
        onClose={onClose}
        className="z-[90] gap-8 bg-white"
      >
        <DialogHeader>
          <DialogTitle>
            <Typography variant={'heading5'} className="text-center">
              Информация о документе
            </Typography>
          </DialogTitle>
        </DialogHeader>
        <DialogDescription
          className="-mx-6 flex max-h-[251px] flex-col items-stretch justify-start gap-4
            overflow-y-auto px-6"
        >
          <Typography variant={'body'}>
            {documentInfo.title}
          </Typography>
          <Typography>
            Дата публикации: {documentInfo.publicationDate}
          </Typography>
          <Typography>
            Дата принятия органами: {documentInfo.adoptionDate}
          </Typography>
          <Typography>
            Дата ратификации: {documentInfo.ratificationDate}
          </Typography>
          <Typography>
            Дата вступления в силу: {documentInfo.enforcementDate}
          </Typography>
          <Typography>
            Дата редакции: {documentInfo.revisionDate}
          </Typography>
          <Typography>{documentInfo.body}</Typography>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  )
}
