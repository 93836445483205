import { RadioGroup, RadioGroupItem } from '@/shared/ui/RadioButton'
import { Typography } from '@/shared/ui/Typography'

import { mockSearchModeDataMobile } from '../../consts'
import { ISearchMode } from '../../types'

interface SearchModeRadioGroupProps {
  selectedSearchMode: ISearchMode
  handleSearchModeChange: (SearchMode: ISearchMode) => void
}

export const SearchModeRadioGroup: React.FC<
  SearchModeRadioGroupProps
> = ({ selectedSearchMode, handleSearchModeChange }) => {
  return (
    <RadioGroup
      value={selectedSearchMode}
      onValueChange={handleSearchModeChange}
      className="gap-6"
    >
      {Object.entries(mockSearchModeDataMobile).map(
        ([value, label]) => (
          <label
            key={value}
            className="flex items-center justify-between gap-3 border-b border-b-[#E3E5E8]
              py-3"
          >
            <Typography
              variant="body"
              className="text-sm font-normal"
            >
              {label}
            </Typography>
            <RadioGroupItem value={value as ISearchMode} />
          </label>
        )
      )}
    </RadioGroup>
  )
}
