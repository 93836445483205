import { cn } from '@/shared/utils/common'
import * as React from 'react'

import { Button, ButtonProps } from '../Button'

type IconButtonElement = React.ElementRef<typeof Button>

interface IconButtonProps extends ButtonProps {
  withHover?: boolean
}

const IconButton = React.forwardRef<
  IconButtonElement,
  IconButtonProps
>(({ className, withHover = true, ...props }, forwardedRef) => (
  <Button
    variant="unstyled"
    {...props}
    ref={forwardedRef}
    className={cn(
      'rounded-lg p-0 transition-transform duration-200 ease-in-out',
      withHover && 'transform hover:scale-[1.1] active:scale-[0.95]',
      className
    )}
    aria-label={props['aria-label'] || 'icon button'}
  />
))

IconButton.displayName = 'IconButton'

export { IconButton }
