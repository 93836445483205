import { format, isAfter, isBefore, isEqual, isValid } from 'date-fns'

export const isCurrentMonth = ({
  year,
  month,
}: {
  year: number
  month: number
}) => {
  const currentDate = new Date()

  return (
    month === currentDate.getMonth() &&
    year === currentDate.getFullYear()
  )
}

export const isMonthInRange = (
  month: number,
  year: number,
  startDate?: Date,
  endDate?: Date
): boolean => {
  if (!startDate || !endDate) return false

  const targetDate = new Date(year, month)

  return (
    (isAfter(targetDate, startDate) ||
      isEqual(targetDate, startDate)) &&
    (isBefore(targetDate, endDate) || isEqual(targetDate, endDate))
  )
}

export const isStartOrEndMonth = (
  month: number,
  year: number,
  startDate?: Date,
  endDate?: Date
): boolean => {
  let isStartMonth = false
  let isEndMonth = false
  if (startDate) {
    isStartMonth =
      startDate &&
      month === startDate.getMonth() &&
      year === startDate.getFullYear()
  }
  if (endDate) {
    isEndMonth =
      endDate &&
      month === endDate.getMonth() &&
      year === endDate.getFullYear()
  }

  return isStartMonth || isEndMonth
}

export function parseYearMonth(dateStr: string): Date | null {
  const [yearStr, monthStr] = dateStr.split('-')

  if (!yearStr || !monthStr) return null

  const year = parseInt(yearStr, 10)
  const month = parseInt(monthStr, 10) - 1

  if (isNaN(year) || isNaN(month)) return null

  const date = new Date(year, month, 1)

  return isValid(date) ? date : null
}

export function formatDatesShort(dateRange: string): string {
  const [start, end] = dateRange.split(' - ')
  const formattedStart = format(new Date(start), 'MMM yyyy')
  const formattedEnd = format(new Date(end), 'MMM yyyy')

  return `${formattedStart} - ${formattedEnd}`
}
