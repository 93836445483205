// ChatByIdPage.tsx
import { useChatMessages } from '@/entities/chat/models/useChatMessages'
import { useFavoriteMessages } from '@/entities/chat/models/useFavoriteMessages'
import { IAiAssistPayload } from '@/entities/chat/types'
import { ChatContainer } from '@/entities/chat/ui/ChatContainer'
import { ChatContainerSkeleton } from '@/entities/chat/ui/ChatContainerSkeleton'
import { ChatPanelButton } from '@/entities/chat/ui/ChatPanelButton'
import { FavoriteChatContainer } from '@/entities/chat/ui/FavoriteChatContainer'
import { MobileChatContainer } from '@/entities/chat/ui/MobileChatContainer'
import { IUser } from '@/entities/user/models'
import { chatApi } from '@/shared/api/chat'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { useAppDispatch, useTypedSelector } from '@/shared/store'
import { useSocket } from '@/socketHook'
import { DocumentPreviewContainer } from '@/widgets/DocumentPreview'
import { useSidebar } from '@/widgets/Sidebar'
import { useLoaderData, useParams } from 'react-router-dom'

export const ChatFavoritesPage = () => {
  const { user } = useLoaderData() as {
    user: IUser
  }

  const isMobile = useMediaQuery('(max-width: 959px)')

  const { messages, isLoading } = useFavoriteMessages()

  const { documents, isChatOpen } = useTypedSelector(
    (state) => state.documentPreview
  )
  const { isSidebarOpen } = useSidebar()

  if (isLoading) {
    return <ChatContainerSkeleton isSidebarOpen={isSidebarOpen} />
  }

  if (!messages) {
    return <div>У вас не избранных сообщений</div>
  }

  return (
    <>
      {isMobile ? (
        <MobileChatContainer messages={messages ?? []} /> //TODO: отдельно рендерить мобилку для избранных
      ) : (
        <FavoriteChatContainer messages={messages ?? []} />
      )}
      <DocumentPreviewContainer />
      {documents.length !== 0 && !isChatOpen && <ChatPanelButton />}
    </>
  )
}
