import { usePostNewChatMutation } from '@/shared/api/chat'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const useHomePageChatInput = () => {
  const [message, setMessage] = useState<string>('')
  const navigate = useNavigate()

  const [postNewChat, { isLoading, isError, error }] =
    usePostNewChatMutation()

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMessage(e.target.value)
  }

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault()
    if (!message.trim()) return

    try {
      const response = await postNewChat({ message }).unwrap()

      if (response.success) {
        navigate(`/chats/${response.chat.id}`)
      }
    } catch (error) {
      console.error('Failed to create new chat:', error)
    }
  }

  return {
    error,
    isError,
    message,
    isLoading,
    handleSubmit,
    handleInputChange,
  }
}
