import {
  ResetPassword,
  SigninIndividual,
  SigninJudicial,
} from '@/entities/signin'
import { SigninProvider } from '@/entities/signin/context/SigninContext'
import IconBackArrowGray from '@/shared/assets/icons/icon_back_arrow_gray.svg?react'
import Logo from '@/shared/assets/icons/logo.svg?react'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { Card } from '@/shared/ui/Card'
import { IconButton } from '@/shared/ui/IconButton'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/shared/ui/Tabs'
import { Typography } from '@/shared/ui/Typography'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export const WidgetSignin = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const initialSignType = searchParams.get('type') || 'individual'
  const [signType, setSignType] = useState(initialSignType)
  const [passwordRecovery, setPasswordRecovery] = useState(false)
  const isMobile = useMediaQuery('(max-width: 479px)')

  const handleTabChange = (value: string) => {
    setSignType(value)
    searchParams.set('type', value)
    setSearchParams(searchParams)
  }

  useEffect(() => {
    if (passwordRecovery) {
      setSignType('judicial')
    }
  }, [passwordRecovery])

  return (
    <SigninProvider>
      <div className="flex flex-col items-center justify-start gap-4 xl:gap-8">
        <LogoHeader />
        <Card
          className="relative flex w-[360px] flex-col gap-8 rounded-[40px] px-4 py-8
            shadow-shadow2 sm:w-[440px] sm:p-10 lg:w-[450px] lg:p-8 xl:w-[544px]"
        >
          {passwordRecovery ? (
            <>
              <PasswordRecoveryHeader
                onBackClick={() => setPasswordRecovery(false)}
              />
              <ResetPassword />
            </>
          ) : (
            <>
              <SigninHeader />
              <Tabs
                value={signType}
                onValueChange={handleTabChange}
                className="inline-flex w-full flex-col items-stretch justify-center"
              >
                <TabsList className="mb-4">
                  <TabsTrigger
                    value="individual"
                    disabled={passwordRecovery}
                  >
                    Физическое лицо
                  </TabsTrigger>
                  <TabsTrigger value="judicial">
                    Юридическое лицо
                  </TabsTrigger>
                </TabsList>
                <TabsContent value="individual">
                  <SigninIndividual isMobile={isMobile} />
                </TabsContent>
                <TabsContent value="judicial">
                  <SigninJudicial
                    isMobile={isMobile}
                    handlePasswordRecovery={() =>
                      setPasswordRecovery(true)
                    }
                  />
                </TabsContent>
              </Tabs>
            </>
          )}
        </Card>
      </div>
    </SigninProvider>
  )
}

// Logo Header Component
const LogoHeader = () => (
  <div
    className="flex h-[50px] w-[360px] flex-col sm:w-[440px] lg:w-[450px]
      xl:w-[544px]"
  >
    <Logo className="self-start xl:self-center" />
  </div>
)

// Password Recovery Header Component
const PasswordRecoveryHeader = ({
  onBackClick,
}: {
  onBackClick: () => void
}) => (
  <div className="mt-6 flex flex-col items-center justify-start gap-6 sm:mt-0">
    <div className="flex w-full items-center justify-between">
      <IconButton
        className="absolute left-4 top-4"
        onClick={onBackClick}
      >
        <IconBackArrowGray />
      </IconButton>
      <Typography variant="heading5" className="grow text-center">
        Восстановление пароля
      </Typography>
    </div>
  </div>
)

// Signin Header Component
const SigninHeader = () => (
  <div className="flex flex-col items-center justify-start gap-4">
    <Typography variant="heading5" className="grow text-center">
      Вход
    </Typography>
  </div>
)
