import IconAiEmpty from '@/shared/assets/icons/icon_ai_empty.svg?react'
import IconAiFilledDark from '@/shared/assets/icons/icon_ai_filled_dark.svg?react'
import IconAiOutlinedDark from '@/shared/assets/icons/icon_ai_filled_dark.svg?react'
import IconDocumentsDark from '@/shared/assets/icons/icon_documents_dark.svg?react'
import IconDocumentsFilled from '@/shared/assets/icons/icon_documents_filled.svg?react'
import IconDocumentsOutlinedGray from '@/shared/assets/icons/icon_documents_outlined_gray.svg?react'
import IconMoreDark from '@/shared/assets/icons/icon_more_dark.svg?react'
import IconWeightsDark from '@/shared/assets/icons/icon_weights_dark.svg?react'
import IconWeightsFilled from '@/shared/assets/icons/icon_weights_filled.svg?react'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { cloneElement, ReactNode, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface IBottomNavigationItem {
  icon: ReactNode
  iconActive: ReactNode
  title: string
  page: string
  disabled?: boolean
}

const navItems: IBottomNavigationItem[] = [
  {
    icon: <IconAiEmpty height={28} width={28} />,
    iconActive: <IconAiFilledDark height={28} width={28} />,
    title: 'AI',
    page: 'assistant',
    disabled: false,
  },
  {
    icon: <IconDocumentsDark height={28} width={28} />,
    iconActive: <IconDocumentsFilled height={28} width={28} />,
    title: 'Документы',
    page: 'documents',
    disabled: false,
  },
  {
    icon: <IconWeightsDark height={28} width={28} />,
    iconActive: <IconWeightsFilled height={28} width={28} />,
    title: 'Судебные',
    page: 'court-base',
    disabled: true,
  },
  {
    icon: <IconMoreDark stroke="#18233661" height={28} width={28} />,
    iconActive: (
      <IconMoreDark stroke="#18233661" height={28} width={28} />
    ),
    title: 'Еще',
    page: 'more',
    disabled: true,
  },
]

export const BottomNavigationBar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [currentPage, setCurrentPage] = useState<string>(
    location.pathname.slice(1) || 'assistant'
  )

  const handleNavigation = (page: string) => {
    setCurrentPage(page)
    switch (page) {
      case 'assistant':
        navigate('/')
        break
      case 'documents':
        navigate('/documents')
        break
      case 'court-base':
        navigate('/court-base')
        break
      case 'more':
        navigate('/more')
        break
      default:
        break
    }
  }

  return (
    <div
      className="fixed bottom-0 z-[60] flex w-full items-center self-stretch border-t
        bg-white pb-2"
    >
      {navItems.map((item, index) => {
        const isActive = currentPage === item.page

        let icon = item.icon

        if (item.page === 'assistant') {
          icon = isActive ? item.iconActive : item.icon
        } else if (item.page === 'documents') {
          icon = isActive ? item.iconActive : item.icon
        } else {
          icon = isActive ? item.iconActive : item.icon
        }

        return (
          <button
            key={index}
            disabled={item.disabled}
            className="flex shrink-0 grow basis-0 cursor-pointer flex-col items-center gap-1
              px-0 pb-[2px] pt-2"
            onClick={() => handleNavigation(item.page)}
          >
            {icon}
            <Typography
              variant={'label'}
              className={cn(
                isActive ? 'text-base-950' : 'text-base-400'
              )}
            >
              {item.title}
            </Typography>
          </button>
        )
      })}
    </div>
  )
}
