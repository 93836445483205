import { useChatInput } from '@/entities/chat/models/useChatInput'
import IconArrowActionGray from '@/shared/assets/icons/icon_arrow_action_gray.svg?react'
import { IconButton } from '@/shared/ui/IconButton'
import { Input } from '@/shared/ui/Input'
import { cn } from '@/shared/utils/common'
import { FormEvent, KeyboardEvent } from 'react'
import { useParams } from 'react-router-dom'

export const ChatInput = ({
  isPanel = false,
}: {
  isPanel?: boolean
}) => {
  const { chat_id } = useParams()

  const {
    isSending,
    inputValue,
    selectRef,
    handleInputChange,
    handleSubmit,
  } = useChatInput({ chatId: chat_id })

  const handleKeyDown = (
    event: KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSubmit(event as unknown as FormEvent<HTMLFormElement>) // Submits the form
    }
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={cn(
        `z-20 mx-auto flex w-full min-w-[672px] items-center justify-center
        lg:max-w-[850px]`,
        isPanel ? 'px-4 pb-4' : 'px-0'
      )}
    >
      <div className="relative flex w-full items-center rounded-[100px] bg-base-100">
        <Input
          textarea
          ref={selectRef}
          value={inputValue}
          canRemove={false}
          onKeyDown={handleKeyDown}
          onChange={handleInputChange}
          disabled={isSending}
          className="w-full bg-base-100 pr-14"
          placeholder="Введите запрос"
        />
        <IconButton
          type="submit"
          disabled={isSending || !inputValue}
          className="absolute bottom-1 right-2 transform opacity-100 [&_svg]:text-black
            [&_svg]:disabled:text-base-300"
        >
          <IconArrowActionGray
            width={44}
            height={44}
            // className="text-white"
          />
        </IconButton>
      </div>
    </form>
  )
}
