import db from '@/db'
import IconChevronRight from '@/shared/assets/icons/icon_chevron_right.svg?react'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/shared/ui/Accordion'
import { Checkbox } from '@/shared/ui/Checkbox'
import { Typography } from '@/shared/ui/Typography'
import React, { useEffect, useState } from 'react'

import { Group, IGroupFilterItem } from '../../types'

interface GroupFilterAccordionProps {
  groups: Group[]
  level?: number
  parentId: number
  isFiltered: boolean
  isSearching: boolean
  selectedIndices: number[]
  handleCheckboxChange: (id: number) => void
}

export const GroupFilterAccordion: React.FC<
  GroupFilterAccordionProps
> = ({
  parentId,
  level = 0,
  groups,
  isFiltered,
  isSearching,
  selectedIndices,
  handleCheckboxChange,
}) => {
  let filteredGroups: Group[]
  console.log(groups)

  if (isFiltered) {
    filteredGroups = groups
  } else {
    filteredGroups = groups.filter(
      (group) => group.parentId === parentId
    )
  }
  console.log('FILTERED CHANGED', filteredGroups)

  return (
    <>
      {filteredGroups.map((group) => {
        const hasChildren =
          !isSearching &&
          groups.some((org) => {
            return org.parentId === group.id
          })

        return (
          <Accordion type="single" collapsible key={group.id}>
            <AccordionItem value={group.id.toString()}>
              <div
                className="flex cursor-pointer items-center justify-start gap-2 self-stretch px-3
                  py-[10px]"
                style={{ marginLeft: `${level * 16}px` }}
              >
                <Checkbox
                  variant={'secondary'}
                  id={`checkbox-${group.id}`}
                  className="shrink"
                  size={'small'}
                  checked={selectedIndices.includes(group.id)}
                  onCheckedChange={() =>
                    handleCheckboxChange(group.id)
                  }
                />
                <AccordionTrigger
                  className="p-0"
                  iconSvg={
                    hasChildren ? (
                      <IconChevronRight className="mr-1 min-h-5 min-w-5" />
                    ) : null
                  }
                >
                  <span className="flex h-full items-center justify-start text-start">
                    <Typography
                      variant={'body'}
                      className="inline text-sm"
                    >
                      {group.name}{' '}
                      <span className="text-sm font-normal leading-5 text-[#747474]">
                        ({group.document_amount})
                      </span>
                    </Typography>
                  </span>
                </AccordionTrigger>
              </div>
              {hasChildren && (
                <AccordionContent className="border-l border-l-[#E3E5E8]">
                  <GroupFilterAccordion
                    groups={groups}
                    level={level + 1}
                    isFiltered={false}
                    parentId={group.id}
                    isSearching={isSearching}
                    selectedIndices={selectedIndices}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                </AccordionContent>
              )}
            </AccordionItem>
          </Accordion>
        )
      })}
    </>
  )
}
