import { useHomePageChatInput } from '@/entities/chat/models/useHomePageChatInput'
import IconArrowActionGray from '@/shared/assets/icons/icon_arrow_action_gray.svg?react'
import { IconButton } from '@/shared/ui/IconButton'
import { Input } from '@/shared/ui/Input'
import { FormEvent, forwardRef, KeyboardEvent } from 'react'

export const HomePageChatInput = forwardRef<HTMLInputElement, {}>(
  (props, ref) => {
    const {
      message,
      error,
      isError,
      isLoading,
      handleInputChange,
      handleSubmit,
    } = useHomePageChatInput()
    const handleKeyDown = (
      event: KeyboardEvent<HTMLTextAreaElement>
    ) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault()
        handleSubmit(event as unknown as FormEvent<HTMLFormElement>) // Submits the form
      }
    }

    return (
      <form
        onSubmit={handleSubmit}
        className="flex w-full max-w-[897px] shrink basis-12 items-center justify-center
          self-center"
      >
        {/* <div className="relative w-full"> */}
        <div className="relative flex w-full items-center bg-[#FCFCFC]">
          <Input
            textarea
            ref={ref}
            value={message}
            canRemove={false}
            onChange={handleInputChange}
            disabled={isLoading}
            className="w-full bg-base-100 pr-14"
            placeholder="Введите запрос"
            onKeyDown={handleKeyDown}
          />
          <IconButton
            type="submit"
            disabled={isLoading}
            className="absolute bottom-1 right-2 transform opacity-100"
          >
            <IconArrowActionGray width={44} height={44} />
          </IconButton>
        </div>
      </form>
    )
  }
)

HomePageChatInput.displayName = 'HomePageChatInput'
