import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { EPlatformPage, SegmentedItem } from '../types'

export const useSegmented = (
  initialSegmentedItems: SegmentedItem[]
) => {
  const [segmentedItems, setSegmentedItems] = useState<
    SegmentedItem[]
  >(initialSegmentedItems)
  const [value, setValue] = useState<EPlatformPage>(
    EPlatformPage.Assistant
  )
  const navigate = useNavigate()
  const location = useLocation()

  const setSegmentedItemDisabled = (
    itemValue: EPlatformPage,
    disabled: boolean
  ) => {
    setSegmentedItems((prevSegmentedItems) =>
      prevSegmentedItems.map((item) =>
        item.value === itemValue ? { ...item, disabled } : item
      )
    )
  }

  const handleSegmentedItemChange = (newValue: EPlatformPage) => {
    setValue(newValue)
    switch (newValue) {
      case EPlatformPage.Assistant:
        navigate('/')
        break
      case EPlatformPage.Documents:
        navigate('/documents')
        break
      case EPlatformPage.CourtBase:
        navigate('/court-base')
        break
      default:
        break
    }
  }

  useEffect(() => {
    // Update selected value based on the current path
    if (location.pathname.startsWith('/documents')) {
      setValue(EPlatformPage.Documents)
    } else if (location.pathname.startsWith('/court-base')) {
      setValue(EPlatformPage.CourtBase)
    } else {
      setValue(EPlatformPage.Assistant)
    }

    // Example: Disable 'court-base' if user is not authorized
    // const isAuthorizedForCourtBase = checkUserAuthorization();
    // setSegmentedItemDisabled(EPlatformPage.CourtBase, !isAuthorizedForCourtBase);
  }, [location.pathname])

  return {
    segmentedItems,
    setSegmentedItemDisabled,
    value,
    handleSegmentedItemChange,
  }
}
