import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { cn } from '@/shared/utils/common'
import { DocumentBaseBodyContainer } from '@/widgets/DocumentsBase/DocumentBaseBodyContainer'
import { WidgetDocumentsSearchInput } from '@/widgets/DocumentsBase/WidgetDocumentsSearchInput'
import { WidgetCategories } from '@/widgets/WidgetCategories'
import { WidgetDocumentFilters } from '@/widgets/WidgetDocumentFilters'
import { WidgetDocumentsMobile } from '@/widgets/WidgetDocumentsMobile'

export const DocumentsPage = () => {
  const isMobile = useMediaQuery('(max-width: 1279px)')

  if (isMobile) {
    return <WidgetDocumentsMobile isMobile={isMobile} />
  }

  return (
    <div
      className="relative flex h-[calc(100dvh-96px)] w-full overflow-visible
        bg-transparent"
    >
      <WidgetCategories />
      <div
        className={cn(
          `menu_boundary relative flex h-full flex-1 flex-col overflow-auto
          rounded-t-3xl bg-transparent px-4`
        )}
      >
        <div className="sticky top-0 z-50 -mx-2 min-h-4 min-w-full bg-[#FCFCFC]"></div>
        <div
          className="sticky top-0 z-50 -mt-4 rounded-t-3xl border-x-[1px] border-t-[1px]
            bg-white pt-1"
        >
          <WidgetDocumentsSearchInput />
          <WidgetDocumentFilters totalDocuments={856000} />
        </div>

        <DocumentBaseBodyContainer isMobile={isMobile} />
      </div>
    </div>
  )
}
