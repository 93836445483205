import LandingFrame1 from '@/shared/assets/icons/landing_frame_1.svg?react'
import LandingFrame2 from '@/shared/assets/icons/landing_frame_2.svg?react'
import LandingFrame3 from '@/shared/assets/icons/landing_frame_3.svg?react'
import LandingFrame4 from '@/shared/assets/icons/landing_frame_4.svg?react'
import { Typography } from '@/shared/ui'
import { motion } from 'framer-motion'

export const WidgetHomePageWelcomeContainer = () => {
  return (
    <div className="flex shrink-0 grow flex-col items-stretch justify-center">
      {/* Header */}
      <div className="flex flex-col items-stretch justify-start gap-2">
        <Typography className="text-base-960 text-center text-[32px] font-bold leading-[54px]">
          Добро пожаловать в{' '}
          <span
            className="text-fill-transparent bg-text-fade-blue-gradient bg-clip-text
              text-[32px] font-bold leading-[54px] text-transparent"
          >
            AI-ассистент!
          </span>
        </Typography>
        <Typography
          className="text-center font-medium text-base-800"
          variant={'title'}
        >
          Для начала, отправьте интересующий вас запрос
        </Typography>
      </div>
      {/* Icons */}
      <div className="mt-16 flex items-stretch justify-center gap-8">
        <div
          className="relative h-[200px] w-[200px] overflow-hidden rounded-[20px] border
            border-base-100 bg-base-50"
        >
          <Typography
            variant={'body'}
            className="absolute left-4 top-4 font-medium text-base-800"
          >
            Гражданское право
          </Typography>
          <motion.div
            key={'1'}
            initial={{ x: 30, y: 30 }}
            animate={{ x: 60, y: 40 }}
            whileHover={{ x: 70, y: 50 }}
            className="h-full w-full object-cover"
          >
            <LandingFrame1 />
          </motion.div>
        </div>
        <div
          className="relative h-[200px] w-[200px] overflow-hidden rounded-[20px] border
            border-base-100 bg-base-50"
        >
          <Typography
            variant={'body'}
            className="absolute left-4 top-4 font-medium text-base-800"
          >
            Уголовное право
          </Typography>
          <motion.div
            key={'1'}
            initial={{ x: 30, y: 30 }}
            animate={{ x: 60, y: 40 }}
            whileHover={{ x: 70, y: 50 }}
            className="h-full w-full object-cover"
            // src={`${config.DOMAIN_BASE}/temp/images/img_onboarding_2.png`}
          >
            <LandingFrame2 />
          </motion.div>
        </div>
        <div
          className="relative h-[200px] w-[200px] overflow-hidden rounded-[20px] border
            border-base-100 bg-base-50"
        >
          {' '}
          <Typography
            variant={'body'}
            className="absolute left-4 top-4 font-medium text-base-800"
          >
            Финансовое право
          </Typography>
          <motion.div
            key={'1'}
            initial={{ x: 30, y: 30 }}
            animate={{ x: 60, y: 40 }}
            whileHover={{ x: 70, y: 50 }}
            className="h-full w-full object-cover"
          >
            <LandingFrame3 />
          </motion.div>
        </div>
        <div
          className="relative h-[200px] w-[200px] overflow-hidden rounded-[20px] border
            border-base-100 bg-base-50"
        >
          {' '}
          <Typography
            variant={'body'}
            className="absolute left-4 top-4 font-medium text-base-800"
          >
            Трудовое право
          </Typography>
          <motion.div
            key={'1'}
            initial={{ x: 30, y: 30 }}
            animate={{ x: 60, y: 40 }}
            whileHover={{ x: 70, y: 50 }}
            className="h-full w-full object-cover"
          >
            <LandingFrame4 />
          </motion.div>
        </div>
      </div>
      <Typography
        variant={'title'}
        className="mt-8 text-center font-normal text-base-700"
      >
        Нажмите на карточку, чтобы посмотреть пример запроса
      </Typography>
    </div>
  )
}
