//src/entities/document/types/index.ts
export const enum EDocumentStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  ARTICLE = 'ARTICLE',
}

export const enum EParagraphType {
  HEADING = 'HEADING',
  COMMENT = 'COMMENT',
  TEXT = 'TEXT',
}

export interface IDocument {
  id: number
  name: string
  additional_info: string[]
  status: EDocumentStatus
}
export interface Locale {
  current: string
  available: string[]
}

export interface Paragraph {
  id: number
  type: 'HEADING' | 'COMMENT' | 'TEXT'
  content: string
}

export interface Version {
  id: number
  date: string
}

export interface DocumentData {
  id: number
  name: string
  locale: Locale
  paragraphs: Paragraph[]
  versions: Version[]
}

export interface Meta {
  total: number
  per_page: number
  current_page: number
  next_page: number | null
  last_page: number
}

export interface DocumentFilter {
  id: number
  name: string
  document_amount: number
  children: DocumentFilter[] | []
}
