import db from '@/db'
// import { lunr } from '@/shared/config/lunrConfig'
import { debounce } from '@/shared/utils/debounce'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Group } from '../types'

export const useGroupFilter = () => {
  const [groups, setGroups] = useState<Group[]>([])
  const [totalGroupsCount, setTotalGroupsCount] = useState<number>(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedIndices, setSelectedIndices] = useState<number[]>([])
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [isFiltered, setIsFiltered] = useState<boolean>(false)

  useEffect(() => {
    const fetchDataAndLoadIndex = async () => {
      try {
        // Load total count from Dexie
        const totalCount = await db.groups.count()
        setTotalGroupsCount(totalCount)

        // Load all groups from Dexie
        const allGroups = await db.groups.toArray()
        setGroups(allGroups)
      } catch (error) {
        console.error(error)
      }
    }

    fetchDataAndLoadIndex()
  }, [])

  // // Debounced search effect
  // useEffect(() => {
  //   const debouncedSearch = debounce(async () => {
  //     setIsSearching(true)

  //     if (lunrIndex && searchText.trim().length > 0) {
  //       try {
  //         const { lunr, index } = lunrIndex // Extract 'lunr' and 'index' from state

  //         // Tokenize and process the search query
  //         const tokens = lunr.tokenizer(searchText)
  //         const processedTokens = index.pipeline.run(tokens)
  //         const query = processedTokens.join(' ')

  //         // Perform the search
  //         const results = index.search(query)
  //         // console.log('RES', results)

  //         const matchedIds = results.map((result: any) =>
  //           parseInt(result.ref)
  //         )
  //         const matches = await db.groups
  //           .where('id')
  //           .anyOf(matchedIds)
  //           .toArray()
  //         setIsFiltered(true)
  //         setGroups(matches)
  //       } catch (error) {
  //         console.error(
  //           'Error during search or fetching from Dexie:',
  //           error
  //         )
  //       } finally {
  //         setIsSearching(false)
  //       }
  //     } else {
  //       // If no search text, load all groups
  //       try {
  //         const allGroups = await db.groups.toArray()
  //         setIsFiltered(false)
  //         setGroups(allGroups)
  //       } catch (error) {
  //         console.error(
  //           'Error fetching all groups from Dexie:',
  //           error
  //         )
  //       } finally {
  //         setIsSearching(false)
  //       }
  //     }
  //   }, 1500)

  //   if (lunrIndex) {
  //     debouncedSearch()
  //   }

  //   return () => {
  //     debouncedSearch.cancel?.()
  //   }
  // }, [searchText, lunrIndex])

  const debouncedApply = useCallback(
    debounce((newSelectedIndices: number[]) => {
      handleApply(newSelectedIndices)
    }, 3000),
    [searchParams, setSearchParams]
  )

  useEffect(() => {
    const group = searchParams.get('group')?.split(',') || []
    if (group.length) {
      setSelectedIndices(group.map(Number))
    }
  }, [searchParams])

  const handleCheckboxChange = useCallback(
    async (id: number) => {
      const isSelected = selectedIndices.includes(id)
      let newSelectedIndices: number[] = []

      if (isSelected) {
        // Deselect the node and its descendants
        const descendantIds = await getAllDescendantIds(id)
        newSelectedIndices = selectedIndices.filter(
          (index) => index !== id && !descendantIds.includes(index)
        )
      } else {
        // Select the node and its descendants
        const descendantIds = await getAllDescendantIds(id)
        console.log('descendants', await getAllDescendantIds(id))

        newSelectedIndices = Array.from(
          new Set([...selectedIndices, id, ...descendantIds])
        )
      }

      // Update the state with the new indices
      setSelectedIndices(newSelectedIndices)

      // Trigger the debounced apply with the new selected indices
      debouncedApply(newSelectedIndices)
    },
    [selectedIndices, debouncedApply]
  )

  const getAllDescendantIds = useCallback(
    async (parentId: number): Promise<number[]> => {
      const descendants: number[] = []
      const queue: number[] = [parentId]

      while (queue.length > 0) {
        const currentId = queue.shift()!
        const children = await db.groups
          .where('parentId')
          .equals(currentId)
          .toArray()
        for (const child of children) {
          descendants.push(child.id)
          queue.push(child.id)
        }
      }

      return descendants
    },
    []
  )

  const handleApply = useCallback(
    (newSelectedIndices: number[]) => {
      if (newSelectedIndices.length > 0) {
        searchParams.set('group', newSelectedIndices.join(','))
      } else {
        searchParams.delete('group')
      }
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams]
  )

  const handleReset = () => {
    setSelectedIndices([])
    searchParams.delete('group')
    setSearchParams(searchParams)
  }

  const handleSelectAll = async (checked: boolean) => {
    if (checked) {
      const allIds = await db.groups.toCollection().primaryKeys()
      setSelectedIndices(allIds)
    } else {
      setSelectedIndices([])
    }
  }

  const allSelected = useMemo(
    () => selectedIndices.length === totalGroupsCount,
    [selectedIndices, totalGroupsCount]
  )

  return {
    isFiltered,
    allSelected,
    isSearching,
    handleApply,
    setGroups,
    setIsFiltered,
    setTotalGroupsCount,
    handleReset,
    handleSelectAll,
    selectedIndices,
    handleCheckboxChange,
    groups,
  }
}
