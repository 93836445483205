import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import { Namespaces } from './types'

i18next
  .use(LanguageDetector)
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    load: 'languageOnly',
    backend: {
      backends: [HttpBackend],
      backendOptions: [
        {
          enabled: true,
          expirationTime: 5 * 60 * 1000,
        },
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
      ],
    },
    detection: {
      order: ['localStorage'],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage'],
    },
    preload: ['ru'],
    supportedLngs: ['ru', 'kk'],
    fallbackLng: 'ru',
    debug: true, //TODO: При проде, перенести на env
    ns: [Namespaces.Common],
    defaultNS: Namespaces.Common,
    react: {
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
  })

export default i18next
