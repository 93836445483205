import IconDocumentsOutlinedDark from '@/shared/assets/icons/icon_documents_outlined_dark.svg?react'
import { Checkbox } from '@/shared/ui/Checkbox'
import { Typography } from '@/shared/ui/Typography'

import { IDocumentStatus } from '../../types'

interface DocumentStatusItemProps {
  items: IDocumentStatus[]
  selectedIndices: number[]
  handleCheckboxChange: (id: number) => void
}

export const DocumentStatusFilterItems: React.FC<
  DocumentStatusItemProps
> = ({ items, selectedIndices, handleCheckboxChange }) => {
  return (
    <>
      {items.map((item) => (
        <label
          key={item.id}
          htmlFor={`checkbox-${item.id}`}
          className="flex cursor-pointer flex-nowrap items-center justify-between gap-2
            self-stretch border-b border-b-[#E3E5E8] px-0 py-5"
        >
          <span
            className="flex shrink grow-0 basis-full items-center justify-start gap-2
              overflow-hidden"
          >
            <IconDocumentsOutlinedDark />
            <Typography variant={'body'} className="inline min-w-0">
              {item.name}
            </Typography>
            <Typography variant={'subtitle'} className="inline">
              ({item.amount})
            </Typography>
          </span>
          <Checkbox
            id={`checkbox-${item.id}`}
            checked={selectedIndices.includes(item.id)}
            onCheckedChange={() => handleCheckboxChange(item.id)}
          />
        </label>
      ))}
    </>
  )
}
