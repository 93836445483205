// src/store/slices/signinJudicialSlice.ts
import { ISigninJudicialDTO } from '@/entities/signin/types'
import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'

interface SigninJudicialState {
  data: ISigninJudicialDTO
  loading: boolean
  error: string | null
}

const initialState: SigninJudicialState = {
  data: {
    email: '',
    password: '',
  },
  loading: false,
  error: null,
}

const signinJudicialSlice = createSlice({
  name: 'signinJudicial',
  initialState,
  reducers: {
    resetJudicialSigninState: (state) => {
      state.data = initialState.data
    },
    updateSigninData: (
      state,
      action: PayloadAction<Partial<ISigninJudicialDTO>>
    ) => {
      state.data = { ...state.data, ...action.payload }
    },
  },
})

export const { resetJudicialSigninState, updateSigninData } =
  signinJudicialSlice.actions
export default signinJudicialSlice.reducer
