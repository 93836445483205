import { HomePageChatInput } from '@/widgets/HomePageChat/HomePageChatInput'
import { WidgetHomePageWelcomeContainer } from '@/widgets/WidgetHomePageWelcomeContainer'
import { useEffect, useRef } from 'react'

export const HomePage = () => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  return (
    <div
      className={`relative flex h-full w-full shrink-0 grow flex-col items-stretch
        justify-center`}
    >
      <WidgetHomePageWelcomeContainer />
      <HomePageChatInput ref={inputRef} />
    </div>
  )
}
