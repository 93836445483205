//@/features/filters/typeFilter/hooks/usetypeFilter.ts
import db from '@/db'
import { lunr } from '@/shared/config/lunrConfig'
import { debounce } from '@/shared/utils/debounce'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Types } from '../types'

export const useTypeFilter = () => {
  const [selectedIndices, setSelectedIndices] = useState<number[]>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [totalTypesCount, setTotalTypesCount] = useState<number>(0)
  const [types, setTypes] = useState<Types[]>([])
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [isFiltered, setIsFiltered] = useState<boolean>(false)

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const totalCount = await db.types.count()
        setTotalTypesCount(totalCount)

        const allTypes = await db.types.toArray()
        setTypes(allTypes)
      } catch (error) {
        console.error('Error loading data or Lunr index:', error)
      }
    }

    fetchTypes()
  }, [])

  const debouncedApply = useCallback(
    debounce((newSelectedIndices: number[]) => {
      handleApply(newSelectedIndices)
    }, 3000),
    [searchParams, setSearchParams]
  )

  useEffect(() => {
    const types = searchParams.get('type')?.split(',')
    if (types?.length) {
      setSelectedIndices(types.map(Number))
    }
  }, [searchParams])

  // Handle checkbox changes for selecting types (useCallback to memoize)
  const handleCheckboxChange = useCallback(
    async (id: number) => {
      const isSelected = selectedIndices.includes(id)
      let newSelectedIndices: number[] = []

      if (isSelected) {
        // Deselect the node and its descendants
        const descendantIds = await getAllDescendantIds(id)
        newSelectedIndices = selectedIndices.filter(
          (index) => index !== id && !descendantIds.includes(index)
        )
      } else {
        // Select the node and its descendants
        const descendantIds = await getAllDescendantIds(id)
        newSelectedIndices = Array.from(
          new Set([...selectedIndices, id, ...descendantIds])
        )
      }

      // Update the state with the new indices
      setSelectedIndices(newSelectedIndices)

      // Trigger the debounced apply with the new selected indices
      debouncedApply(newSelectedIndices)
    },
    [selectedIndices, debouncedApply]
  )
  const getAllDescendantIds = useCallback(
    async (parentId: number): Promise<number[]> => {
      const descendants: number[] = []
      const queue: number[] = [parentId]

      while (queue.length > 0) {
        const currentId = queue.shift()!
        const children = await db.types
          .where('parentId')
          .equals(currentId)
          .toArray()
        for (const child of children) {
          descendants.push(child.id)
          queue.push(child.id)
        }
      }

      return descendants
    },
    []
  )

  const handleApply = useCallback(
    (newSelectedIndices: number[]) => {
      if (newSelectedIndices.length > 0) {
        searchParams.set('type', newSelectedIndices.join(','))
      } else {
        searchParams.delete('type')
      }
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams]
  )

  const handleReset = useCallback(() => {
    setSelectedIndices([])
    searchParams.delete('type')
    setSearchParams(searchParams)
  }, [setSearchParams, searchParams])

  const handleSelectAll = useCallback(async (checked: boolean) => {
    if (checked) {
      const allIds = await db.types.toCollection().primaryKeys()
      setSelectedIndices(allIds)
    } else {
      setSelectedIndices([])
    }
  }, [])

  const allSelected = useMemo(
    () => selectedIndices.length === totalTypesCount,
    [selectedIndices, totalTypesCount]
  )

  return {
    types,
    selectedIndices,
    setIsFiltered,
    handleCheckboxChange,
    handleApply,
    handleReset,
    handleSelectAll,
    allSelected,
    setTypes,
    setTotalTypesCount,
    isFiltered,
    isSearching,
  }
}
