import IconShevronDown from '@/shared/assets/icons/icon_chevron_down.svg?react'
import { cn } from '@/shared/utils/common'
import * as SelectPrimitive from '@radix-ui/react-select'
import * as React from 'react'

import { Typography } from '../Typography'

const Select = SelectPrimitive.Root
const SelectGroup = SelectPrimitive.Group
const SelectValue = SelectPrimitive.Value

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={cn(
      `border-input ring-offset-background placeholder:text-muted-foreground
      flex h-10 min-w-0 max-w-full items-center justify-between
      whitespace-nowrap rounded-md border bg-transparent py-4 pl-3 pr-4
      text-start text-sm shadow-sm focus:outline-none focus:ring-1
      focus:ring-base-900 disabled:cursor-not-allowed disabled:opacity-50
      xl:h-14 [&>span]:line-clamp-1 [&_span]:block [&_span]:w-[117px]
      [&_span]:truncate`,
      className
    )}
    {...props}
  >
    {children}
    <SelectPrimitive.Icon asChild>
      <IconShevronDown className="h-4 w-4 opacity-50" />
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
))
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = 'popper', ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        `text-popover-foreground data-[state=open]:animate-in
        data-[state=closed]:animate-out data-[state=closed]:fade-out-0
        data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95
        data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2
        data-[side=left]:slide-in-from-right-2
        data-[side=right]:slide-in-from-left-2
        data-[side=top]:slide-in-from-bottom-2 absolute z-50 max-h-96
        w-[326px] rounded-md border bg-white shadow-md`,
        position === 'popper' &&
          `data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1
          data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1`,
        className
      )}
      position={position}
      {...props}
    >
      <SelectPrimitive.Viewport
        className={cn(
          'p-1',
          position === 'popper' &&
            `h-[var(--radix-select-trigger-height)] w-full
            min-w-[var(--radix-select-trigger-width)]`
        )}
      >
        {children}
      </SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
))
SelectContent.displayName = SelectPrimitive.Content.displayName

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={cn('px-2 py-1.5 text-sm font-semibold', className)}
    {...props}
  />
))
SelectLabel.displayName = SelectPrimitive.Label.displayName

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      `focus:text-accent-foreground relative flex cursor-default select-none
      items-center rounded-sm py-1.5 pl-2 pr-8 text-sm outline-none
      focus:bg-slate-200 data-[disabled]:pointer-events-none
      data-[disabled]:opacity-50`,
      className
    )}
    {...props}
  >
    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
))
SelectItem.displayName = SelectPrimitive.Item.displayName

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={cn('bg-muted -mx-1 my-1 h-px', className)}
    {...props}
  />
))
SelectSeparator.displayName = SelectPrimitive.Separator.displayName

interface LabeledSelectProps {
  label?: string
  options: { value: string; label: string }[]
  value: string
  onChange: (value: string) => void
  onBlur?: () => void
}

const LabeledSelect = React.forwardRef<
  HTMLDivElement,
  LabeledSelectProps
>(({ label, options, value, onChange, onBlur }, ref) => {
  return (
    <div
      ref={ref}
      className="flex w-full shrink grow-0 basis-full flex-col justify-between gap-2
        self-stretch"
    >
      {label && (
        <Typography
          variant={'subtitle'}
          className="whitespace-nowrap"
        >
          {label}
        </Typography>
      )}
      <Select value={value} onValueChange={onChange}>
        <SelectTrigger onBlur={onBlur}>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          {options.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
})

LabeledSelect.displayName = 'LabeledSelect'

export {
  Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectLabel,
  SelectItem,
  SelectSeparator,
  LabeledSelect,
}
