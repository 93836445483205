import { cn } from '@/shared/utils/common'
import {
  forwardRef,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'

import { Typography } from '../Typography'

interface SegmentedItem {
  value: string
  icon?: ReactNode
  label: ReactNode
  disabled?: boolean
}

interface SegmentedProps extends HTMLAttributes<HTMLDivElement> {
  items: SegmentedItem[]
  value?: string
  filter: string
  handleChange?: (value: string) => void
}

export const Segmented = forwardRef<HTMLDivElement, SegmentedProps>(
  (
    {
      items,
      value: controlledValue,
      handleChange,
      className,
      filter,
      ...props
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = useState<string>(
      controlledValue ?? items[0]?.value ?? ''
    )
    const [searchParams, setSearchParams] = useSearchParams()

    const value = controlledValue ?? internalValue

    useEffect(() => {
      const paramValue = searchParams.get(filter)
      if (paramValue) {
        setInternalValue(paramValue)
      }
    }, [searchParams, filter])

    const handleClick = (itemValue: string) => {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        [filter]: itemValue,
      })
      if (controlledValue === undefined) {
        setInternalValue(itemValue)
      }
      handleChange?.(itemValue)
    }

    return (
      <div
        className={cn(
          'inline-flex gap-4 self-center rounded-full bg-[#F0F0F0] p-1',
          className
        )}
        ref={ref}
        {...props}
      >
        {items.map((item) => (
          <button
            key={item.value}
            type="button"
            disabled={item.disabled || item.value === value}
            onClick={() => handleClick(item.value)}
            className={cn(
              `flex items-center justify-start gap-2 whitespace-nowrap rounded-full
              px-3 py-[6px] transition-colors duration-200`,
              {
                'bg-white text-base-900 shadow-shadow3':
                  item.value === value,
                'bg-transparent text-gray-600 shadow-none':
                  item.value !== value,
              }
            )}
          >
            {item.icon && item.icon}
            <Typography className="leading-custom-[114px] text-sm font-semibold not-italic leading-4">
              {item.label}
            </Typography>
          </button>
        ))}
      </div>
    )
  }
)

Segmented.displayName = 'Segmented'
