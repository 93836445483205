import { useChatMessages } from '@/entities/chat/models/useChatMessages'
import IconExit from '@/shared/assets/icons/icon_exit.svg?react'
import { useAppDispatch } from '@/shared/store'
import { closeChatPanel } from '@/shared/store/slices/chat/documentPreviewSlice'
import { Typography } from '@/shared/ui/Typography'
import { Panel } from 'react-resizable-panels'
import { useParams } from 'react-router-dom'

import { ChatInput } from '../ChatInput'
import { ChatMessages } from '../ChatMessages'

export const ChatPanel = () => {
  const dispatch = useAppDispatch()
  const { chat_id } = useParams<{ chat_id: string }>()
  const { data, error } = useChatMessages(chat_id!)

  return (
    <Panel
      className="relative flex shrink-0 flex-col items-center rounded-[40px] bg-white
        px-2 pb-2 shadow-shadow2 lg:max-w-[868px] xl:max-w-[1176px]"
      defaultSize={40}
      minSize={40}
    >
      <div className="flex min-h-[60px] items-center justify-between self-stretch px-5">
        <div
          onClick={() => dispatch(closeChatPanel())}
          className="flex items-center justify-between gap-3 p-3"
        >
          <IconExit />
          <Typography>Скрыть чат</Typography>
        </div>
      </div>
      <div
        className="menu_boundary flex grow flex-col items-center gap-4 self-stretch
          overflow-y-auto px-5 pb-6 pt-8"
      >
        <ChatMessages messages={data?.messages} />
      </div>
      <ChatInput isPanel={true} />
    </Panel>
  )
}
