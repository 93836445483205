import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie'

import { config } from '..'

interface AuthResponse {
  auth: boolean
  channel_data: string
}

interface AuthRequest {
  socket_id: string
  channel_name: string
}

export const broadcastAuthApi = createApi({
  reducerPath: 'broadcastAuthApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = Cookies.get('access_token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    authorizeChannel: builder.mutation<AuthResponse, AuthRequest>({
      query: ({ socket_id, channel_name }) => ({
        url: '/broadcasting/auth',
        method: 'POST',
        body: { socket_id, channel_name },
      }),
    }),
  }),
})

export const { useAuthorizeChannelMutation } = broadcastAuthApi
