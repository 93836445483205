// @/db.ts
import Dexie, { type Table } from 'dexie'

interface Filter {
  id: number
  name: string
  parentId: number
  document_amount: number
  children_count: number
  tokens: string[]
}

class CodexDatabase extends Dexie {
  organizations!: Table<Filter, number>
  types!: Table<Filter, number>
  groups!: Table<Filter, number>

  constructor() {
    super('codexDatabase')
    this.version(1).stores({
      organizations:
        'id, parentId, name, document_amount, children_count, *tokens',
      types:
        'id, parentId, name, document_amount, children_count, *tokens',
      groups:
        'id, parentId, name, document_amount, children_count, *tokens',
    })
  }
}
const db = new CodexDatabase()

export default db
