import {
  useGetDocumentByIdQuery,
  useGetDocumentsQuery,
} from '@/shared/api/documents'
import IconFileOutlinedDark from '@/shared/assets/icons/icon_file_outlined_dark.svg?react'
import IconMoreDotsDark from '@/shared/assets/icons/icon_more_dots_dark.svg?react'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { IconButton } from '@/shared/ui/IconButton'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { DocumentInfoDialog } from '@/widgets/DocumentPreview/DocumentInfoDialog'
import { DocumentsBaseDocumentMenu } from '@/widgets/DocumentsBase/DocumentsBaseDocumentMenu'
import { forwardRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { IDocument } from '../../types'
import { DocumentItemStatus } from '../DocumentItemStatus'

interface DocumentItemProps {
  documentItem: IDocument
  isIndexed?: boolean
  isLastDocumentItem: boolean
}

export const DocumentItem = forwardRef<
  HTMLDivElement,
  DocumentItemProps
>(({ documentItem, isLastDocumentItem, isIndexed = 'true' }, ref) => {
  const navigate = useNavigate()
  const [isInfoDialogOpen, setIsInfoDialogOpen] =
    useState<boolean>(false)
  const handleInfoClick = () => {
    setIsInfoDialogOpen((prev) => !prev)
  }
  const handleInfoClose = () => {
    setIsInfoDialogOpen(false)
  }
  const isMobile = useMediaQuery('(max-width: 768px)')
  if (isMobile)
    return (
      <>
        <DocumentInfoDialog
          open={isInfoDialogOpen}
          key={documentItem.id}
          onClose={handleInfoClose}
        />
        {/* Body Document item */}
        <div
          onClick={() => navigate(`/documents/${documentItem.id}`)}
          ref={ref}
          className="flex shrink grow-0 basis-[230px] flex-col items-stretch justify-start
            overflow-hidden rounded-[16px] border-[1px] border-base-200 bg-white
            shadow-shadow3 sm:basis-[208px] lg:basis-[120px] xl:basis-[96px]"
        >
          {/* Header */}
          <div className="flex items-center justify-between self-stretch border-b px-6 py-2">
            <div className="flex items-center gap-4">
              <Typography variant={'body'} className="font-medium">
                {documentItem.id}.
              </Typography>
              <IconFileOutlinedDark />
            </div>
            <DocumentsBaseDocumentMenu onInfoClick={handleInfoClick}>
              <IconButton>
                <IconMoreDotsDark />
              </IconButton>
            </DocumentsBaseDocumentMenu>
          </div>
          {/* Body */}
          <div
            className="flex grow flex-col items-stretch justify-center gap-3 self-stretch
              rounded-2xl bg-white p-3 px-6"
          >
            <Typography className="line-clamp-[7] text-sm font-medium leading-5">
              {documentItem.name}
            </Typography>
          </div>
        </div>
      </>
    )

  return (
    <>
      <DocumentInfoDialog
        open={isInfoDialogOpen}
        key={documentItem.id}
        onClose={handleInfoClose}
      />
      {/* Body Document item */}
      <div
        onClick={() => navigate(`/documents/${documentItem.id}`)}
        className={cn(
          // py-3 pl-4 pr-14
          isLastDocumentItem ? '' : 'border-b-[1px]',
          'relative bg-white p-4'
        )}
        style={{
          boxShadow:
            '0px 6px 2px 0px rgba(0, 0, 0, 0.00), 0px 4px 1px 0px rgba(0, 0, 0, 0.00), 0px 2px 1px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.03)',
        }}
      >
        <div
          ref={ref}
          className="relative flex items-stretch justify-start gap-4"
        >
          {isIndexed && (
            <Typography variant={'body'} className="font-medium">
              {documentItem.id}.
            </Typography>
          )}
          {/* Header */}

          {/* Body */}
          <div className="flex flex-col items-stretch justify-start gap-2 pr-8">
            <Typography variant={'body'} className="font-medium">
              {documentItem.name}
            </Typography>
            <div className="flex flex-col items-stretch justify-start">
              {documentItem.additional_info.length > 0 &&
                documentItem.additional_info.map((item, index) => {
                  if (
                    index ===
                    documentItem.additional_info.length - 1
                  )
                    return (
                      <div
                        key={`additional${index}`}
                        className="flex items-center justify-start gap-3"
                      >
                        <Typography
                          variant={'label'}
                          className="font-medium text-[#5E626A]"
                        >
                          {item}
                        </Typography>
                        <DocumentItemStatus
                          status={documentItem.status}
                        />
                      </div>
                    )
                  return (
                    <Typography
                      key={`additional${index}`}
                      variant={'label'}
                      className="font-medium text-[#5E626A]"
                    >
                      {item}
                    </Typography>
                  )
                })}
              {documentItem.additional_info.length === 0 && (
                <div className="flex items-center justify-start">
                  <DocumentItemStatus status={documentItem.status} />
                </div>
              )}
            </div>
          </div>
        </div>
        <DocumentsBaseDocumentMenu
          key={documentItem.id}
          onInfoClick={handleInfoClick}
        >
          <IconMoreDotsDark />
        </DocumentsBaseDocumentMenu>
      </div>
    </>
  )
})

DocumentItem.displayName = 'DocumentItem'
