import IconChevronDown from '@/shared/assets/icons/icon_chevron_down.svg?react'
import IconPlanetDark from '@/shared/assets/icons/icon_planet_dark.svg?react'
import { Typography } from '@/shared/ui/Typography'
import { forwardRef, ForwardRefRenderFunction } from 'react'

interface CustomInputProps {
  value?: string
  onClick?: () => void
  className?: string
}

const CustomInput: ForwardRefRenderFunction<
  HTMLButtonElement,
  CustomInputProps
> = ({ value, onClick, className, ...props }, ref) => (
  <div className="flex shrink grow-0 basis-[150px] items-center justify-start gap-2">
    <button
      ref={ref}
      {...props}
      onClick={onClick}
      className={`${className} flex h-8 w-full items-center justify-between gap-2
        rounded-lg border border-[#E3E5E8] bg-white px-4 py-[6px]`}
    >
      <Typography className="font-Inter whitespace-nowrap text-sm font-medium leading-[20px]">
        {value ?? 'Статус'}
      </Typography>
      <IconPlanetDark />
    </button>
  </div>
)

export const DocumentStatusFilterCustomInput = forwardRef(CustomInput)
