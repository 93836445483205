//@/features/filters/OrganizationFilter/hooks/useOrganizationFilter.ts
import db from '@/db'
import { debounce } from '@/shared/utils/debounce'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Organization } from '../types'

export const useOrganizationFilter = () => {
  const [selectedIndices, setSelectedIndices] = useState<number[]>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [totalOrganizationsCount, setTotalOrganizationsCount] =
    useState<number>(0)
  const [organizations, setOrganizations] = useState<Organization[]>(
    []
  )
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [isFiltered, setIsFiltered] = useState<boolean>(false)

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const totalCount = await db.organizations.count()
        setTotalOrganizationsCount(totalCount)

        const allOrganizations = await db.organizations.toArray()
        console.log('ALL', allOrganizations)

        setOrganizations(allOrganizations)
      } catch (error) {
        console.error('Error loading data', error)
      }
    }

    fetchOrganizations()
  }, [])

  const debouncedApply = useCallback(
    debounce((newSelectedIndices: number[]) => {
      handleApply(newSelectedIndices)
    }, 3000),
    [searchParams, setSearchParams]
  )

  useEffect(() => {
    const organizations =
      searchParams.get('organization')?.split(',') || []
    if (organizations.length) {
      setSelectedIndices(organizations.map(Number))
    }
  }, [searchParams])

  // Handle checkbox changes for selecting organizations (useCallback to memoize)
  const handleCheckboxChange = useCallback(
    async (id: number) => {
      const isSelected = selectedIndices.includes(id)
      let newSelectedIndices: number[] = []

      if (isSelected) {
        // Deselect the node and its descendants
        const descendantIds = await getAllDescendantIds(id)
        newSelectedIndices = selectedIndices.filter(
          (index) => index !== id && !descendantIds.includes(index)
        )
      } else {
        // Select the node and its descendants
        const descendantIds = await getAllDescendantIds(id)
        console.log('TEST', descendantIds)

        newSelectedIndices = Array.from(
          new Set([...selectedIndices, id, ...descendantIds])
        )
      }

      // Update the state with the new indices
      setSelectedIndices(newSelectedIndices)

      // Trigger the debounced apply with the new selected indices
      debouncedApply(newSelectedIndices)
    },
    [selectedIndices, debouncedApply]
  )
  const getAllDescendantIds = useCallback(
    async (parentId: number): Promise<number[]> => {
      const descendants: number[] = []
      const queue: number[] = [parentId]

      while (queue.length > 0) {
        const currentId = queue.shift()!
        const children = await db.organizations
          .where('parentId')
          .equals(currentId)
          .toArray()
        for (const child of children) {
          descendants.push(child.id)
          queue.push(child.id)
        }
      }

      return descendants
    },
    []
  )

  const handleApply = useCallback(
    (newSelectedIndices: number[]) => {
      if (newSelectedIndices.length > 0) {
        searchParams.set('organization', newSelectedIndices.join(','))
      } else {
        searchParams.delete('organization')
      }
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams]
  )

  const handleReset = useCallback(() => {
    setSelectedIndices([])
    searchParams.delete('organization')
    setSearchParams(searchParams)
  }, [setSearchParams, searchParams])

  const handleSelectAll = useCallback(async (checked: boolean) => {
    if (checked) {
      const allIds = await db.organizations
        .toCollection()
        .primaryKeys()
      setSelectedIndices(allIds)
    } else {
      setSelectedIndices([])
    }
  }, [])

  const allSelected = useMemo(
    () => selectedIndices.length === totalOrganizationsCount,
    [selectedIndices, totalOrganizationsCount]
  )

  return {
    organizations,
    handleApply,
    handleReset,
    allSelected,
    isFiltered,
    isSearching,
    setIsFiltered,
    selectedIndices,
    handleSelectAll,
    setOrganizations,
    handleCheckboxChange,
    setTotalOrganizationsCount,
  }
}
