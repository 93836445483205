import {
  ActFormFilterDrawer,
  DocumentStatusFilterDrawer,
  GroupFilterDrawer,
  MobileRatificationDateDrawer,
  OrganizationFilterDrawer,
  SearchModeFilterDrawer,
  SortModeFilterDrawer,
  TypeFilterDrawer,
} from '@/features/filters'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { DocumentBaseBodyContainer } from '@/widgets/DocumentsBase/DocumentBaseBodyContainer'
import { MobileCustomSearchInput } from '@/widgets/DocumentsBase/MobileCustomSearchInput'

interface WidgetDocumentsMobileProps {
  isMobile: boolean
}

export const WidgetDocumentsMobile: React.FC<
  WidgetDocumentsMobileProps
> = ({ isMobile }) => {
  return (
    <div
      className={cn(
        'flex h-full flex-col overflow-y-auto px-5 pb-[68px] pt-8'
        // 'menu_boundary'
      )}
    >
      <div className="mb-[10px] flex justify-center self-stretch">
        <MobileCustomSearchInput />
      </div>

      <div className="flex items-center justify-between border-y py-3">
        <div className="flex flex-col items-stretch justify-start gap-1">
          <Typography variant={'label'} className="font-semibold">
            Все документы
          </Typography>
          <Typography variant={'subtitle'}>
            842 000 документов
          </Typography>
        </div>
        <div className="flex gap-8">
          <SortModeFilterDrawer />
          <SearchModeFilterDrawer />
        </div>
      </div>

      {/* Filters */}
      <div className="-mx-5 shrink-0 grow self-stretch overflow-visible">
        <div
          className="flex flex-nowrap items-center justify-start gap-3 overflow-x-scroll
            py-3 pl-5 pr-3"
        >
          <TypeFilterDrawer />
          <GroupFilterDrawer />
          <OrganizationFilterDrawer />
          <MobileRatificationDateDrawer />
          <DocumentStatusFilterDrawer />
          <ActFormFilterDrawer />
        </div>
      </div>

      {/* Divider */}
      <div className="-mx-5 my-2 shrink-0 grow basis-2 bg-[#F0F0F0]" />

      {/* Body container */}
      <DocumentBaseBodyContainer isMobile={isMobile} />
    </div>
  )
}
