import IconDownloadOutlinedGray from '@/shared/assets/icons/icon_download_outlined_gray.svg?react'
import IconInfoOutlinedGray from '@/shared/assets/icons/icon_info_outlined_gray.svg?react'
import IconLanguageFilledGray from '@/shared/assets/icons/icon_language_filled_gray.svg?react'
import IconStarGray from '@/shared/assets/icons/icon_star_gray.svg?react'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from '@/shared/ui/Menubar'
import { Typography } from '@/shared/ui/Typography'
import { ReactNode } from 'react'

export const ChatAiFileDrawerMenu = ({
  children,
  onInfoClick,
}: {
  children: ReactNode
  onInfoClick: () => void
}) => {
  return (
    <>
      <Menubar className="flex items-center justify-stretch self-stretch border-none">
        <MenubarMenu>
          <MenubarTrigger
            className="flex w-full items-center justify-start rounded-lg bg-white
              hover:cursor-pointer hover:bg-slate-100"
          >
            {children}
          </MenubarTrigger>
          <MenubarContent
            className="z-[100] flex flex-col items-stretch gap-3 rounded-[20px] bg-white p-2
              shadow-shadow2"
            align="start"
            alignOffset={0}
            sideOffset={0}
          >
            <MenubarItem
              inset={true}
              className="p-0"
              onClick={onInfoClick}
            >
              <div
                className="flex w-full items-center gap-3 rounded-lg p-2 hover:cursor-pointer
                  hover:bg-slate-100"
              >
                <IconInfoOutlinedGray className="h-5 w-5" />
                <Typography className="text-nowrap font-normal">
                  О документе
                </Typography>
              </div>
            </MenubarItem>
            <MenubarItem inset={true} asChild className="p-0">
              <div
                className="flex items-center gap-3 self-stretch rounded-lg p-2
                  hover:cursor-pointer hover:bg-slate-100"
              >
                <IconStarGray className="h-5 w-5" />
                <Typography className="text-nowrap font-normal">
                  В избранное
                </Typography>
              </div>
            </MenubarItem>

            <MenubarItem inset={true} asChild className="p-0">
              <div
                className="flex items-center gap-3 self-stretch rounded-lg p-2
                  hover:cursor-pointer hover:bg-slate-100"
              >
                <IconDownloadOutlinedGray className="h-5 w-5" />
                <Typography className="text-nowrap font-normal">
                  Скачать
                </Typography>
              </div>
            </MenubarItem>

            <MenubarItem inset={true} asChild className="p-0">
              <div
                className="flex items-center gap-3 self-stretch rounded-lg p-2
                  hover:cursor-pointer hover:bg-slate-100"
              >
                <IconLanguageFilledGray className="h-5 w-5" />
                <Typography className="text-nowrap font-normal">
                  Сменить язык
                </Typography>
              </div>
            </MenubarItem>
          </MenubarContent>
        </MenubarMenu>
      </Menubar>
    </>
  )
}
