import {
  DocumentData,
  EDocumentStatus,
  IDocument,
  Meta,
} from '@/entities/document'
import {
  mockDocumentByIdData,
  mockDocumentsData,
} from '@/entities/document/consts'
import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie'

import { config } from '..'

interface IDocumentsResponse {
  success: boolean
  meta: {
    total: number
    per_page: number
    current_page: number
    next_page: number | null
    last_page: number
  }
  documents: IDocument[]
}

interface IDocumentByIdResponse {
  success: boolean
  document: DocumentData
  meta: Meta
}

interface IDocumentQueryParams {
  search_mode?: 'CONTEXT'
  search_query?: string
  group?: string // Comma-separated values (e.g., '1,2,3')
  type?: string // Comma-separated values (e.g., '1,2,3')
  organization?: string // Comma-separated values (e.g., '1,2,3')
  status?: 'ACTIVE' | 'PENDING'
  created_at?: string // Date in 'YYYY-MM-DD' format (e.g., '2024-01-15')
  created_at_from?: string // Date in 'YYYY-MM-DD' format (e.g., '2024-01-01')
  created_at_until?: string // Date in 'YYYY-MM-DD' format (e.g., '2024-01-30')
  page?: number // Optional page number for pagination
}

export const documentsApi = createApi({
  reducerPath: 'documentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE,
    prepareHeaders: (headers) => {
      const token = Cookies.get('access_token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Documents'],
  endpoints: (builder) => ({
    getDocuments: builder.query<
      IDocumentsResponse,
      IDocumentQueryParams
    >({
      query: (params) => {
        const typedParams: IDocumentQueryParams = {
          page: Number(params.page) || 1,
          // search_mode: params.search_mode ?? 'CONTEXT',
          ...params,
        }

        return {
          url: '/documents',
          params: typedParams,
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.documents.map(({ id }) => ({
                type: 'Documents' as const,
                id,
              })),
              { type: 'Documents', id: 'LIST' },
            ]
          : [{ type: 'Documents', id: 'LIST' }],
    }),
    getDocumentById: builder.query<
      IDocumentByIdResponse,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/documents/${id}`,
      }),
      providesTags: (result, error, arg) => [
        { type: 'Documents', id: arg.id },
      ],
    }),
  }),
})

export const { useGetDocumentsQuery, useGetDocumentByIdQuery } =
  documentsApi

// const getDocuments = async (): Promise<IDocumentsResponse> => {
//   try {
//     return await new Promise<IDocumentsResponse>((resolve) => {
//       setTimeout(() => {
//         resolve({
//           success: true,
//           meta: {
//             total: 25,
//             per_page: 25,
//             current_page: 1,
//             next_page: null,
//             last_page: 1,
//           },
//           documents: [
//             {
//               id: 41,
//               name: 'Решение Коллегии Евразийской экономической комиссии от 1 марта 2021 года № 23',
//               additional_info: ['(г. Москва)'],
//               status: EDocumentStatus.ACTIVE,
//             },
//             {
//               id: 215,
//               name: 'Таможенный кодекс Евразийского экономического союза',
//               additional_info: [
//                 '(приложение № 1 к Договору о Таможенном кодексе Евразийского экономического союза от 11 апреля 2017 года)',
//                 '(с изменениями от 29.05.2019 г.)',
//               ],
//               status: EDocumentStatus.ACTIVE,
//             },
//             ...Array.from({ length: 23 }).map((_, index) => ({
//               id: 300 + index,
//               name: `Документ ${index + 1}`,
//               additional_info: [`Информация ${index + 1}`],
//               status:
//                 Math.random() > 0.5
//                   ? EDocumentStatus.ACTIVE
//                   : EDocumentStatus.PENDING,
//             })),
//           ],
//         })
//       }, 2000)
//     })
//   } catch (err: any) {
//     console.error('Error fetching documents:', err)
//     throw new Error('Error fetching documents')
//   }
// }

// export const documentsApi = {
//   getDocuments,
// }
