import { useGetChatByIdQuery } from '@/shared/api/chat'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { IChat } from '../types'

interface UseChatMessagesReturnType {
  isLoading: boolean
  error: string | null
  data?: IChat | null
}

export const useChatMessages = (
  chatId: string
): UseChatMessagesReturnType => {
  const [searchParams] = useSearchParams()

  const { data, error, isLoading } = useGetChatByIdQuery(chatId)

  return {
    data: data
      ? {
          chat: data.chat,
          messages: data.messages,
        }
      : null,
    isLoading,
    error: error ? 'Failed to fetch chat messages' : null,
  }
}
