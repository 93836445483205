import { ISignupJudicialDTO } from '@/entities/signup/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ICompanyType {
  code: string
  name: string
}
interface SignupState {
  step: number
  data: ISignupJudicialDTO
  companyTypes: ICompanyType[]
  codeSent: boolean
  canResend: boolean
}

const initialState: SignupState = {
  step: 1,
  data: {
    name: '',
    phone: '',
    email: '',
    company_name: '',
    company_bin: '',
    company_type: '',
    licences_count: 1,
    password: '',
  },
  companyTypes: [],
  codeSent: false,
  canResend: false,
}

const signupJudicialSlice = createSlice({
  name: 'signupJudicial',
  initialState,
  reducers: {
    resetSignupJudicialSteps: (state) => {
      state.step = 1
      state.codeSent = false
      state.canResend = false
    },
    updateSignupData: (
      state,
      action: PayloadAction<Partial<ISignupJudicialDTO>>
    ) => {
      state.data = { ...state.data, ...action.payload }
    },
    setCodeSent: (state, action: PayloadAction<boolean>) => {
      state.codeSent = action.payload
    },
    setCanResend: (state, action: PayloadAction<boolean>) => {
      state.canResend = action.payload
    },
    setCompanyTypes: (
      state,
      action: PayloadAction<ICompanyType[]>
    ) => {
      state.companyTypes = action.payload
    },
    incrementStep: (state) => {
      state.step += 1
    },
  },
})

export const {
  resetSignupJudicialSteps,
  updateSignupData,
  setCodeSent,
  setCanResend,
  setCompanyTypes,
  incrementStep,
} = signupJudicialSlice.actions

export default signupJudicialSlice.reducer
