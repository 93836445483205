import IconCheckFilledGreen from '@/shared/assets/icons/icon_check_filled_green.svg?react'
import { Button } from '@/shared/ui/Button'
import { CardContent, CardFooter } from '@/shared/ui/Card'
import { Input } from '@/shared/ui/Input'
import { Typography } from '@/shared/ui/Typography'
import { FormProvider } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { useResetPassword } from '../../models/useResetPassword'

export const ResetPassword = () => {
  const {
    linkSent,
    onSubmit,
    register,
    resetField,
    methods,
    errors,
  } = useResetPassword()

  return (
    <FormProvider {...methods}>
      {linkSent ? (
        <>
          <CardContent className="flex flex-col items-center justify-start gap-4">
            <IconCheckFilledGreen />
          </CardContent>
          <Typography className="text-center">
            Ссылка для восстановления Вашего пароля была отправлена на
            указанную Вами почту {methods.getValues('email')}
          </Typography>
        </>
      ) : (
        <form onSubmit={onSubmit}>
          <CardContent className="flex flex-col gap-4">
            <Input
              label="Email"
              {...register('email', {
                required: 'Email is required',
              })}
              resetField={resetField}
              error={errors.email?.message}
              placeholder="email@gmail.com"
            />
          </CardContent>
          <CardFooter className="mt-8 flex flex-col items-center gap-5 lg:gap-8">
            <div className="flex flex-col items-stretch justify-center gap-4 self-stretch">
              <Button
                variant={'primary'}
                className="w-full"
                type="submit"
                disabled={!methods.watch('email')}
              >
                Отправить ссылку на почту
              </Button>
              <Typography className="text-center text-xs text-gray-500">
                У вас нет аккаунта?{' '}
                <Link
                  to={'/signup'}
                  className="font-medium text-base-900 underline"
                >
                  Создать новый аккаунт
                </Link>
              </Typography>
            </div>

            <Typography
              variant={'subtitle'}
              className="mt-4 self-stretch text-center font-normal text-[#9EA2AA]"
            >
              Используя CODEX, Вы соглашаетесь с{' '}
              <span className={'underline'}>
                Политикой конфиденциальности
              </span>
            </Typography>
          </CardFooter>
        </form>
      )}
    </FormProvider>
  )
}
