import {
  documentsApi,
  useGetDocumentsQuery,
} from '@/shared/api/documents'
import { useToast } from '@/shared/ui/Toast/useToast'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

interface IDocumentProps {
  id: number
  text: string
}

interface DocumentsResponse {
  status: string
  data: {
    page: number
    documents: IDocumentProps[]
    total_pages: number
  }
}

type Props = {
  isMobile: boolean
}

export const useDocuments = ({ isMobile }: Props) => {
  const [searchParams, setSeatchParams] = useSearchParams()
  const queryParams = Object.fromEntries(searchParams.entries())
  const observer = useRef<IntersectionObserver | null>(null)
  const { search_mode, search_query } = queryParams
  // const { toast } = useToast()
  const { data, error, isFetching } = useGetDocumentsQuery(
    {
      ...queryParams,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: search_mode && !search_query ? true : false,
    }
  )
  // if (search_mode && !search_query) {
  //   toast({
  //     title: 'Ошибка',
  //     description: 'Заполните поле поиска',
  //     variant: 'error',
  //   })
  // }

  const lastDocumentRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isFetching) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {})
      if (node) observer.current.observe(node)
    },
    [isFetching, data?.meta.next_page]
  )

  return {
    data,
    error,
    currentPage: Number(queryParams.page) || 1,
    lastDocumentRef,
    isLoading: isFetching,
  }
}
