import { Skeleton } from '@/shared/ui'
import { cn } from '@/shared/utils/common'

export const ChatContainerSkeleton = ({
  isSidebarOpen,
}: {
  isSidebarOpen: boolean
}) => {
  return (
    <div className="relative flex min-h-full w-full flex-col">
      <div className="flex-grow overflow-y-auto">
        <div
          className={cn(
            isSidebarOpen ? 'lg:min-w-[672px]' : 'lg:min-w-[768px]',
            `relative mx-auto flex w-full min-w-[672px] flex-col items-stretch
            justify-start gap-8 self-center bg-[#FCFCFC] pb-12 pt-24
            lg:max-w-[768px]`
          )}
        >
          <Skeleton
            className="flex w-[60%] flex-col items-stretch justify-start gap-4 self-end
              bg-gray-300 px-4 py-3"
          >
            <Skeleton className="h-4 w-[100%] bg-base-100" />
            <Skeleton className="h-4 w-[60%] bg-base-100" />
          </Skeleton>
          <Skeleton
            className="flex w-[60%] flex-col items-stretch justify-start gap-4 self-start
              bg-gray-300 px-4 py-3"
          >
            <Skeleton className="h-4 w-[100%] bg-base-100" />
            <Skeleton className="h-4 w-[60%] bg-base-100" />
            <Skeleton className="h-4 w-[75%] bg-base-100" />
          </Skeleton>
          <Skeleton
            className="flex w-[60%] flex-col items-stretch justify-start gap-4 self-end
              bg-gray-300 px-4 py-3"
          >
            <Skeleton className="h-4 w-[60%] bg-base-100" />
          </Skeleton>
          <Skeleton
            className="flex w-[60%] flex-col items-stretch justify-start gap-4 self-start
              bg-gray-300 px-4 py-3"
          >
            <Skeleton className="h-4 w-[60%] bg-base-100" />
            <Skeleton className="h-4 w-[80%] bg-base-100" />
          </Skeleton>
          <Skeleton className="-mt-4 h-20 w-[70%] self-start bg-gray-200" />
          <Skeleton className="-mt-4 h-20 w-[70%] self-start bg-gray-200" />
          <Skeleton className="-mt-4 h-20 w-[70%] self-start bg-gray-200" />
          <Skeleton
            className="flex w-[60%] flex-col items-stretch justify-start gap-4 self-end
              bg-gray-300 px-4 py-3"
          >
            <Skeleton className="h-4 w-[90%] bg-base-100" />
            <Skeleton className="h-4 w-[45%] bg-base-100" />
            <Skeleton className="h-4 w-[85%] bg-base-100" />
          </Skeleton>
        </div>
      </div>

      <div className="w-full">
        <Skeleton
          className={cn(
            `z-20 mx-auto flex w-full min-w-[672px] items-center justify-center
            lg:max-w-[850px]`
          )}
        ></Skeleton>
      </div>
    </div>
  )
}
