// src/shared/ui/Typography.tsx
import { cn } from '@/shared/utils/common'
import { cva, type VariantProps } from 'class-variance-authority'
import React, { ElementType } from 'react'

const typographyStyles = cva('', {
  variants: {
    variant: {
      // Heading1
      heading1: [
        'font-bold text-base-950',
        'text-[46px] leading-[52px]',
        'md:text-[54px] md:leading-[60px]',
        'xl:text-[62px] xl:leading-[68px]',
      ],
      // Heading2
      heading2: [
        'font-bold text-base-950',
        'text-[36px] leading-[42px]',
        'md:text-[44px] md:leading-[52px]',
        'xl:text-[48px] xl:leading-[54px]',
      ],
      // Heading3
      heading3: [
        'font-bold text-base-950',
        'text-[32px] leading-[38px]',
        'md:text-[36px] md:leading-[42px]',
      ],
      // Heading4
      heading4: [
        'font-bold text-base-950',
        'text-[28px] leading-[34px]',
        'md:text-[32px] md:leading-[38px]',
        'xl:leading-[36px]',
      ],
      // Heading5
      heading5: [
        'font-bold text-base-950',
        'text-[24px] leading-[30px]',
      ],
      // Title
      title: [
        'font-semibold text-base-950',
        'text-[18px] leading-[26px]',
      ],
      // Body
      body: ['font-normal', 'text-[16px] leading-[24px]'],
      // Label
      label: [
        'font-normal text-base-950',
        'text-[14px] leading-[20px]',
      ],
      // Subtitle
      subtitle: [
        'font-normal text-base-950',
        'text-[12px] leading-[16px]',
      ],
    },
  },
  defaultVariants: {
    variant: 'body',
  },
})

type TypographyProps<T extends ElementType> = {
  as?: T
  variant?: VariantProps<typeof typographyStyles>['variant']
  className?: string
  children?: React.ReactNode
} & Omit<
  React.ComponentPropsWithoutRef<T>,
  'as' | 'className' | 'children'
>

export const Typography = <T extends ElementType = 'p'>({
  as,
  variant,
  children,
  className,
  ...restProps
}: TypographyProps<T>) => {
  const Component = as || 'span'

  return (
    <Component
      className={cn(typographyStyles({ variant }), className)}
      {...restProps}
    >
      {children}
    </Component>
  )
}
