// ChatMessages.tsx
import { IChatMessage } from '../../types'
import { ChatAiResponse } from '../ChatAiResponse'
import { ChatUserRequest } from '../ChatUserRequest'

interface ChatMessagesProps {
  messages?: IChatMessage[]
}

export const ChatMessages = ({ messages }: ChatMessagesProps) => {
  if (!messages) return null

  return (
    <div className="flex flex-1 flex-col items-stretch justify-start gap-8">
      {messages.map((item, index) => {
        const isLastMessage = index === messages.length - 1
        if (item.from === 'ASSISTANT') {
          return (
            <div
              key={item.id + index}
              className="flex flex-col items-stretch justify-start gap-4"
            >
              <div className="flex min-w-0 flex-col items-stretch justify-start gap-4">
                <ChatAiResponse
                  key={item.id + index}
                  response={item}
                  isLastResponse={isLastMessage}
                />
              </div>
            </div>
          )
        } else if (item.from === 'USER') {
          return (
            <ChatUserRequest
              key={item.id + index}
              chatMessage={item}
            />
          )
        }
        return null
      })}
    </div>
  )
}
