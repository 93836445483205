import { CustomFilterInput, ECategoryType } from '@/entities/filter'
import { Checkbox } from '@/shared/ui/Checkbox'
import { Typography } from '@/shared/ui/Typography'
import React from 'react'

import { useOrganizationFilter } from '../../hooks/useOrganizationFilter'
import { OrganizationFilterAccordion } from '../OrganizationFilterAccordion'

export const OrganizationFilterDesktopMenu = () => {
  const {
    selectedIndices,
    handleSelectAll,
    handleCheckboxChange,
    allSelected,
    setOrganizations,
    setTotalOrganizationsCount,
    organizations,
    isSearching,
    setIsFiltered,
    isFiltered,
  } = useOrganizationFilter()

  return (
    <div className="relative flex flex-col overflow-hidden">
      <div className="p-0" onClick={(e) => e.preventDefault()}>
        <CustomFilterInput
          categoryType={ECategoryType.ORGANIZATIONS}
          setFilterItems={setOrganizations}
          setIsFiltered={setIsFiltered}
          setTotalCount={setTotalOrganizationsCount}
        />
        <div className="mt-2 flex items-center justify-start gap-2 px-3 py-[10px]">
          <Checkbox
            variant={'secondary'}
            size={'small'}
            checked={allSelected}
            onCheckedChange={handleSelectAll}
            id="select-all-checkbox"
          />
          <Typography variant={'subtitle'} className="text-sm">
            Все
          </Typography>
        </div>
      </div>
      <OrganizationFilterAccordion
        organizations={organizations}
        isFiltered={isFiltered}
        selectedIndices={selectedIndices}
        handleCheckboxChange={handleCheckboxChange}
        isSearching={isSearching}
        parentId={-1}
        level={0}
      />
    </div>
  )
}
