import IconChevronDown from '@/shared/assets/icons/icon_chevron_down.svg?react'
import IconSortDark from '@/shared/assets/icons/icon_sort_dark.svg?react'
import { Typography } from '@/shared/ui/Typography'
import React from 'react'
import { forwardRef, ForwardRefRenderFunction } from 'react'

interface CustomInputProps {
  value?: string
  onClick?: () => void
  className?: string
}

export const SortModeFilterCustomInput = forwardRef<
  HTMLButtonElement,
  CustomInputProps
>(({ value, onClick, className, ...props }, ref) => {
  return (
    <div className="flex shrink grow-0 basis-[150px] flex-col items-start gap-2">
      <button
        ref={ref}
        onClick={onClick}
        className={`flex h-8 items-center justify-between gap-2 self-stretch rounded-lg
          border border-[#E3E5E8] bg-white px-4 py-[6px] ${className}`}
        {...props}
      >
        <Typography variant={'label'} className="font-medium">
          {value ?? 'Сортировать'}
        </Typography>
        <IconSortDark />
      </button>
    </div>
  )
})
{
  /* <div className="flex shrink basis-[150px] items-center justify-start gap-2">
<button
  className={`${className} flex h-[40px] w-full items-center justify-between gap-2
    rounded-lg border border-[#E3E5E8] bg-white px-4 py-2`}
  onClick={onClick}
  ref={ref}
>
  <Typography className="font-Inter whitespace-nowrap text-sm font-medium leading-[20px]">
    {value ?? 'Статус'}
  </Typography>
  <IconPlanetDark />
</button>
</div> */
}
