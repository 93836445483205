import { Checkbox } from '@/shared/ui/Checkbox'
import { Typography } from '@/shared/ui/Typography'

import { IActForm } from '../../types'

interface ActFormItemProps {
  items: IActForm[]
  selectedIndices: number[]
  handleCheckboxChange: (id: number) => void
}

export const ActFormFilterItems: React.FC<ActFormItemProps> = ({
  items,
  selectedIndices,
  handleCheckboxChange,
}) => {
  return (
    <>
      {items.map((item) => (
        <div className="p-1">
          <label
            key={item.id}
            htmlFor={`checkbox-${item.id}`}
            className="flex cursor-pointer items-start justify-start gap-2 self-stretch px-2
              py-[6px]"
          >
            <Checkbox
              variant={'secondary'}
              size={'small'}
              id={`checkbox-${item.id}`}
              checked={selectedIndices.includes(item.id)}
              onCheckedChange={() => handleCheckboxChange(item.id)}
            />
            <span className="flex items-start justify-start">
              <Typography
                variant={'label'}
                className="align-start inline"
              >
                {item.name}{' '}
                <span>
                  <Typography
                    variant={'subtitle'}
                    className="inline text-[#747474]"
                  >
                    ({item.amount})
                  </Typography>
                </span>
              </Typography>
            </span>
          </label>
        </div>
      ))}
    </>
  )
}
