import axios from 'axios'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

import { config } from './shared/api'
import { useAuthorizeChannelMutation } from './shared/api/broadcast'

export function createSocketConnection(token: string) {
  window.Pusher = Pusher

  if (!window.Echo) {
    window.Echo = new Echo({
      broadcaster: 'reverb',
      key: import.meta.env.VITE_REVERB_APP_KEY,
      wsHost: import.meta.env.VITE_REVERB_HOST,
      wsPort: import.meta.env.VITE_REVERB_PORT,
      wssPort: import.meta.env.VITE_REVERB_PORT,
      forceTLS: false,
      encrypted: true,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      authorizer: (channel: any, options: any) => {
        return {
          authorize: async (socketId: string, callback: Function) => {
            try {
              const response = await axios.post(
                `${config.API_BASE}/broadcasting/auth`,
                {
                  socket_id: socketId,
                  channel_name: channel.name,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )

              if (response.data) {
                callback(false, response.data) // Authorization successful
              } else {
                callback(true, response.data) // Authorization failed
              }
            } catch (error) {
              callback(true, error) // Handle error during authorization
            }
          },
        }
      },

      withCredentials: true,
      enabledTransports: ['ws', 'wss'],
    })
  }
}
