import Cookies from 'js-cookie'
import { useEffect } from 'react'

import { createSocketConnection } from './socketService'

function listen(
  callBack: (payload: any) => void,
  channel: string,
  event: string
) {
  // console.log(`event: ${event}, channel: ${channel}`)

  window.Echo.private(channel).listen(event, (payload: any) => {
    callBack(payload)
  })

  return function cleanUp() {
    window.Echo.leaveChannel(`${channel}`)
  }
}

type Options = {
  type: 'CHAT'
  userId: number
  callBack: (payload: any) => void
}

export const useSocket = ({ type, userId, callBack }: Options) => {
  const token = Cookies.get('access_token')
  // const token = '3|G4prZ9ca50q9SD0aCopliw5bQQlpioAFoD0aOzPzba0e5b9b'

  useEffect(() => {
    if (token) {
      createSocketConnection(token)
      switch (type) {
        case 'CHAT': {
          return listen(
            callBack,
            `chat.${userId}`,
            '.ai-assist.responded'
          )
        }
      }
    }
  }, [])
}
