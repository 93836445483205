import IconLogo from '@/shared/assets/icons/icon_logo.svg?react'
import { useAppDispatch } from '@/shared/store'
import { openChatPanel } from '@/shared/store/slices/chat/documentPreviewSlice'
import { IconButton } from '@/shared/ui/IconButton'
import React from 'react'

export const ChatPanelButton = () => {
  const dispatch = useAppDispatch()

  return (
    <div
      className="fixed right-0 top-1/2 z-50 flex h-[514px] w-4 -translate-y-1/2
        transform items-center justify-center rounded-bl-3xl rounded-tl-3xl
        bg-white shadow-shadow2"
    >
      <IconButton
        className="h-8 w-8"
        onClick={() => dispatch(openChatPanel())}
      >
        <IconLogo className="-translate-x-[16px] transform" />
      </IconButton>
    </div>
  )
}
