import { Provider } from 'react-redux'

import { AppRoutes } from './app/router'
import store from './shared/store'
import { Toaster } from './shared/ui/Toast/toaster'

function App() {
  return (
    <Provider store={store}>
      <Toaster />
      <AppRoutes />
    </Provider>
  )
}
export default App
