import { useChatHistory } from '@/entities/chatHistory/models/useChatHistory'
import IconBookmarkGray from '@/shared/assets/icons/icon_bookmark_gray.svg?react'
import IconEdit from '@/shared/assets/icons/icon_edit.svg?react'
import IconGrid from '@/shared/assets/icons/icon_grid.svg?react'
import IconStarGray from '@/shared/assets/icons/icon_star_gray.svg?react'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTrigger,
} from '@/shared/ui/Drawer'
import { IconButton } from '@/shared/ui/IconButton'
import { cn } from '@/shared/utils/common'
import { WidgetChatHistory } from '@/widgets/WidgetChatHistory'
import { ReactNode, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const MobileSidebar = ({
  children,
}: {
  children: ReactNode
}) => {
  const location = useLocation()
  const isFavoritesPage = location.pathname.includes(
    '/chats/favorites'
  )

  const {
    isLoading,
    todayChats,
    yesterdayChats,
    otherChatsByDate,
    handleFavoritesToggled,
  } = useChatHistory({ isFavoritesPage: isFavoritesPage })
  const navigate = useNavigate()
  const [isFavorite, setIsFavorite] = useState<boolean>(false)

  return (
    <Drawer direction="left">
      <DrawerTrigger>{children}</DrawerTrigger>
      <DrawerContent
        className="absolute mb-[71px] h-[calc(100svh-71px)] w-[calc(100vw-59px)]
          overflow-y-auto overflow-x-hidden rounded-none bg-white pb-0 pt-0
          sm:w-[240px] md:w-[384px]"
      >
        <DrawerHeader
          className="sticky top-0 -mx-5 -mt-1 flex items-center justify-between
            bg-white-blured pb-0 pt-0 shadow-sm backdrop-blur-4px"
        >
          <DrawerClose>
            <IconButton>
              <IconGrid className="h-6 w-6" />
            </IconButton>
          </DrawerClose>
          <IconButton onClick={handleFavoritesToggled}>
            <IconBookmarkGray
              width={24}
              height={24}
              fill={isFavoritesPage ? '#4D8EFF' : 'none'}
              className={
                isFavoritesPage
                  ? '[&>path]:stroke-[#4D8EFF]'
                  : '[&>path]:stroke-[#67707E]'
              }
            />
          </IconButton>
          <DrawerClose onClick={() => navigate('/')}>
            <IconButton>
              <IconEdit className="h-6 w-6" />
            </IconButton>
          </DrawerClose>
        </DrawerHeader>
        <WidgetChatHistory
          isLoading={isLoading}
          todayChats={todayChats}
          yesterdayChats={yesterdayChats}
          otherChatsByDate={otherChatsByDate}
        />
      </DrawerContent>
    </Drawer>
  )
}
