import { useFilters } from '@/entities/filter/models/useFilters'
import {
  ActFormFilterAccordion,
  GroupFilterAccordion,
  GroupFilterDesktopMenu,
  OrganizationFilterDesktopMenu,
  TypeFilterDesktopMenu,
} from '@/features/filters'
import IconChevronRight from '@/shared/assets/icons/icon_chevron_right_dark.svg?react'
import IconExitLeft from '@/shared/assets/icons/icon_exit_left.svg?react'
import IconFilterDark from '@/shared/assets/icons/icon_filter_dark.svg?react'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  IconButton,
  Typography,
} from '@/shared/ui'
import { cn } from '@/shared/utils/common'
import { flattenFiltersJSON } from '@/shared/utils/db'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'

export const WidgetCategories = () => {
  const {
    accordionHeight,
    accordionRef,
    containerHeight,
    containerRef,
    handleMenuToggle,
    isMenuOpen,
    itemMaxHeight,
  } = useFilters()

  return (
    <motion.div
      className={cn(
        `relative flex max-h-full w-full flex-col items-stretch justify-start
        self-stretch overflow-hidden pb-8`
      )}
      ref={containerRef}
      animate={{
        width: isMenuOpen ? 290 : 40,
      }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
    >
      {/* IconButton */}
      <motion.div
        initial={{ opacity: 1 }}
        animate={{ opacity: isMenuOpen ? 0 : 1 }}
        transition={{ duration: 0.2 }}
        className="absolute left-0 top-0"
      >
        <IconButton
          onClick={handleMenuToggle}
          className="h-10 w-10 bg-white p-[10px]"
          style={{
            border:
              '1px solid var(--Stroke-Controls-white, rgba(46, 50, 56, 0.04))',
            boxShadow:
              '0px 18px 5px 0px rgba(0, 0, 0, 0.00), 0px 12px 5px 0px rgba(0, 0, 0, 0.00), 0px 7px 4px 0px rgba(0, 0, 0, 0.02), 0px 3px 3px 0px rgba(0, 0, 0, 0.03), 0px 1px 2px 0px rgba(0, 0, 0, 0.03)',
          }}
        >
          <IconFilterDark />
        </IconButton>
      </motion.div>

      {/* Animated Menu Drawer */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            ref={accordionRef}
            key="accordion"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className="z-20 max-h-full w-full shrink"
            style={{
              boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.04)',
            }}
          >
            <Accordion
              collapsible
              orientation="vertical"
              type="single"
              className="max-h-full min-h-0 w-full gap-0 overflow-hidden rounded-[24px] border
                border-gray-100 bg-white p-0"
            >
              {/* Accordion Header */}
              <div className="flex items-center justify-between border-b border-b-[#F0F0F0] p-4">
                <Typography
                  variant={'label'}
                  className="font-semibold text-base-500"
                >
                  Категории
                </Typography>
                <IconButton onClick={handleMenuToggle}>
                  <IconExitLeft />
                </IconButton>
              </div>

              {/* Accordion Items */}
              <AccordionItem
                value="types"
                className="flex min-h-0 flex-col overflow-y-scroll"
              >
                <AccordionTrigger
                  className="flex w-full flex-row items-center justify-between px-4 py-5"
                  iconSvg={
                    <IconChevronRight className="max-h-6 max-w-6" />
                  }
                >
                  <Typography
                    className="whitespace-nowrap font-medium"
                    variant={'label'}
                  >
                    Тип документа
                  </Typography>
                </AccordionTrigger>
                <AccordionContent
                  className={cn(
                    'flex max-h-full flex-col items-stretch justify-start px-2'
                  )}
                  style={{ maxHeight: itemMaxHeight ?? '' }}
                >
                  <TypeFilterDesktopMenu />
                </AccordionContent>
              </AccordionItem>

              <AccordionItem
                value="groups"
                className="flex min-h-0 flex-col overflow-y-scroll"
              >
                <AccordionTrigger
                  className="flex w-full flex-row items-center justify-between px-4 py-5"
                  iconSvg={
                    <IconChevronRight className="max-h-6 max-w-6" />
                  }
                >
                  <Typography
                    className="whitespace-nowrap font-medium"
                    variant={'label'}
                  >
                    Раздел
                  </Typography>
                </AccordionTrigger>
                <AccordionContent
                  className={cn(
                    'flex max-h-full flex-col items-stretch justify-start px-2'
                  )}
                  style={{ maxHeight: itemMaxHeight ?? '' }}
                >
                  <GroupFilterDesktopMenu />
                </AccordionContent>
              </AccordionItem>
              <AccordionItem
                value="organizations"
                className="flex min-h-0 flex-col overflow-y-scroll"
              >
                <AccordionTrigger
                  className="flex w-full flex-row items-center justify-between px-4 py-5"
                  iconSvg={
                    <IconChevronRight className="max-h-6 max-w-6" />
                  }
                >
                  <Typography
                    className="whitespace-nowrap font-medium"
                    variant={'label'}
                  >
                    Орган
                  </Typography>
                </AccordionTrigger>
                <AccordionContent
                  className={cn(
                    'flex max-h-full flex-col items-stretch justify-start px-2'
                  )}
                  style={{ maxHeight: itemMaxHeight ?? '' }}
                >
                  <OrganizationFilterDesktopMenu />
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}
