import {
  ISigninIndividualDTO,
  ISigninJudicialDTO,
} from '@/entities/signin/types'
import {
  ISignupIndividualDTO,
  ISignupJudicialDTO,
} from '@/entities/signup/types'
import { api } from '@/shared/api'
import Cookies from 'js-cookie'
import { redirect } from 'react-router-dom'

interface ICompanyType {
  code: string
  name: string
}
export interface IAuthResponse {
  success: boolean
  user_id: number
  access_token: string
}

export const postSignupJudicial = async (
  data: ISignupJudicialDTO
): Promise<IAuthResponse> => {
  const response = await api.post<IAuthResponse>(
    '/registration/company',
    data
  )
  return response.data
}

export const postSignupIndividual = async (
  data: ISignupIndividualDTO
): Promise<IAuthResponse> => {
  const response = await api.post<IAuthResponse>(
    `/registration/personal`,
    data,
    {
      headers: {
        'ngrok-skip-browser-warning': 'true',

        'Content-Type': 'application/json',
      },
    }
  )
  return response.data
}

export const postOtpPhone = async (phone: string) => {
  const response = await api.post(
    '/otps/phone',
    { phone },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  return response.data
}

interface ISigninIndividualResponse {
  success: boolean
  user_id: number
  access_token: string
}

export const postSigninIndividual = async (
  data: ISigninIndividualDTO
): Promise<ISigninIndividualResponse> => {
  const response = await api.post('/authentication/personal', data, {
    headers: {
      'ngrok-skip-browser-warning': 'true',
      'Content-Type': 'application/json',
    },
  })
  return response.data
}

export const postSigninJudicial = async (
  data: ISigninJudicialDTO
) => {
  const response = await api.post('/authentication/company', data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data
}

interface ICompanyTypesResponse {
  success: true
  company_types: ICompanyType[]
}

export const getCompanyTypes = async () => {
  const response = await api.get<ICompanyTypesResponse>(
    '/handbooks/company-types',
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  return response.data
}

interface IPasswordRecovery {
  new_password: string
  confirm_password: string
}

export const putPasswordRecovery = async (
  token: string,
  data: IPasswordRecovery
) => {
  const response = await api.put(
    `/password-recovery/${token}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}

export interface IGetUserResponse {
  success: boolean
  user: {
    id: number
    name: string
    phone: string
    email?: string
  }
}

export const getUser = async (): Promise<IGetUserResponse> => {
  const access_token = Cookies.get('access_token')

  if (!access_token) {
    throw new Error('No access token provided')
  }

  const response = await api.get('/user', {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
  })
  return response.data
}
