import { ISigninJudicialDTO } from '@/entities/signin/types'
import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'

import { config } from '..'
import { IAuthResponse } from '../auth'

export const signinJudicialApi = createApi({
  reducerPath: 'signinJudicialApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE,
    prepareHeaders: (headers) => {
      return headers
    },
  }),
  endpoints: (builder) => ({
    signinJudicial: builder.mutation<
      IAuthResponse,
      ISigninJudicialDTO
    >({
      query: (data) => ({
        url: '/authentication/company',
        method: 'POST',
        body: data,
      }),
    }),
  }),
})

export const { useSigninJudicialMutation } = signinJudicialApi
