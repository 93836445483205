import {
  DesktopRangePicker,
  DocumentStatusFilterDesktopMenu,
  SortModeFilterDesktopMenu,
} from '@/features/filters'
import IconBookmarkBlack from '@/shared/assets/icons/icon_bookmark_black.svg?react'
import { IconButton, Typography } from '@/shared/ui'
import React from 'react'

type Props = {
  totalDocuments: number
}

export const WidgetDocumentFilters = ({ totalDocuments }: Props) => {
  return (
    <div className="shrink-0 grow self-stretch border-b border-[#F0F0F0]">
      <div
        className="flex flex-nowrap items-center justify-between gap-3 overflow-x-scroll
          py-3 pl-5 pr-3"
      >
        <Typography
          variant={'label'}
          className="font-semibold text-base-400"
        >
          {`Найдено документов: ${totalDocuments ?? '-'}`}
        </Typography>
        <div className="inline-flex shrink-0 grow items-center justify-end gap-4">
          <DesktopRangePicker />
          <DocumentStatusFilterDesktopMenu />
          <SortModeFilterDesktopMenu />
          <IconButton className="h-8 w-8 border-[1px] border-[#E3E5E8] bg-white p-2">
            <IconBookmarkBlack className="h-4 w-4" />
          </IconButton>
        </div>
      </div>
    </div>
  )
}
