import { IUser } from '@/entities/user/models'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'

// Import IUser interface from the userApi file

interface IUserState {
  isAuthenticated: boolean
  data: IUser | null
}

const initialState: IUserState = {
  isAuthenticated: false,
  data: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<IUser>) => {
      state.data = action.payload
      state.isAuthenticated = true
    },
    logout: (state) => {
      state.isAuthenticated = false
      state.data = null
      Cookies.remove('access_token') // Remove the access token on logout
    },
  },
})

export const { updateUser, logout } = userSlice.actions
export default userSlice.reducer
