import { Typography } from '@/shared/ui'
import React from 'react'

import { EDocumentStatus } from '../../types'

type DocumentStatusBadge = {
  label: string
  color: string
  textColor: string
}

const documentStatusBadgeVariants: Record<
  EDocumentStatus,
  DocumentStatusBadge
> = {
  [EDocumentStatus.ACTIVE]: {
    label: 'Действующий',
    color: '#EDF3EC',
    textColor: '#4A6F47',
  },
  [EDocumentStatus.ARTICLE]: {
    label: 'Статья',
    color: '#EDEEF1',
    textColor: '#67707E',
  },
  [EDocumentStatus.PENDING]: {
    label: 'Не введен в действие',
    color: '#FCECD6',
    textColor: '#845F34',
  },
  [EDocumentStatus.EXPIRED]: {
    label: 'Утратил силу',
    color: '#FDEBEC',
    textColor: '#8F5752',
  },
}
export const DocumentItemStatus = ({
  status,
}: {
  status: EDocumentStatus
}) => {
  const { label, color, textColor } =
    documentStatusBadgeVariants[status]

  return (
    <Typography
      variant={'subtitle'}
      className="rounded-[4px] px-2 py-[2px] font-semibold"
      style={{
        backgroundColor: color,
        color: textColor,
      }}
    >
      {label}
    </Typography>
  )
}
