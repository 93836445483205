import IconChevronDown from '@/shared/assets/icons/icon_chevron_down.svg?react'
import { Typography } from '@/shared/ui/Typography'
import { forwardRef, ForwardRefRenderFunction } from 'react'

interface CustomInputProps {
  value?: string
  onClick?: () => void
  className?: string
}

const CustomInput: ForwardRefRenderFunction<
  HTMLButtonElement,
  CustomInputProps
> = ({ value, onClick, className }, ref) => (
  <div className="flex flex-col items-start gap-[3px]">
    <button
      className={`${className} flex h-[40px] items-center justify-between gap-2
        self-stretch rounded-lg border border-[#E3E5E8] bg-white px-4 py-2`}
      onClick={onClick}
      ref={ref}
    >
      <Typography className="font-Inter whitespace-nowrap text-sm font-medium leading-[20px]">
        {value ?? 'Форма акта'}
      </Typography>
      <IconChevronDown />
    </button>
  </div>
)

export const ActFormFilterCustomInput = forwardRef(CustomInput)
