import { IActForm } from '../types'

export const mockActFormData: IActForm[] = [
  { id: 1, name: 'Самый важный фильтр', amount: 886057 },
  {
    id: 2,
    name: 'Аналитика, публикации, выступления',
    amount: 886057,
  },
  {
    id: 3,
    name: 'Новости, информационные сообщения',
    amount: 886057,
  },
  {
    id: 4,
    name: 'Проекты',
    amount: 886057,
  },
  {
    id: 5,
    name: 'НТД',
    amount: 886057,
  },
  {
    id: 6,
    name: 'Ответы гос.органов',
    amount: 886057,
  },
  {
    id: 7,
    name: 'Образца документов',
    amount: 886057,
  },
]
