import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { Outlet } from 'react-router-dom'

export const DocumentsLayout = () => {
  const isMobile = useMediaQuery('(max-width: 1279px)')

  if (isMobile)
    return (
      <div className="relative flex flex-1 flex-col px-8 pt-24">
        <Outlet />
      </div>
    )

  return (
    <div className="relative flex flex-1 flex-col px-8 pt-24">
      <Outlet />
    </div>
  )
}
