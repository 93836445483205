import { putPasswordRecovery } from '@/shared/api/auth'
import { useToast } from '@/shared/ui/Toast/useToast'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

interface IFormInput {
  new_password: string
  confirm_password: string
}

export const usePasswordRecovery = (token: string) => {
  const methods = useForm<IFormInput>()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods

  const navigate = useNavigate()

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    try {
      await putPasswordRecovery(token, data)

      navigate('/signin')
    } catch (err: any) {
      console.error('recovery fail')
    }
  }

  return { methods, register, handleSubmit, onSubmit, errors }
}
