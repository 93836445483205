import { api } from '@/shared/api'
import { useToast } from '@/shared/ui/Toast/useToast'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

interface IResetPassword {
  email: string
}

export const useResetPassword = () => {
  const { toast } = useToast()
  const methods = useForm<IResetPassword>()
  const [linkSent, setLinkSent] = useState<boolean>()
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = methods

  const onSubmit = handleSubmit(async (data) => {
    try {
      await api.post('/password-recovery', {
        email: data.email,
      })
      setLinkSent(true)
    } catch (error: any) {
      console.error('reset fail')
    }
  })

  return {
    resetField,
    register,
    methods,
    linkSent,
    onSubmit,
    errors,
  }
}
