const lunrModule = await import('lunr')
const lunr = lunrModule.default || lunrModule

const stemmerSupportModule = await import(
  'lunr-languages/lunr.stemmer.support'
)
const stemmerSupport =
  stemmerSupportModule.default || stemmerSupportModule
const ruModule = await import('lunr-languages/lunr.ru')
const ru = ruModule.default || ruModule
const multiModule = await import('lunr-languages/lunr.multi')
const multi = multiModule.default || multiModule

// Apply the plugins to Lunr
stemmerSupport(lunr)
ru(lunr)
multi(lunr)

export { lunr }
