import { IChatMessage } from '@/entities/chat/types'
import { usePostNewChatMessageMutation } from '@/shared/api/chat'
import { addUserMessage } from '@/shared/store/slices/chat/chatSlice'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

export const useChatInput = ({
  chatId,
}: {
  chatId: string | undefined
}) => {
  const [isSending, setIsSending] = useState(false)
  const [inputValue, setInputValue] = useState<string>('')
  const selectRef = useRef<HTMLInputElement>(null)

  const [postNewChatMessage] = usePostNewChatMessageMutation()

  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.focus()
    }
  }, [])

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputValue(e.target.value)
  }

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault()
    if (!inputValue.trim() || !chatId) return

    setIsSending(true)
    try {
      // Send the message using RTK Query mutation
      await postNewChatMessage({
        chatId,
        data: { message: inputValue },
      }).unwrap()
      setInputValue('')
    } catch (error) {
      console.error('Failed to send message:', error)
    } finally {
      setIsSending(false)
    }
  }

  return {
    isSending,
    inputValue,
    selectRef,
    handleInputChange,
    handleSubmit,
  }
}

// const sendMessage = async (query: string, chat_id: string) => {
//   try {
//     const access_token = Cookies.get('access_token')
//     const response = await api.post(
//       `/chats/${chat_id}/messages`,
//       { message: query },
//       {
//         headers: {
//           Authorization: `Bearer ${access_token}`,
//           'Content-Type': 'application/json',
//         },
//       }
//     )

//     // if (!response.ok) {
//     //   throw new Error(
//     //     `Error: ${response.status} ${response.statusText}`
//     //   )
//     // }

//     // const data: IReply = await response.json()

//     return response
//   } catch (error) {
//     console.error('Error sending message:', error)
//     throw error
//   }
// }
