import { IUser } from '@/entities/user/models'
import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie'

import { config } from '..'

export interface IGetUserResponse {
  success: boolean
  user: IUser
}

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE,
    prepareHeaders: (headers) => {
      const access_token = Cookies.get('access_token')
      if (access_token) {
        headers.set('Authorization', `Bearer ${access_token}`)
      }
      headers.set('Accept', 'application/json')
      return headers
    },
  }),
  endpoints: (builder) => ({
    getUser: builder.query<IGetUserResponse, void>({
      query: () => '/users',
    }),
  }),
})

// Export the generated hook
export const { useGetUserQuery } = userApi
