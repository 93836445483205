import { WidgetSignin } from '@/widgets/auth/WidgetSignin'

export const SigninPage = () => {
  return (
    <div className="relative flex h-[100dvh] flex-col">
      <div className="my-8">
        <WidgetSignin />
      </div>
    </div>
  )
}
