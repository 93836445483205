import IconGearDark from '@/shared/assets/icons/icon_gear_dark.svg?react'
import { Button } from '@/shared/ui/Button'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from '@/shared/ui/Menubar'
import { Typography } from '@/shared/ui/Typography'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface ProfileDTO {
  id: number
  name: string
  email: string
}
const mockProfile: ProfileDTO = {
  id: 10,
  name: 'Талгат',
  email: 'talgat@gmail.com',
}

type Props = {
  children: ReactNode
}

export const ProfileNavigationMenu = ({ children }: Props) => {
  return (
    <Menubar className="self-stretch border-none shadow-none hover:cursor-pointer">
      <MenubarMenu>
        <MenubarTrigger>{children}</MenubarTrigger>
        <MenubarContent
          className="z-50 flex w-[224px] flex-col items-stretch gap-2 rounded-[20px]
            bg-white p-2 shadow-shadow2"
          align="start"
          alignOffset={-180}
          sideOffset={0}
        >
          <div className="flex flex-col gap-4 p-2">
            <div className="flex flex-col items-stretch justify-start">
              <Typography variant={'heading4'}>
                {mockProfile.name}
              </Typography>
              <Typography variant={'subtitle'}>
                {mockProfile.email}
              </Typography>
            </div>
            <Button variant={'primary'}>Улучшить план</Button>
          </div>
          <MenubarItem
            className="flex items-center justify-start gap-3 rounded-lg border-y-[1px]
              border-y-slate-200 px-2 py-3 hover:cursor-pointer hover:bg-slate-100"
            inset={true}
          >
            <IconGearDark />
            <Typography>Настройки</Typography>
          </MenubarItem>
          <MenubarItem
            className="flex items-center justify-start gap-3 rounded-lg p-2
              hover:cursor-pointer hover:bg-slate-100"
            inset={true}
          >
            Поддержка
          </MenubarItem>
          <MenubarItem
            className="flex items-center justify-start gap-3 rounded-lg p-2
              hover:cursor-pointer hover:bg-slate-100"
            inset={true}
          >
            Планы
          </MenubarItem>
          <MenubarItem
            className="flex items-center justify-between gap-4 border-t-[1px]
              border-t-slate-200 p-2"
          >
            <Link to={'#'}>
              <Typography variant={'subtitle'}>Права</Typography>
            </Link>
            <Link to={'#'}>
              <Typography variant={'subtitle'}>Условия</Typography>
            </Link>
            <Link to={'#'}>
              <Typography variant={'subtitle'}>Политика</Typography>
            </Link>
          </MenubarItem>
        </MenubarContent>
      </MenubarMenu>
    </Menubar>
  )
}
