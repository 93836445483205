// ChatMessages.tsx
import IconArrowRightBlue from '@/shared/assets/icons/icon_arrow_right_blue.svg?react'
import IconCommentEmptyBlue from '@/shared/assets/icons/icon_comment_empty_blue.svg?react'
import { Typography } from '@/shared/ui'
import { format, isValid } from 'date-fns'
import { Link } from 'react-router-dom'

import { IChatMessage } from '../../types'
import { ChatAiResponse } from '../ChatAiResponse'

interface Props {
  messages?: IChatMessage[]
}

export const FavoriteChatMessages = ({ messages }: Props) => {
  if (!messages) return null

  return (
    <div className="flex flex-1 flex-col items-stretch justify-start gap-8">
      {messages.map((item, index) => {
        return (
          <div className="flex flex-col items-stretch justify-start gap-4">
            {item.created_at && isValid(new Date(item.created_at)) ? (
              <Typography
                className="flex items-center justify-start border-b-[1px] border-base-100 py-2
                  text-[14px] font-semibold"
              >
                {format(item.created_at, 'MM.dd.yyyy')}
              </Typography>
            ) : null}
            <div className="flex min-w-0 flex-col items-stretch justify-start gap-4">
              {item.is_favorite && (
                <Link
                  to={`/chats/${item.chat?.id}`}
                  className="group flex max-w-full items-center justify-start gap-[6px] self-start
                    overflow-hidden rounded-[6px] border-[1px] border-base-200 bg-base-50
                    px-2 py-1 transition-colors duration-200 ease-in-out hover:bg-base-100"
                >
                  <Typography
                    variant={'label'}
                    className="min-w-0 overflow-hidden truncate whitespace-nowrap font-semibold"
                  >
                    В избранных из диалога: {item.chat?.title}
                  </Typography>
                  <div className="relative h-4 w-4">
                    <IconCommentEmptyBlue className="absolute" />
                    <IconArrowRightBlue
                      className="absolute transition-transform duration-150 ease-in-out
                        group-hover:translate-x-[7px]"
                    />
                  </div>
                </Link>
              )}

              <ChatAiResponse
                key={item.id + index}
                response={item}
                isLastResponse={true}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}
