import IconClose from '@/shared/assets/icons/icon_close.svg?react'
import { Button } from '@/shared/ui/Button'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTrigger,
} from '@/shared/ui/Drawer'
import { Typography } from '@/shared/ui/Typography'
import { MobileCustomSearchInput } from '@/widgets/DocumentsBase/MobileCustomSearchInput'

import { mockActFormData } from '../../consts'
import { useActFormFilter } from '../../hooks/useActFormFilter'
import { ActFormFilterCustomInput } from '../ActFormFilterCustomInput'
import { ActFormFilterItems } from '../ActFormFilterItems'

export const ActFormFilterDrawer = () => {
  const {
    selectedIndices,
    handleCheckboxChange,
    handleApply,
    handleReset,
  } = useActFormFilter(mockActFormData)

  return (
    <Drawer direction="bottom">
      <DrawerTrigger>
        <ActFormFilterCustomInput />
      </DrawerTrigger>
      <DrawerContent className="z-[70] h-[92svh] overflow-y-hidden sm:h-[90vh] md:h-[88vh]">
        <DrawerClose className="absolute right-6 top-10">
          <IconClose className="h-6 w-6" />
        </DrawerClose>
        <DrawerHeader className="flex flex-col items-stretch justify-start gap-8 p-0">
          <Typography className="self-start text-[28px] font-bold leading-[34px] text-black">
            Форма акта
          </Typography>
          <MobileCustomSearchInput />
        </DrawerHeader>
        <DrawerDescription
          className="-mx-6 mt-8 flex flex-col items-stretch justify-start gap-5
            overflow-y-scroll px-6"
        >
          <ActFormFilterItems
            items={mockActFormData}
            selectedIndices={selectedIndices}
            handleCheckboxChange={handleCheckboxChange}
          />
        </DrawerDescription>
        <DrawerFooter className="flex flex-row flex-nowrap justify-between gap-5 p-0 pb-5">
          <DrawerClose className="shrink-0 grow">
            <Button
              variant={'tonal'}
              className="w-full"
              onClick={handleApply}
            >
              Применить
            </Button>
          </DrawerClose>
          <DrawerClose className="shrink-0 grow">
            <Button
              variant={'outlined'}
              className="w-full"
              onClick={handleReset}
            >
              Сбросить
            </Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
