import { cn } from '@/shared/utils/common'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import * as React from 'react'

const Tabs = TabsPrimitive.Root

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      `text-muted-foreground inline-flex h-10 items-center justify-center
      gap-3 self-center rounded-[100px] bg-base-100 p-1`,
      className
    )}
    {...props}
  />
))
TabsList.displayName = TabsPrimitive.List.displayName

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      `ring-offset-background focus-visible:ring-ring inline-flex
      items-center justify-center self-stretch whitespace-nowrap
      rounded-[100px] px-3 text-sm font-bold leading-4 text-base-400
      transition-all focus-visible:outline-none focus-visible:ring-2
      focus-visible:ring-offset-2 disabled:pointer-events-none
      disabled:opacity-50 data-[state=active]:bg-base-20
      data-[state=active]:text-base-950 data-[state=active]:shadow-shadow3`,
      className
    )}
    {...props}
  />
))
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      `ring-offset-background focus-visible:ring-ring mt-2
      focus-visible:outline-none focus-visible:ring-2
      focus-visible:ring-offset-2`,
      className
    )}
    {...props}
  />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsList, TabsTrigger, TabsContent }
