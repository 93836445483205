import IconChevronLeft from '@/shared/assets/icons/icon_chevron_left_dark.svg?react'
import IconChevronRight from '@/shared/assets/icons/icon_chevron_right_dark.svg?react'
import IconMoreDotsDark from '@/shared/assets/icons/icon_more_dots_dark.svg?react'
import { cn } from '@/shared/utils/common'
import { cva } from 'class-variance-authority'
import * as React from 'react'

import { Typography } from '../Typography'

type ButtonProps = {
  variant?: 'outline' | 'filled' | 'ghost'
  size?: 'icon' | 'default'
  disabled?: boolean
}

const buttonVariants = cva(
  'flex items-center min-w-10 justify-center w-full flex-nowrap px-4 transition-colors hover:cursor-pointer duration-200 ease-in-out',
  {
    variants: {
      variant: {
        outline: [
          '!px-4',
          '!py-3',
          'min-w-10',
          'border',
          'border-base-200',
          'bg-white',
          'rounded-md',
          'hover:bg-gray-100',
          'active:bg-[#4D8EFF]',
          'active:text-white',
        ],
        filled: [
          '!px-4',
          '!py-3',
          'min-w-10',
          'bg-black',
          'text-white',
          'rounded-md',
        ],
        ghost: [
          'p-2',
          'border',
          'border-gray-200',
          'bg-gray-100',
          'rounded-md',
          'hover:bg-gray-100',
        ],
      },
      size: {
        icon: ['h-[48px] min-w-10'],
        default: ['h-[48px] px-4 py-3'],
      },
      isActive: {
        true: ['bg-[#4D8EFF]', 'text-white'],
        false: [''],
      },
      disabled: {
        true: ['!cursor-default'],
        false: [''],
      },
    },
    defaultVariants: {
      variant: 'outline',
      size: 'icon',
      isActive: false,
      disabled: false,
    },
  }
)

const Pagination = ({
  className,
  ...props
}: React.ComponentProps<'nav'>) => (
  <nav
    role="navigation"
    aria-label="pagination"
    className={cn('mx-auto flex w-full justify-center', className)}
    {...props}
  />
)
Pagination.displayName = 'Pagination'

const PaginationContent = React.forwardRef<
  HTMLUListElement,
  React.ComponentProps<'ul'>
>(({ className, ...props }, ref) => (
  <ul
    ref={ref}
    className={cn('flex flex-row items-center gap-2', className)}
    {...props}
  />
))
PaginationContent.displayName = 'PaginationContent'

const PaginationItem = React.forwardRef<
  HTMLLIElement,
  React.ComponentProps<'li'>
>(({ className, ...props }, ref) => (
  <li ref={ref} className={cn('', className)} {...props} />
))
PaginationItem.displayName = 'PaginationItem'

type PaginationLinkProps = {
  isActive?: boolean
} & Pick<ButtonProps, 'size' | 'variant' | 'disabled'> &
  React.ComponentProps<'a'>

const PaginationLink = ({
  className,
  isActive = false,
  size = 'icon',
  variant = 'outline',
  disabled = false,
  ...props
}: PaginationLinkProps) => (
  <a
    aria-current={isActive ? 'page' : undefined}
    className={cn(
      buttonVariants({
        variant: isActive ? 'filled' : variant,
        size,
        isActive,
        disabled,
      }),
      className
    )}
    {...props}
  />
)
PaginationLink.displayName = 'PaginationLink'

const PaginationPrevious = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label="Go to previous page"
    size="default"
    className={cn('gap-2', className)}
    {...props}
  >
    <IconChevronLeft className="h-5 w-5" />
    <Typography>Назад</Typography>
  </PaginationLink>
)
PaginationPrevious.displayName = 'PaginationPrevious'

const PaginationNext = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label="Go to next page"
    size="default"
    className={cn('gap-2 py-3 pl-4 pr-2', className)}
    {...props}
  >
    <Typography>Вперед</Typography>
    <IconChevronRight className="h-5 w-5" />
  </PaginationLink>
)
PaginationNext.displayName = 'PaginationNext'

const PaginationEllipsis = ({
  className,
  ...props
}: React.ComponentProps<'span'>) => (
  <span
    aria-hidden
    className={cn(
      buttonVariants({
        variant: 'outline',
        size: 'icon',
      }),
      className
    )}
    {...props}
  >
    <IconMoreDotsDark className="h-5 w-5" />
    <span className="sr-only">More pages</span>
  </span>
)
PaginationEllipsis.displayName = 'PaginationEllipsis'

export {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
}
