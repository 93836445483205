import IconClose from '@/shared/assets/icons/icon_close.svg?react'
import IconMoreDotsDark from '@/shared/assets/icons/icon_more_dots_dark.svg?react'
import IconOpenDark from '@/shared/assets/icons/icon_open_dark.svg?react'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/shared/ui/Drawer'
import { IconButton } from '@/shared/ui/IconButton'
import { Typography } from '@/shared/ui/Typography'
import { DocumentInfoDialog } from '@/widgets/DocumentPreview/DocumentInfoDialog'
import { ReactNode, useState } from 'react'

import { ChatAiFileDrawerMenu } from '../ChatAiFileDrawerMenu'

type Props = {
  children: ReactNode
  document: {
    id: number
    name: string
    body: string
  }
}

export const ChatAiFileDrawer = ({ children, document }: Props) => {
  const isMobile = useMediaQuery('(max-width: 767px)')

  const handleOpenInNewTab = () => {
    const newTab = window.open('', '_blank')
    if (newTab) {
      newTab.document.write(`
        <!DOCTYPE html>
        <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>${document.name}</title>
            <style>
              body {
                font-family: 'Inter', sans-serif;
                margin: 0;
                padding: 20px;
                color: #000;
              }
              h1 {
                text-align: center;
                font-size: 2em;
                font-weight: bold;
                margin-bottom: 20px;
              }
              p {
                font-size: 1em;
                line-height: 1.5;
              }
            </style>
          </head>
          <body>
            <h1>${document.name}</h1>
            <p>${document.body}</p>
          </body>
        </html>
      `)
      newTab.document.close()
    }
  }

  const [isInfoDialogOpen, setIsInfoDialogOpen] =
    useState<boolean>(false)

  const handleInfoClick = () => {
    setIsInfoDialogOpen((prev) => !prev)
  }
  const handleInfoClose = () => {
    setIsInfoDialogOpen(false)
  }

  return (
    <>
      <DocumentInfoDialog
        open={isInfoDialogOpen}
        onClose={handleInfoClose}
      />
      <Drawer direction="bottom">
        <DrawerTrigger>{children}</DrawerTrigger>
        <DrawerContent
          className="z-[70] h-[98svh] overflow-y-hidden bg-white px-6 pb-8 pt-6 sm:h-[90vh]
            md:h-[88vh]"
        >
          <DrawerHeader className="mb-8 flex items-center justify-between p-0">
            <div className="flex items-center justify-start gap-8">
              <ChatAiFileDrawerMenu onInfoClick={handleInfoClick}>
                <IconMoreDotsDark />
              </ChatAiFileDrawerMenu>

              {!isMobile && (
                <IconButton onClick={handleOpenInNewTab}>
                  <IconOpenDark />
                </IconButton>
              )}
            </div>
            <Typography className="line-clamp-2 text-center text-2xl font-bold leading-normal text-black">
              {document.name}
            </Typography>
            <div className="flex items-center justify-start gap-8">
              {!isMobile && <div className="h-6 w-6" />}
              <DrawerClose>
                <IconClose className="h-6 w-6" />
              </DrawerClose>
            </div>
          </DrawerHeader>
          <DrawerDescription className="-mx-6 overflow-y-auto px-6 pb-20 text-base font-normal leading-normal">
            {document.body}
          </DrawerDescription>
        </DrawerContent>
      </Drawer>
    </>
  )
}
