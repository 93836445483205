import { useChatInput } from '@/entities/chat/models/useChatInput'
import IconSubmitOutlinedDark from '@/shared/assets/icons/icon_submit_outlined_dark.svg?react'
import { IconButton } from '@/shared/ui/IconButton'
import { Input } from '@/shared/ui/Input'
import { cn } from '@/shared/utils/common'
import { useParams } from 'react-router-dom'

export const MobileChatInput = () => {
  const { chat_id } = useParams()

  const {
    isSending,
    inputValue,
    selectRef,
    handleInputChange,
    handleSubmit,
  } = useChatInput({ chatId: chat_id })

  return (
    <form
      onSubmit={handleSubmit}
      className={cn(
        'fixed flex w-full items-center justify-center',
        'bottom-0 self-center bg-white px-[1px] pb-[91px]',
        'lg:max-w-[768px]'
        // isPanel ? 'px-4 py-0 pb-0 lg:bottom-0 lg:pb-4' : ''
      )}
    >
      <div className="relative flex w-full items-center">
        <Input
          ref={selectRef}
          value={inputValue}
          onChange={handleInputChange}
          canRemove={false}
          disabled={isSending}
          className="w-full rounded-full bg-[#F0F0F0] pl-8 pr-14"
          placeholder="Введите запрос"
        />
        <IconButton
          type="submit"
          disabled={isSending}
          className="absolute right-2 top-1/2 -translate-y-1/2 transform"
        >
          <IconSubmitOutlinedDark
            width={44}
            height={44}
            fill="#182336"
            opacity={0.38}
          />
        </IconButton>
      </div>
    </form>
  )
}
