import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react'

//TODO: Add styles once design is ready
const customButtonVariants = cva(
  'flex items-center leading-[140%] px-6 py-[10px] flex-nowrap rounded-full justify-center',
  {
    variants: {
      variant: {
        primary: [
          'bg-base-900',
          'hover:bg-brand-primary-light',
          'active:bg-brand-primary-dark',
          'text-white',
          'transition-all',
          'duration-200',
          'ease-[cubic-bezier(0.2,0,0,1)]',
        ],
        secondary: [
          'bg-[#0090FF]',
          'text-white',
          'transition-all',
          'duration-200',
          'ease-[cubic-bezier(0.2,0,0,1)]',
        ],
        tonal: [
          'bg-[#D2E3FF]',
          'hover:shadow-lg hover:bg-[#C8DBFF]',
          'active:transform active:scale-95 active:bg-[#B6CFFD]',
          'transition-all',
          'duration-200',
          'ease-[cubic-bezier(0.2,0,0,1)]',
        ],
        ghost: [
          'bg-white',
          'hover:text-blue-600 hover:bg-transparent',
          'active:text-blue-800 active:bg-transparent',
          'transition-all',
          'duration-200',
          'ease-[cubic-bezier(0.2,0,0,1)]',
        ],
        outlined: [
          'bg-white',
          'border',
          'border-base-900',
          'text-base-900',
          'hover:border-gray-500 hover:bg-gray-100',
          'active:border-gray-700 active:bg-gray-200',
          'transition-all',
          'duration-200',
          'ease-[cubic-bezier(0.2,0,0,1)]',
        ],
        unstyled: ['p-0'],
        elevated: [
          'bg-[#F7F2FA]',
          'shadow-shadow4',
          'hover:shadow-lg',
          'active:transform active:scale-95',
          'transition-all',
          'duration-200',
          'ease-[cubic-bezier(0.2,0,0,1)]',
        ],
      },
      disabled: {
        true: [
          'bg-opacity-10',
          'cursor-not-allowed',
          'text-base-900',
          'text-opacity-35',
        ],
        false: [''],
      },
    },
    compoundVariants: [{ variant: 'primary' }],
    defaultVariants: {
      variant: 'primary',
      disabled: false,
    },
  }
)

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof customButtonVariants> {
  isLoading?: boolean
  asChild?: boolean
  disabled?: boolean
  leftIcon?: ReactNode
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant,
      children,
      className,
      isLoading = false, // TODO: Add loading state once design is ready
      asChild = false,
      disabled = false,
      leftIcon,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button'

    return (
      <Comp
        className={cn(
          customButtonVariants({ variant, disabled }),
          className
        )}
        ref={ref}
        disabled={disabled}
        {...props}
      >
        {leftIcon && <span className="mr-2">{leftIcon}</span>}{' '}
        <Typography>{children}</Typography>
      </Comp>
    )
  }
)

Button.displayName = 'Button'
