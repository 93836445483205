import { IChatMessage } from '@/entities/chat/types'
import { useGetListOfFavoritesQuery } from '@/shared/api/chat'

interface UseFavoriteMessagesReturnType {
  messages: IChatMessage[] | undefined
  isLoading: boolean
  error: string | null
}

export const useFavoriteMessages =
  (): UseFavoriteMessagesReturnType => {
    const {
      data: favoriteData,
      isLoading: favoriteLoading,
      error: favoriteError,
    } = useGetListOfFavoritesQuery({ page: 1 })

    return {
      messages: favoriteData?.messages,
      isLoading: favoriteLoading,
      error: favoriteError
        ? 'Failed to load favorite messages'
        : null,
    }
  }
