import { ISignupIndividualDTO } from '@/entities/signup/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface SignupIndividualState {
  data: ISignupIndividualDTO
  codeSent: boolean
  canResend: boolean
}

const initialState: SignupIndividualState = {
  data: {
    name: '',
    user_id: null,
    phone: '',
    verification_code: '',
  },
  codeSent: false,
  canResend: false,
}

const signupIndividualSlice = createSlice({
  name: 'signupIndividual',
  initialState,
  reducers: {
    resetSignupIndividualSteps: (state) => {
      state.data = initialState.data
      state.codeSent = false
      state.canResend = false
    },
    updateSignupData: (
      state,
      action: PayloadAction<Partial<ISignupIndividualDTO>>
    ) => {
      state.data = { ...state.data, ...action.payload }
    },

    setCodeSent: (state, action: PayloadAction<boolean>) => {
      state.codeSent = action.payload
    },
    setCanResend: (state, action: PayloadAction<boolean>) => {
      state.canResend = action.payload
    },
  },
})

export const {
  resetSignupIndividualSteps,
  updateSignupData,
  setCodeSent,
  setCanResend,
} = signupIndividualSlice.actions

export default signupIndividualSlice.reducer
