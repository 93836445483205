import IconSearch from '@/shared/assets/icons/icon_search_dark.svg?react'
import { Input } from '@/shared/ui/Input'

export const MobileCustomSearchInput = () => {
  return (
    <Input
      placeholder="Поиск по названию документа..."
      className="h-10 rounded-[100px] bg-[#F0F0F0]"
      leftIcon={<IconSearch className="h-5 w-5 text-gray-600" />}
    />
  )
}
