// src/context/SigninContext.tsx
import {
  ISigninIndividualDTO,
  ISigninJudicialDTO,
} from '@/entities/signin/types'
import {
  useSendOtpMutation,
  useSigninIndividualMutation,
} from '@/shared/api/signinIndividual'
import { useSigninJudicialMutation } from '@/shared/api/signinJudicial'
import { useToast } from '@/shared/ui/Toast/useToast'
import Cookies from 'js-cookie'
import React, {
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { useTimer } from 'react-timer-hook'

interface SigninContextProps {
  // Judicial
  judicialData: ISigninJudicialDTO
  setJudicialData: React.Dispatch<
    React.SetStateAction<ISigninJudicialDTO>
  >
  handleJudicialSignin: (data: ISigninJudicialDTO) => Promise<void>

  // Individual
  individualData: ISigninIndividualDTO
  setIndividualData: React.Dispatch<
    React.SetStateAction<ISigninIndividualDTO>
  >
  handleIndividualSignin: (
    data: ISigninIndividualDTO
  ) => Promise<void>
  handleSendOtp: (phone: string) => Promise<void>

  // Common states
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  codeSent: boolean
  setCodeSent: React.Dispatch<React.SetStateAction<boolean>>
  canResend: boolean
  timeLeft: number
}

const SigninContext = createContext<SigninContextProps | undefined>(
  undefined
)

export const SigninProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [judicialData, setJudicialData] =
    useState<ISigninJudicialDTO>({ email: '', password: '' })
  const [individualData, setIndividualData] =
    useState<ISigninIndividualDTO>({
      phone: '',
      verification_code: '',
    })
  const [loading, setLoading] = useState(false)
  const [codeSent, setCodeSent] = useState(false)
  const [canResend, setCanResend] = useState(true)

  const { toast } = useToast()
  const navigate = useNavigate()
  const [signinJudicial] = useSigninJudicialMutation()
  const [signinIndividual] = useSigninIndividualMutation()
  const [sendOtp] = useSendOtpMutation()

  // Timer hook for 30-second countdown
  const { seconds, restart } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: () => setCanResend(true), // Enable resend when timer expires
  })

  const handleJudicialSignin = useCallback(
    async (data: ISigninJudicialDTO) => {
      setLoading(true)
      try {
        const response = await signinJudicial(data).unwrap()
        Cookies.set('access_token', response.access_token, {
          expires: 30,
          secure: true,
          sameSite: 'Strict',
        })
        setJudicialData({ email: '', password: '' })
        navigate('/')
      } catch (error) {
        console.error('Judicial sign-in failed:', error)
      } finally {
        setLoading(false)
      }
    },
    [signinJudicial, navigate]
  )

  const handleIndividualSignin = useCallback(
    async (data: ISigninIndividualDTO) => {
      setLoading(true)
      try {
        const response = await signinIndividual(data).unwrap()
        Cookies.set('access_token', response.access_token, {
          expires: 30,
          secure: true,
          sameSite: 'Strict',
        })
        setIndividualData({ phone: '', verification_code: '' })
        navigate('/')
      } catch (error: any) {
        if (error?.data?.message) {
          toast({
            variant: 'error',
            title: 'Ошибка авторизации',
            description: error.data.message,
          })
        } else
          toast({
            variant: 'error',
            title: 'Что-то пошло не так',
            description: error.data.message,
          })
      } finally {
        setLoading(false)
      }
    },
    [signinIndividual, navigate]
  )

  const handleSendOtp = useCallback(
    async (phone: string) => {
      if (!canResend) return

      setLoading(true)
      try {
        await sendOtp(phone).unwrap()
        setCodeSent(true)
        setCanResend(false) // Disable resend until cooldown completes

        // Restart the timer for a 30-second countdown
        const expiryTime = new Date()
        expiryTime.setSeconds(expiryTime.getSeconds() + 30)
        restart(expiryTime)
      } catch (error: any) {
        if (error?.data?.message) {
          toast({
            title: 'Ошибка авторизации',
            description: error.data.message,
          })
        } else
          toast({
            title: 'Что-то пошло не так',
            description: error.data.message,
          })
      } finally {
        setLoading(false)
      }
    },
    [sendOtp, canResend, restart]
  )

  return (
    <SigninContext.Provider
      value={{
        judicialData,
        setJudicialData,
        handleJudicialSignin,
        individualData,
        setIndividualData,
        handleIndividualSignin,
        handleSendOtp,
        loading,
        setLoading,
        codeSent,
        setCodeSent,
        canResend,
        timeLeft: seconds, // Remaining time for resend availability
      }}
    >
      {children}
    </SigninContext.Provider>
  )
}

export const useSignin = (): SigninContextProps => {
  const context = useContext(SigninContext)
  if (!context) {
    throw new Error('useSignin must be used within an SigninProvider')
  }
  return context
}
