import IconBookMark from '@/shared/assets/icons/icon_bookmark_gray.svg?react'
import { IconButton } from '@/shared/ui'
import { cn } from '@/shared/utils/common'

import { useAiResponse } from '../../models/useAiResponse'

type Props = {
  isFavorite: boolean
  messageId: string
  chatId?: string
}

export const ButtonToggleFavoriteMessage = ({
  isFavorite,
  messageId,
  chatId,
}: Props) => {
  const { handleToggleFavorite } = useAiResponse(chatId)

  return (
    <IconButton
      className={cn(
        'p-1',
        isFavorite &&
          '[&_svg>path]:stroke-[#4D8EFF] [&_svg]:fill-[#4D8EFF]'
      )}
      onClick={() => {
        handleToggleFavorite(messageId)
      }}
    >
      <IconBookMark
        height={20}
        width={20}
        className="fill-none [&>path]:stroke-[#67707E]"
      />
    </IconButton>
  )
}
