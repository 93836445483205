import db from '@/db'
import { useEffect, useRef, useState } from 'react'

export const useFilters = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [containerHeight, setContainerHeight] = useState<
    number | undefined
  >()
  const [accordionHeight, setAccordionHeight] = useState<
    number | undefined
  >()
  const [itemMaxHeight, setItemMaxHeight] = useState<
    number | undefined
  >()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const accordionRef = useRef<HTMLDivElement | null>(null)

  // Toggle menu state
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  // Update container heights for proper layout
  const updateContainerHeights = () => {
    if (accordionRef.current && containerRef.current) {
      const containerRect =
        containerRef.current.getBoundingClientRect()
      const accordionRect =
        accordionRef.current.getBoundingClientRect()
      setAccordionHeight(accordionRect.height)
      setContainerHeight(containerRect.height)
      setItemMaxHeight(containerRect.height - 295)
    }
  }

  // Load preprocessed data into Dexie
  useEffect(() => {
    const loadDataToDexie = async () => {
      try {
        const organizationsCount = await db.organizations.count()
        const groupsCount = await db.groups.count()
        const typesCount = await db.types.count()

        if (organizationsCount === 0) {
          const response = await fetch('/organizationsData.json')
          const data = await response.json()

          await db.organizations.bulkPut(data)
        }

        if (groupsCount === 0) {
          const response = await fetch('/groupsData.json')
          const data = await response.json()
          await db.groups.bulkPut(data)
        }

        if (typesCount === 0) {
          const response = await fetch('/typesData.json')
          const data = await response.json()
          await db.types.bulkPut(data)
        }
      } catch (error) {
        console.error('Error loading data into Dexie:', error)
      }
    }

    loadDataToDexie()
  }, [])

  // Handle window resizing
  useEffect(() => {
    updateContainerHeights()
    window.addEventListener('resize', updateContainerHeights)
    return () =>
      window.removeEventListener('resize', updateContainerHeights)
  }, [containerRef, accordionRef, isMenuOpen])

  return {
    isMenuOpen,
    containerRef,
    accordionRef,
    containerHeight,
    accordionHeight,
    itemMaxHeight,
    handleMenuToggle,
  }
}
