import { CustomFilterInput, ECategoryType } from '@/entities/filter'
import { Checkbox } from '@/shared/ui/Checkbox'
import { Typography } from '@/shared/ui/Typography'

import { useGroupFilter } from '../../hooks/useGroupFilter'
import { GroupFilterAccordion } from '../GroupFilterAccordion'

export const GroupFilterDesktopMenu = () => {
  const {
    groups,
    isFiltered,
    isSearching,
    allSelected,
    setGroups,
    setTotalGroupsCount,
    selectedIndices,
    setIsFiltered,
    handleSelectAll,
    handleCheckboxChange,
  } = useGroupFilter()

  return (
    <div className="relative flex flex-col overflow-hidden">
      <div className="p-0" onClick={(e) => e.preventDefault()}>
        <CustomFilterInput
          setIsFiltered={setIsFiltered}
          setTotalCount={setTotalGroupsCount}
          setFilterItems={setGroups}
          categoryType={ECategoryType.GROUPS}
        />
        <div className="flex items-center justify-start gap-2 px-3 py-[10px]">
          <Checkbox
            variant={'secondary'}
            size={'small'}
            checked={allSelected}
            onCheckedChange={handleSelectAll}
            id="select-all-checkbox"
          />
          <Typography variant={'subtitle'} className="ml-2 text-sm">
            Все
          </Typography>
        </div>
      </div>
      <GroupFilterAccordion
        parentId={-1}
        level={0}
        groups={groups}
        isFiltered={isFiltered}
        isSearching={isSearching}
        selectedIndices={selectedIndices}
        handleCheckboxChange={handleCheckboxChange}
      />
    </div>
  )
}
