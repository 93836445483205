import { ISearchMode } from '../types'

export const mockSearchModeDataMobile: Record<ISearchMode, string> = {
  IN_TITLE: 'По названию документа',
  CONTEXT: 'По контексту',
}

export const mockSearchModeData: Record<ISearchMode, string> = {
  IN_TITLE: 'Поиск по названию',
  CONTEXT: 'Поиск по контексту',
}
