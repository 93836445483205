import { cn } from '@/shared/utils/common'
import { useEffect, useRef } from 'react'

import { IChatMessage } from '../../types'
import { ChatMessages } from '../ChatMessages'
import { MobileChatInput } from '../MobileChatInput'

type MobileChatContainerProps = {
  messages: IChatMessage[]
}

export const MobileChatContainer = ({
  messages,
}: MobileChatContainerProps) => {
  const bottomRef = useRef<HTMLDivElement | null>(null)

  // Scroll to the bottom whenever messages change
  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [messages])
  if (!messages) return null
  return (
    <div
      className={cn(
        'flex h-full w-full flex-col overflow-y-auto',
        'menu_boundary'
      )}
    >
      <div
        className={cn(
          `relative flex h-full flex-col items-stretch justify-start gap-8
          self-center`,
          'px-5 pb-[143px] pt-10 md:max-w-[728px]'
        )}
      >
        <ChatMessages messages={messages} />
        <div ref={bottomRef} className="-my-7" />
      </div>
      <MobileChatInput />
    </div>
  )
}
