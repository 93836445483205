import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { IconButton } from '@/shared/ui'
import { cn } from '@/shared/utils/common'
import { BottomNavigationBar } from '@/widgets/BottomNavigationBar'
import {
  MobilePlatformHeader,
  PlatformHeader,
} from '@/widgets/Headers'
import { Sidebar } from '@/widgets/Sidebar'
import {
  SidebarProvider,
  useSidebar,
} from '@/widgets/Sidebar/model/useSidebar'
import { motion } from 'framer-motion'
import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

const AssistantLayoutContent = () => {
  const isMobile = useMediaQuery('(max-width: 959px)')
  const { isSidebarOpen, closeSidebar, toggleSidebar } = useSidebar()

  useEffect(() => {
    closeSidebar()
  }, [closeSidebar])

  if (isMobile) return <Outlet />

  return (
    <>
      <div
        className="relative flex h-[100vh] w-screen flex-col items-stretch justify-start
          px-8 pb-8"
      >
        <div
          className={cn(
            'relative flex items-stretch',
            isSidebarOpen ? 'gap-16' : ''
          )}
          style={{ height: 'calc(100vh - 32px)' }}
        >
          <Sidebar />
          {/* Spacer Div */}
          <motion.div
            animate={{ width: isSidebarOpen ? 272 : 0 }}
            transition={{
              type: 'spring',
              stiffness: 300,
              damping: 30,
            }}
            className="relative shrink-0 bg-transparent"
          />
          {/* Content */}
          <div
            className="flex shrink grow-0 basis-full items-stretch justify-center
              overflow-hidden"
          >
            <Outlet />
          </div>
        </div>
        {/* Sidebar */}
      </div>
    </>
  )
}

export const AssistantLayout = () => {
  return (
    <SidebarProvider>
      <AssistantLayoutContent />
    </SidebarProvider>
  )
}
