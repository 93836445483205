import IconDocumentsOutlinedDark from '@/shared/assets/icons/icon_documents_outlined_dark.svg?react'
import { Checkbox } from '@/shared/ui/Checkbox'
import { Typography } from '@/shared/ui/Typography'

import { IDocumentStatus } from '../../types'

interface DocumentStatusItemProps {
  items: IDocumentStatus[]
  selectedIndices: number[]
  handleCheckboxChange: (id: number) => void
}

export const DocumentStatusDesktopFilterItems: React.FC<
  DocumentStatusItemProps
> = ({ items, selectedIndices, handleCheckboxChange }) => {
  return (
    <>
      {items.map((item) => (
        <label
          key={item.id}
          htmlFor={`checkbox-${item.id}`}
          className="flex cursor-pointer flex-nowrap items-center justify-between gap-2
            self-stretch px-0 py-2"
        >
          <span
            className="flex shrink grow-0 basis-full items-center justify-start gap-2
              overflow-hidden"
          >
            <IconDocumentsOutlinedDark className="h-4 w-4" />
            <Checkbox
              variant={'secondary'}
              size={'small'}
              id={`checkbox-${item.id}`}
              checked={selectedIndices.includes(item.id)}
              onCheckedChange={() => handleCheckboxChange(item.id)}
            />
            <Typography variant={'label'} className="inline min-w-0">
              {item.name}
            </Typography>
            <Typography variant={'subtitle'} className="inline">
              ({item.amount})
            </Typography>
          </span>
        </label>
      ))}
    </>
  )
}
