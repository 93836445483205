import { IChatHistoryItem } from '@/entities/chat/types'
import { useChatHistoryItem } from '@/entities/chatHistory/models/useChatHistoryItem'
import {
  useDeleteChatByIdMutation,
  usePatchFavoriteChatByIdMutation,
  usePatchRenameChatByIdMutation,
} from '@/shared/api/chat'
import IconBookmarkBlack from '@/shared/assets/icons/icon_bookmark_back.svg?react'
import IconBucketBlack from '@/shared/assets/icons/icon_bucket_black.svg?react'
import IconMoreGray from '@/shared/assets/icons/icon_more_gray.svg?react'
import IconPenBlack from '@/shared/assets/icons/icon_pen_black.svg?react'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from '@/shared/ui/Menubar'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { Arrow } from '@radix-ui/react-menubar'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

interface ChatHistoryItemProps {
  item: IChatHistoryItem
}

export const ChatHistoryItem = ({ item }: ChatHistoryItemProps) => {
  const { isHovered, triggerRef } = useChatHistoryItem()
  const { chat_id } = useParams<{ chat_id: string }>()
  const [isBeingRenamed, setIsBeingRenamed] = useState<boolean>(false)
  const [newTitle, setNewTitle] = useState<string>(item.title)
  const inputRef = useRef<HTMLInputElement>(null) // Reference for the input
  const navigate = useNavigate()
  const isMobile = useMediaQuery('(max-width: 768px)')

  const [deleteChat] = useDeleteChatByIdMutation()
  const [renameChat] = usePatchRenameChatByIdMutation()
  const [toggleFavoriteChat] = usePatchFavoriteChatByIdMutation()

  const handleRenameChat = async () => {
    if (newTitle.trim() === item.title) {
      setIsBeingRenamed(false) // No need to update if title hasn’t changed
      return
    }
    try {
      await renameChat({
        id: item.id,
        title: newTitle,
      }).unwrap()
      setIsBeingRenamed(false) // Exit rename mode after renaming
    } catch (error) {
      console.error('Failed to rename chat:', error)
    }
  }

  const handleDelete = async () => {
    try {
      await deleteChat(item.id).unwrap()
    } catch (error) {
      console.error('Failed to delete chat:', error)
    }
  }

  const handleToggleFavorite = async () => {
    try {
      await toggleFavoriteChat(item.id).unwrap()
    } catch (error) {
      console.error('Failed to toggle favorite status:', error)
    }
  }

  // Focus on the input when entering rename mode
  useEffect(() => {
    if (isBeingRenamed) {
      inputRef.current?.focus()
    }
  }, [isBeingRenamed])

  return (
    <Menubar
      className={cn(
        'flex h-[38px] items-center justify-stretch self-stretch border-none',
        'rounded-lg shadow-none hover:cursor-pointer hover:bg-base-100',
        item.id === chat_id ? 'bg-base-100' : ''
      )}
    >
      <MenubarMenu>
        <div
          className="relative flex w-full items-center justify-between self-stretch
            overflow-hidden p-2"
          ref={triggerRef}
          onClick={() => navigate(`/chats/${item.id}`)}
        >
          {isBeingRenamed ? (
            <input
              ref={inputRef}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleRenameChat()
                } else if (e.key === 'Escape') {
                  setIsBeingRenamed(false)
                }
              }}
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              className="ring-2 focus:outline-none"
              onBlur={() => setIsBeingRenamed(false)} // Exit rename mode on blur
            />
          ) : (
            <Typography
              variant={'label'}
              className="relative shrink grow-0 basis-full overflow-hidden whitespace-nowrap"
            >
              {item.title}
            </Typography>
          )}
          {!isBeingRenamed && (
            <MenubarTrigger onClick={(e) => e.stopPropagation()}>
              {isHovered || item.id === chat_id ? (
                <div
                  className={cn(
                    'absolute right-2 top-2 z-50 flex items-center justify-end gap-1',
                    'bg-fade-gradient py-[1px] pl-[22px] hover:cursor-pointer'
                  )}
                >
                  <IconMoreGray height={18} width={18} />
                </div>
              ) : (
                <div
                  className="absolute right-2 top-2 flex h-[20px] w-[18px] items-center justify-end
                    gap-1 bg-fade-gradient-white py-[1px] pl-[22px]"
                />
              )}
            </MenubarTrigger>
          )}
        </div>
        <MenubarContent
          className="z-50 flex w-[174px] flex-col items-stretch justify-start gap-[6px]
            rounded-[20px] border-none bg-base-20 p-2"
          align="center"
          sideOffset={12}
          style={{
            boxShadow:
              '0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
          }}
          side="right"
        >
          <Arrow fill="#FCFDFD" className="h-2 w-5" />
          <MenubarItem
            className="flex items-center justify-start gap-2 rounded-lg p-2
              hover:cursor-pointer hover:bg-slate-100"
            inset={true}
            onClick={(e) => {
              e.stopPropagation()
              setNewTitle(item.title) // Reset title to item's original title
              setIsBeingRenamed(true)
            }}
          >
            <IconPenBlack className="h-5 w-5" />
            <Typography className="whitespace-nowrap font-normal">
              Переименовать
            </Typography>
          </MenubarItem>
          <MenubarItem
            className="flex items-center justify-start gap-2 rounded-lg p-2
              hover:cursor-pointer hover:bg-slate-100"
            inset={true}
            onClick={(e) => {
              e.stopPropagation()
              handleToggleFavorite()
            }}
          >
            <IconBookmarkBlack className="h-5 w-5" />
            <Typography className="whitespace-nowrap font-normal">
              В избранное
              {/* {item.is_favorite
                ? 'Удалить из избранного'
                : 'В избранное'} */}
            </Typography>
          </MenubarItem>

          <MenubarItem
            className="flex items-center justify-start gap-2 rounded-lg p-2
              hover:cursor-pointer hover:bg-slate-100"
            inset={true}
            onClick={(e) => {
              e.stopPropagation()
              handleDelete()
            }}
          >
            <IconBucketBlack className="h-5 w-5" />
            <Typography className="whitespace-nowrap font-normal">
              Удалить
            </Typography>
          </MenubarItem>
        </MenubarContent>
      </MenubarMenu>
    </Menubar>
  )
}
