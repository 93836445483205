import IconArrowRightDark from '@/shared/assets/icons/icon_arrow_right_dark.svg?react'
import {
  Button,
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
  Typography,
} from '@/shared/ui'
import { cn } from '@/shared/utils/common'
import { ChangeEvent, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

interface CustomPaginationProps {
  isMobile: boolean
  currentPage: number
  totalPages: number
  perPage: number
  totalItems: number
}

export const CustomPagination = ({
  isMobile,
  currentPage,
  totalPages,
  perPage,
  totalItems,
}: CustomPaginationProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [inputValue, setInputValue] = useState('')

  const handlePageChange = (page: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: page.toString(),
    })
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (/^\d*$/.test(value)) {
      setInputValue(value)
    }
  }

  const handleInputSubmit = () => {
    if (inputValue) {
      const page = Math.max(
        1,
        Math.min(Number(inputValue), totalPages)
      )
      handlePageChange(page)
    }
  }

  const getPageNumbers = () => {
    let startPage = Math.max(1, currentPage - 1)
    const endPage = Math.min(startPage + 3, totalPages)

    if (endPage - startPage < 3) {
      startPage = Math.max(1, endPage - 3)
    }

    const pages = []
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i)
    }
    return pages
  }

  return (
    <div
      className="my-8 inline-flex flex-col items-stretch justify-start gap-4
        self-center"
    >
      <div className="flex items-center justify-start gap-8">
        <Pagination className="shrink grow-0 bg-white">
          <PaginationContent>
            {currentPage !== 1 && (
              <PaginationItem>
                <PaginationPrevious
                  onClick={(e) => {
                    e.preventDefault()
                    handlePageChange(Math.max(currentPage - 1, 1))
                  }}
                />
              </PaginationItem>
            )}
            {getPageNumbers().map((page) => (
              <PaginationItem key={page}>
                <PaginationLink
                  isActive={page === currentPage}
                  disabled={page === currentPage}
                  onClick={(e) => {
                    e.preventDefault()
                    handlePageChange(page)
                  }}
                >
                  <Typography variant={'body'}>{page}</Typography>
                </PaginationLink>
              </PaginationItem>
            ))}
            {currentPage + 3 < totalPages && (
              <>
                <PaginationItem>
                  <PaginationEllipsis />
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink
                    isActive={totalPages === currentPage}
                    className="flex w-full min-w-[36px] items-center justify-center !p-2 px-2"
                    onClick={(e) => {
                      e.preventDefault()
                      handlePageChange(totalPages)
                    }}
                  >
                    {totalPages}
                  </PaginationLink>
                </PaginationItem>
              </>
            )}
            {currentPage !== totalPages && (
              <PaginationItem>
                <PaginationNext
                  onClick={(e) => {
                    e.preventDefault()
                    handlePageChange(
                      Math.min(currentPage + 1, totalPages)
                    )
                  }}
                />
              </PaginationItem>
            )}
          </PaginationContent>
        </Pagination>
        <div className="flex shrink grow-0 items-center justify-start gap-3">
          <Typography variant={'body'}>Страница</Typography>
          <input
            maxLength={5}
            value={inputValue}
            onChange={handleInputChange}
            className="flex max-w-20 shrink grow-0 items-center overflow-visible
              rounded-[4px] border-[1px] border-base-200 px-3 py-3 text-center
              focus:ring-black"
          />
          <Button
            variant={'outlined'}
            leftIcon={<IconArrowRightDark />}
            onClick={handleInputSubmit}
          >
            Перейти
          </Button>
        </div>
      </div>
      <div className="flex flex-col items-stretch justify-start gap-2">
        <Typography
          variant={'label'}
          className="font-medium text-base-500"
        >
          {currentPage * perPage - perPage + 1}-
          {Math.min(currentPage * perPage, totalItems)} из{' '}
          {totalItems}
        </Typography>
      </div>
    </div>
  )
}
