import { ECategoryType } from '@/entities/filter'
import { Group } from '@/features/filters/GroupFilter/types'
import { Organization } from '@/features/filters/OrganizationFilter/types'
import { Types } from '@/features/filters/TypeFilter/types'
import SearchIcon from '@/shared/assets/icons/icon_search_dark.svg?react'
import React, { Dispatch, SetStateAction } from 'react'

import { useFiltersSearch } from '../../models/useFiltersSearch'

interface CustomFilterInputProps {
  categoryType: ECategoryType
  setTotalCount: Dispatch<SetStateAction<number>>
  setFilterItems: Dispatch<
    SetStateAction<Group[] | Organization[] | Types[]>
  >
  setIsFiltered: Dispatch<React.SetStateAction<boolean>>
}

export const CustomFilterInput: React.FC<CustomFilterInputProps> = ({
  categoryType,
  setFilterItems,
  setTotalCount,
  setIsFiltered,
}) => {
  const getPlaceholder = () => {
    switch (categoryType) {
      case ECategoryType.TYPES:
        return 'Найти тип документа...'
      case ECategoryType.ORGANIZATIONS:
        return 'Найти организацию...'
      case ECategoryType.GROUPS:
        return 'Найти раздел...'
      default:
        return ''
    }
  }

  const { searchText, setSearchText } = useFiltersSearch({
    categoryType: categoryType as ECategoryType,
    setFilterItems: setFilterItems,
    setTotalCount: setTotalCount,
    setIsFiltered: setIsFiltered,
  })

  return (
    <div
      className="rounded-[8px] border-[1px] border-[#D8DBDF]"
      style={{
        display: 'flex',
        padding: '0px 12px',
        alignItems: 'center',
        gap: '8px',
        flex: '1 0 0',
      }}
    >
      <SearchIcon />
      <input
        type="text"
        placeholder={getPlaceholder()}
        value={searchText}
        className="font-normal leading-[20px] placeholder:text-[14px]
          placeholder:text-[#67707E]"
        onChange={(e) => setSearchText(e.target.value)}
        style={{
          flex: '1',
          padding: '8px',
          border: 'none',
          outline: 'none',
        }}
      />
    </div>
  )
}
